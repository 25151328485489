import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopUpService } from '../../../services/popUp.service';
import { GLOBAL } from "../../../services/GLOBAL";

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}


@Component({
  selector: 'app-popUp-form',
  templateUrl: './pop-up-form.component.html',
  styleUrls: ['./pop-up-form.component.css']
})
export class PopUpFormComponent implements OnInit {
  selectedFiles: { [key: string]: File } = {};
  savedPopUp: any = {
    textSection1: '',
    textSection2: '',
    textSection3: '',
    landingCoverImage: '',
    mobileCoverImage: '',
    popUpCoverImage: '',
    encendido: false
  };
  public msm_error;
  public msm_success;
  public imgSelect: { landingCoverImage: String | ArrayBuffer, mobileCoverImage: String | ArrayBuffer, popUpCoverImage: String | ArrayBuffer } =
    { landingCoverImage: '', mobileCoverImage: '', popUpCoverImage: '' };
  public url;
  public global;


  constructor(private fb: FormBuilder, private _popUpService: PopUpService) {
    this.url = GLOBAL.url; this.global = GLOBAL;
  }

  ngOnInit(): void {
    this._popUpService.getPopUp().subscribe(
      response => {
        this.savedPopUp = response;
      },
      error => {
        this.msm_error = 'No se pudo obtener el popUp';
      }
    );
  }

  imgSelected(event: HtmlInputEvent, fieldName) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => this.imgSelect[fieldName] = reader.result;
      reader.readAsDataURL(<File>event.target.files[0]);
      this.savedPopUp[fieldName] = <File>event.target.files[0];
    }
  }

  onSubmit(popUpForm) {
    if (popUpForm.valid) {
      let data = {
        textSection1: popUpForm.value.textSection1,
        textSection2: popUpForm.value.textSection2,
        textSection3: popUpForm.value.textSection3,
        landingCoverImage: this.savedPopUp.landingCoverImage,
        mobileCoverImage: this.savedPopUp.mobileCoverImage,
        popUpCoverImage: this.savedPopUp.popUpCoverImage,
        encendido: popUpForm.value.encendido
      }

      this._popUpService.createOrUpdatePopUp(data).subscribe(
        response => {
          console.log('Se actualizó correctamente el popUp');
          this.msm_success = 'Se actualizó correctamente el popUp';
          this.clearAlertAfterTimeout();
          this.scrollToTop();
        },
        error => {
          this.msm_error = 'No se guardó correctamente el popUp';
          this.clearAlertAfterTimeout();
          this.scrollToTop();
        }
      );

    } else {
      this.msm_error = 'Complete correctamente el formulario por favor.';
      this.clearAlertAfterTimeout();
      this.scrollToTop();
    }
  }

  clearAlertAfterTimeout() {
    setTimeout(() => {
      this.msm_success = null;
      this.msm_error = null;
    }, 10000); // 10000 ms = 10 segundos
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  close_alert() {
    this.msm_error = '';
    this.msm_success = '';
  }
}
