import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GLOBAL} from "../../services/GLOBAL";
import {CitaService} from "../../services/cita.service";
import {Router} from "@angular/router";
import {UserService} from "../../services/user.service";

declare var grecaptcha: any;

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.css']
})
export class CitaComponent implements OnInit {
  @ViewChild('cedula') cedula;
  public global = GLOBAL;

  public servicios: Array<any> = [];
  public especialidades: Array<any> = [];
  public selectedServicio: any;
  public selectedEspecialidadServicios: Array<any> = [];
  public dateSelected: any;
  public tomorrow: any;
  public places: Array<any> = [];
  public placeSelected: any;
  public errorAgenda: any;
  public errorStep1: any;
  public agendaSelected: any;
  public citaSelected: any;
  public nombrePaciente: any;
  public cedulaPaciente: any;
  public correoPaciente: any;
  public sexoPaciente: any;
  public telefonoPaciente: any;
  public expediente: any;
  public errorCita: any;
  public confirmacionCita: any;

  public lugarSelected = false;
  public btnStart = true;
  public btnClick: boolean = true;
  public btnSpinner: boolean = false;
  public spinner: boolean = false;
  public spinner2: boolean = false;
  public horario: boolean = false;
  public emptyHorarios: boolean = false;
  public returnedToStep1: boolean = false;
  public changeSomethingFromStep1: boolean = false;




  private browserRecaptchaKey = this.global.browserRecaptchaKey;
  public userMail = this.global.userMail;
  public citas: Array<any> = [];
  public fechaNacimiento: any;
  public primerApellidoPaciente: any;
  public segundoApellidoPaciente: any;
  public showcompleteForm: boolean = false;
  public obteniendoDatosPaciente: any;


  constructor(
    private _citaService: CitaService,
    private router: Router,
    private userService: UserService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.showcompleteForm = false;
    var today = new Date();
    var tomorrowDate = new Date();
    tomorrowDate.setDate(today.getDate());
    this.tomorrow = tomorrowDate.toISOString().split('T')[0];
    this.fechaNacimiento = "1989-04-03";
    this.userService.getMe().subscribe(res => {
      // @ts-ignore
      this.nombreUsuario = res.mail;
      // @ts-ignore
      this.global.userMail = res.mail;
      this.userMail = this.global.userMail;
    });
  }


  listarServicios() {
    this.btnClick = false;
    this.btnSpinner = true;
    this.servicios = [];
    this.especialidades = [];
    this.btnStart = true;
    var recaptchaAction = 'ListarServicios';
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(this.browserRecaptchaKey, {action: recaptchaAction});
      var data = {
        recaptchaToken: token,
        recaptchaAction: recaptchaAction
      };
      (await this._citaService.listarServicios(data)).subscribe(
        response => {
          this.servicios = response.servicios;
          for (let key in this.servicios) {
            if (!Object.keys(this.especialidades).includes(this.servicios[key].especialidad)) {
              this.especialidades[this.servicios[key].especialidad] = [];
            }
            if (this.servicios.hasOwnProperty(key)) {
              this.especialidades[this.servicios[key].especialidad].push({key: key, value: this.servicios[key]});
            }
          }
          this.btnStart = false;
          this.btnSpinner = false;
        }, error => {
          if (error.message) {
            this.errorStep1 = error.message;
          } else {
            this.errorStep1 = "No pudimos comunicarnos con el servidor, intente más tarde";
          }
        }
      );
    });
  }

  listarAgendas() {
    if (!(this.returnedToStep1 && !this.changeSomethingFromStep1)){
      this.spinner2 = true;
      this.emptyHorarios = false;
      this.places = [];
      this.citas = [];
      this.lugarSelected = false;
      this.emptyHorarios = false;
      this.placeSelected = undefined;
      this.agendaSelected = false;
      var recaptchaAction = 'ListarAgenda';
      if (this.selectedServicio && this.dateSelected) {

        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(this.browserRecaptchaKey, {action: recaptchaAction});
          var data = {
            fecha_inicio: this.dateSelected.toLocaleString("es-CL").split(' ')[0] + ' 00:00:00',
            fecha_fin: this.dateSelected.toLocaleString("es-CL").split(' ')[0] + ' 23:59:59',
            servicio: this.selectedServicio.key,
            recaptchaToken: token,
            recaptchaAction: recaptchaAction
          }
          this._citaService.listarAgendas(data).subscribe(
            response => {
              this.places = response.agenda;
              this.lugarSelected = true;
              this.spinner2 = false;
            },
            error => {
              this.errorAgenda = 'Error de comunicación';
            }
          );
        });
      } else {
        this.errorAgenda = "Elija servicio y fecha"
      }
    }
  }


  debug() {
    var t = 0;
  }

  agendarCita() {
    this.spinner = true;
    this.confirmacionCita = undefined;
    this.errorCita = undefined;
    var recaptchaAction = 'AgendarCita';
    if (this.selectedServicio && this.dateSelected && this.agendaSelected && this.citaSelected && this.nombrePaciente &&
      this.cedulaPaciente && this.correoPaciente) {
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(this.browserRecaptchaKey, {action: 'AgendarCita'});
          var data = {
            servicio: this.selectedServicio.key,
            agenda: this.agendaSelected.key,
            fecha: this.citaSelected.key,
            nombre: this.nombrePaciente,
            cedula: this.cedulaPaciente,
            correo: this.correoPaciente,
            sexo: this.sexoPaciente,
            telefono: this.telefonoPaciente,
            expediente: this.expediente,
            primerApellido: this.primerApellidoPaciente,
            segundoApellido: this.segundoApellidoPaciente,
            fechaNacimiento: this.fechaNacimiento.toLocaleString("es-CL"),
            recaptchaToken: token,
            recaptchaAction: recaptchaAction
          };
          (await this._citaService.registro(data)).subscribe(
            response => {
              if (response.cita.api_cita_creada) {
                this.spinner = false;
                this.confirmacionCita = response.cita.api_cita_creada;

                this.selectedServicio = undefined;
                this.agendaSelected = undefined;
                this.placeSelected = undefined;
                this.citaSelected = undefined;
                this.nombrePaciente = undefined;
                this.cedulaPaciente = undefined;
                this.correoPaciente = undefined;
                this.expediente = undefined;
                this.sexoPaciente = undefined;
                this.telefonoPaciente = undefined;
                this.selectedEspecialidadServicios = [];
                this.places = [];
                this.lugarSelected = false;
                this.servicios = [];
                this.especialidades = [];
                this.btnStart = true;
                this.dateSelected = false;
                this.primerApellidoPaciente = undefined;
                this.segundoApellidoPaciente = undefined;
                this.fechaNacimiento = undefined;

              } else {
                this.spinner = false;
                this.errorCita = 'Espacio no disponible';
              }
            },
            error => {
              this.spinner = false;
              this.errorCita = 'Error de comunicación';
            }
          );
        });
    } else {
      this.spinner = false;
      this.errorCita = "Complete todos los campos obligatorios"
    }
  }

  selectEspecialidad() {
    this.selectedServicio = this.selectedEspecialidadServicios[0];
    //this.selectedServicio = undefined;
  }

  getCitas() {
    this.horario = true;
    this.citas = [];
    var recaptchaAction = 'ListarCitas';
    if (this.selectedServicio && this.dateSelected && this.agendaSelected) {

      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(this.browserRecaptchaKey, {action: recaptchaAction});
        var data = {
          fecha_inicio: this.dateSelected.toLocaleString("es-CL").split(' ')[0] + ' 00:00:00',
          fecha_fin: this.dateSelected.toLocaleString("es-CL").split(' ')[0] + ' 23:59:59',
          servicio: this.selectedServicio.key,
          agenda: this.agendaSelected.key,
          recaptchaToken: token,
          recaptchaAction: recaptchaAction
        }
        this._citaService.listarCitas(data).subscribe(
          response => {
            this.horario = false;
            if ((response && Object.keys(response).length === 0 && Object.getPrototypeOf(response) === Object.prototype)
              || (Object.keys(response.citas[this.placeSelected.key][this.agendaSelected.key].citas).length == 0)) {
              this.emptyHorarios = true;
            } else {
              this.emptyHorarios = false;
              this.citas = response.citas[this.placeSelected.key][this.agendaSelected.key].citas;
            }
          },
          error => {
            this.horario = false;
            this.errorAgenda = 'Error de comunicación con el servidor, intente de nuevo';
          }
        );
      });
    } else {
      this.horario = false;
      this.errorAgenda = "Elija servicio y fecha"
    }
  }

  resetCitasHorarios() {
    this.emptyHorarios = false;
    this.citas = [];
    this.agendaSelected = false;
  }

  resetStep2() {
    this.spinner2 = true;
    this.emptyHorarios = false;
    this.places = [];
    this.citas = [];
    this.lugarSelected = false;
    this.emptyHorarios = false;
    this.placeSelected = undefined;
    this.agendaSelected = false;
    this.changeSomethingFromStep1 = true;
  }

  returnStep1() {
    this.returnedToStep1 = true;
    this.changeSomethingFromStep1 = false;
  }

  volver() {
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }

  back() {
    this.errorCita = undefined;
  }

  async completarCitaForm() {
    this.showcompleteForm = false;
    this.nombrePaciente = undefined;
    this.correoPaciente = undefined;
    this.expediente = undefined;
    this.sexoPaciente = undefined;
    this.telefonoPaciente = undefined;
    this.fechaNacimiento = undefined;
    this.primerApellidoPaciente = undefined;
    this.segundoApellidoPaciente = undefined;
    this.obteniendoDatosPaciente = true;
    var recaptchaAction = 'CompletarForm';
    const token = await grecaptcha.enterprise.execute(this.browserRecaptchaKey, {action: 'CompletarForm'});
    var data = {
      cedula: this.cedulaPaciente,
      recaptchaToken: token,
      recaptchaAction: recaptchaAction
    }
    this._citaService.getDatosPacientes(data).subscribe(
      response => {
        if(response.datosPaciente) {
          this.nombrePaciente = response.datosPaciente.nombre;
          this.correoPaciente = response.datosPaciente.correo;
          this.expediente = response.datosPaciente.expediente;
          this.sexoPaciente = response.datosPaciente.sexo;
          this.telefonoPaciente = response.datosPaciente.telefono;
          this.fechaNacimiento = response.datosPaciente.fecha_nacimiento;
          this.primerApellidoPaciente = response.datosPaciente.primer_apellido;
          this.segundoApellidoPaciente = response.datosPaciente.segundo_apellido;
        }
        this.obteniendoDatosPaciente = false;
        this.showcompleteForm = true;
      },
      error => {
        this.showcompleteForm = true;
        this.obteniendoDatosPaciente = false;
      });
  }

  validateChar(event) {
    const e = <KeyboardEvent>event;
    let k;
    k = this.cedula.nativeElement.value;
    if (k != null) {
      if(k.match(/[^\w\s]/gi) === null) {
        return;
      } else {
        e.preventDefault();
        this.renderer.setProperty(this.cedula.nativeElement, 'value', k.replace(/[^\w\s]/gi, ''));
        this.cedulaPaciente = this.cedulaPaciente.replace(/[^\w\s]/gi, '');
      }
    } else {
      return;
    }
  }
}



