import { Component } from '@angular/core';
import { PortadaService } from './services/portada.service';
import { GLOBAL } from './services/GLOBAL';
import { HttpClient } from '@angular/common/http';
import {filter, Observable, takeUntil, Subject} from 'rxjs';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService  } from '@azure/msal-angular'
import { EventMessage, EventType } from '@azure/msal-browser';
import { PopUpService } from './services/popUp.service';



declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'front';
  public general : any = {};
  public url;
  public headers = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private _congeneralService : PortadaService,
    private http: HttpClient,
    private _router : Router,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private _popUpService: PopUpService
    ){
      this.url = GLOBAL.url;
  }

  ngOnInit(): void{
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Do something with event payload here
        var t = 0;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onActivate(event) {
      window.scroll(0,0);

  }
}
