<header class="sticky-top">

  <nav class="navbar navbar-light bg-light none-phone">
    <div class="container-fluid">

      <div class="col-4 col-md-4 d-flex justify-content-start">
        <a href="https://www.instagram.com/clinicas_asembis/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
               class="bi bi-instagram ms-3 me-2" viewBox="0 0 16 16" style="color: #d63f87;">
            <path
              d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
          </svg>
        </a>

        <a href="https://www.facebook.com/Asembis/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-facebook"
               viewBox="0 0 16 16" style="color: #4073b9;">
            <path
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
          </svg>
        </a>

        <a href="https://www.tiktok.com/@asembis" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-tiktok ms-2" viewBox="0 0 16 16" style="color: black;">
            <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
          </svg>
        </a>
      </div>

      <div class="col-4 col-md-4 d-flex justify-content-center">
        <a [routerLink]="['/']" [ngClass]="{'cursor': global.cursor_btn}" *ngIf="!global.color_filter" href="/" 
           style="width: 77%;"> <img
          src="assets/img/logoASEMBIS.svg" alt="Logo de ASEMBIS" width="40%" style="margin-left: 30%;"></a>
        <a [routerLink]="['/']" href="/"  [ngClass]="{'cursor': global.cursor_btn}" *ngIf="global.color_filter"
           style="width: 77%;"> <img
          src="assets/img/logoASEMBIS-black.svg" alt="Logo de ASEMBIS" width="40%" style="margin-left: 30%;"></a>
      </div>

      <div class="col-4 col-md-4 d-flex justify-content-end">

        <img class="access-icon mt-2" src="assets/img/icons/access-icon.svg" alt="Icono de accesibilidad">

        <a [ngClass]="{'text-decoration-none': true, 'cursor': global.cursor_btn}" type="button"
           data-bs-toggle="collapse"
           data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
           aria-expanded="false" aria-label="Toggle navigation"><h5
          [ngClass]="{'access mt-2 me-3 ms-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'medium-f': !global.dislex, 'dislex': global.dislex}">
          ACCESIBILIDAD</h5></a>
      </div>

    </div>
  </nav>


  <div class="collapse" id="navbarToggleExternalContent">
    <div class="p-3" style="background-color: #ececec;">
      <div class="container-fluid">
        <div class="row">
          <div [ngClass]="{'d-flex justify-content-end': true,'cursor': global.cursor_btn}">
            <a [ngClass]="{'text-decoration-none fs-5 mb-3': true,'cursor': global.cursor_btn}" type="button"
               data-bs-toggle="collapse"
               data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
               aria-expanded="false" aria-label="Toggle navigation" style="color: black;">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   [ngClass]="{'bi bi-x-lg': true,'cursor': global.cursor_btn}"
                   viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd"
                      d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>
            </a>
            <a [ngClass]="{'text-decoration-none mb-3': true, 'cursor': global.cursor_btn}" type="button"
               data-bs-toggle="collapse"
               data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
               aria-expanded="false" aria-label="Toggle navigation" style="color: black;">
              <h5 [ngClass]="{'bi bi-x-lg ms-2 mt-1': true, 'cursor': global.cursor_btn, 'dislex': global.dislex}">
                Cerrar</h5>
            </a>

          </div>


          <div class="col-12 col-md-6 col-lg-3">
            <div class="card bg-transparent mb-3">
              <div class="card-body">
                <h6 [ngClass]="{'card-title':true ,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                       class="bi bi-justify-left me-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                  </svg>
                  TAMAÑO DE TEXTO
                </h6>
                <p [ngClass]="{'card-text': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Al
                  activar este filtro el tamaño del texto en los parrafos se incrementará,
                  facilitando asi la lectura en la página. </p>
                <div class="d-grid">
                  <a [ngClass]="{'btn btn-primary btn-block': true, 'cursor': global.cursor_btn}"
                     *ngIf="!global.sizetext" (click)="sizetext(true)">Activar
                    filtro</a>
                  <a [ngClass]="{'btn btn-dark btn-block': true, 'cursor': global.cursor_btn}" *ngIf="global.sizetext"
                     (click)="sizetext(false)">Desactivar
                    filtro</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card bg-transparent mb-3">
              <div class="card-body">
                <h6 [ngClass]="{'card-title':true ,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-textarea-t me-2" viewBox="0 0 16 16">
                    <path
                      d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874V2.5zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5v3.563zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    <path
                      d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386L11.434 4z"/>
                  </svg>
                  FUENTE ANTI-DISLEXIA
                </h6>
                <p [ngClass]="{'card-text': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Al
                  activar este filtro se cambiará a una fuente diseñada para mitigar algunos de
                  los errores de lectura causados por la dislexia.</p>
                <div class="d-grid">
                  <a [ngClass]="{'btn btn-primary btn-block': true, 'cursor': global.cursor_btn}" *ngIf="!global.dislex"
                     (click)="dislex(true)">Activar
                    filtro</a>
                  <a [ngClass]="{'btn btn-dark btn-block': true, 'cursor': global.cursor_btn}" *ngIf="global.dislex"
                     (click)="dislex(false)">Desactivar
                    filtro</a>

                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card bg-transparent mb-3">
              <div class="card-body">
                <h6 [ngClass]="{'card-title':true ,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-cursor-fill me-2" viewBox="0 0 16 16">
                    <path
                      d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
                  </svg>
                  AGRANDAR CURSOR
                </h6>
                <p [ngClass]="{'card-text': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Al
                  activar este filtro se agrandará el tamaño del cursor haciendolo más visible
                  para personas con capacidad visual reducida.</p>
                <div class="d-grid">
                  <a (click)="cursor()" *ngIf="!global.cursor_btn"
                     [ngClass]="{'btn btn-primary btn-block': true, 'cursor': global.cursor_btn}">Activar filtro</a>
                  <a (click)="no_cursor()" *ngIf="global.cursor_btn"
                     [ngClass]="{'btn btn-dark btn-block': true, 'cursor': global.cursor_btn}">Desactivar
                    filtro</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card bg-transparent mb-3">
              <div class="card-body">
                <h6 [ngClass]="{'card-title':true ,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-palette-fill me-2" viewBox="0 0 16 16">
                    <path
                      d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                  </svg>
                  ELIMINAR COLORES
                </h6>
                <p [ngClass]="{'card-text': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Al
                  activar este filtro se eliminarán los colores de la página creando un alto
                  contraste entre el fondo y los textos.</p>
                <div class="d-grid">

                  <a [ngClass]="{'btn btn-primary btn-block': true, 'cursor': global.cursor_btn}"
                     *ngIf="!global.color_filter" (click)="change_color(true)">Activar
                    filtro</a>
                  <a [ngClass]="{'btn btn-dark btn-block': true, 'cursor': global.cursor_btn}"
                     *ngIf="global.color_filter" (click)="change_color(false)">Desactivar
                    filtro</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <nav class="navbar navbar-expand-lg none-mobile" style="background-color: rgb(238, 241, 241);">
    <div class="container-fluid">
      <div class="col-12 col-md-12 col-lg-1 col-xl-2 d-flex justify-content-between">
        <!--<a [ngClass]="{'nav-link nav-text p-0 m-2': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}" [routerLink]="['/citas']">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
               class="bi bi-calendar3 me-1 mb-1 yellow" viewBox="0 0 16 16">
            <path
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
            <path
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg> AGENDAR CITA
        </a>-->

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-list"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
      </div>

      <div class="col-12 col-md-12 col-lg-9 col-xl-8">

        <div class="collapse navbar-collapse d-flex justify-content-center" id="navbarNav">
          <ul class="navbar-nav text-center">
            <li class="nav-item">
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/']" href="/" ><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                INICIO</h3></a>
            </li>
            <li class="nav-item">
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/sobreasembis']" href="/sobreasembis" ><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                SOBRE ASEMBIS</h3></a>
            </li>
            <li class="nav-item">
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/trabajosocial']" href="/trabajosocial"><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                TRABAJO SOCIAL</h3></a>
            </li>
            <li class="nav-item">
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/especialidades']" href="/especialidades"><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                ESPECIALIDADES</h3></a>
            </li>
           <!--  <li class="nav-item">
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/anteojos']"><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                ANTEOJOS</h3></a>
            </li> -->
            <!--<li class="nav-item">
              <a
                [ngClass]="{'nav-link': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/planesdesalud']"><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                SERVICIOS</h3>
              </a>
            </li>-->
          <li class="nav-item" *ngIf="savedPopUp?.encendido"  >
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/promo']"><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                PROMOCIÓN</h3></a>
            </li>
            <li class="nav-item">
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/convenios']" href="/convenios"><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                CONVENIOS</h3></a>
            </li>
           <li class="nav-item">
              <a
                [ngClass]="{'nav-link': true, 'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter}"
                [routerLink]="['/blog']" href="/blog"><h3
                [ngClass]="{'nav-text btn-line p-0 m-1': true,'cursor': global.cursor_btn, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                BLOG</h3></a>
            </li> 
            
          </ul>


        </div>

      </div>

      <div class="col-xl-2"></div>

    </div>
  </nav>


  <!--  Responsive menus fot mobile devices-->


  <!--  Menu accessibility for phones-->

  <nav class="navbar navbar-light bg-light menu-phone">
    <div class="container-fluid">
      <div class="col-4 col-md-4">
        <a class="logo-phone" [routerLink]="['/']" href="/" *ngIf="!global.color_filter" style="width: 100%;"> <img
          src="assets/img/logoASEMBIS.svg" alt="Logo de ASEMBIS"></a>
        <a class="logo-phone" [routerLink]="['=']" href="/" *ngIf="global.color_filter" style="width: 100%;"> <img
          src="assets/img/logoASEMBIS-black.svg" alt="Logo de ASEMBIS"></a>
      </div>

      <div class="col-4 col-md-4 d-flex justify-content-end">

        <img class="access-icon mt-1" src="assets/img/icons/access-icon.svg" alt="Icono de accesibilidad">

        <a class="text-decoration-none " type="button" data-bs-toggle="collapse"
           data-bs-target="#navbarToggleExternalContentphone" aria-controls="navbarToggleExternalContentphone"
           aria-expanded="false" aria-label="Toggle navigation" style="color: #00a44e;"><h5
          [ngClass]="{'access mt-2 me-1': true, 'green': !global.color_filter, 'black': global.color_filter,'medium-f': !global.dislex, 'dislex': global.dislex}">
          ACCESIBILIDAD</h5></a>


      </div>

    </div>
  </nav>

  <div class="collapse" id="navbarToggleExternalContentphone">
    <div class="p-3" style="background-color: #ececec;">
      <div class="container-fluid">
        <div class="row">
          <div class="d-flex justify-content-end">
            <a class="" [ngClass]="{'text-decoration-none fs-5 mb-3': true, 'dislex': global.dislex}" type="button"
               data-bs-toggle="collapse"
               data-bs-target="#navbarToggleExternalContentphone" aria-controls="navbarToggleExternalContentphone"
               aria-expanded="false" aria-label="Toggle navigation" style="color: black;">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg"
                   viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd"
                      d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>
              Cerrar</a>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <div class="card bg-transparent mb-3">
              <div class="card-body">
                <h6 [ngClass]="{'card-title':true ,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                       class="bi bi-justify-left me-2" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                  </svg>
                  TAMAÑO DE TEXTO
                </h6>
                <p [ngClass]="{'card-text': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Al
                  activar este filtro el tamaño del texto en los parrafos se incrementará,
                  facilitando asi la lectura en la página. </p>
                <div class="d-grid">
                  <a [ngClass]="{'btn btn-primary btn-block': true, 'cursor': global.cursor_btn}"
                     *ngIf="!global.sizetext" (click)="sizetext(true)">Activar
                    filtro</a>
                  <a [ngClass]="{'btn btn-dark btn-block': true, 'cursor': global.cursor_btn}" *ngIf="global.sizetext"
                     (click)="sizetext(false)">Desactivar
                    filtro</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card bg-transparent mb-3">
              <div class="card-body">
                <h6 [ngClass]="{'card-title':true ,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-textarea-t me-2" viewBox="0 0 16 16">
                    <path
                      d="M1.5 2.5A1.5 1.5 0 0 1 3 1h10a1.5 1.5 0 0 1 1.5 1.5v3.563a2 2 0 0 1 0 3.874V13.5A1.5 1.5 0 0 1 13 15H3a1.5 1.5 0 0 1-1.5-1.5V9.937a2 2 0 0 1 0-3.874V2.5zm1 3.563a2 2 0 0 1 0 3.874V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V9.937a2 2 0 0 1 0-3.874V2.5A.5.5 0 0 0 13 2H3a.5.5 0 0 0-.5.5v3.563zM2 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    <path
                      d="M11.434 4H4.566L4.5 5.994h.386c.21-1.252.612-1.446 2.173-1.495l.343-.011v6.343c0 .537-.116.665-1.049.748V12h3.294v-.421c-.938-.083-1.054-.21-1.054-.748V4.488l.348.01c1.56.05 1.963.244 2.173 1.496h.386L11.434 4z"/>
                  </svg>
                  FUENTE ANTI-DISLEXIA
                </h6>
                <p [ngClass]="{'card-text': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Al
                  activar este filtro se cambiará a una fuente diseñada para mitigar algunos de
                  los errores de lectura causados por la dislexia.</p>
                <div class="d-grid">
                  <a [ngClass]="{'btn btn-primary btn-block': true, 'cursor': global.cursor_btn}" *ngIf="!global.dislex"
                     (click)="dislex(true)">Activar
                    filtro</a>
                  <a [ngClass]="{'btn btn-dark btn-block': true, 'cursor': global.cursor_btn}" *ngIf="global.dislex"
                     (click)="dislex(false)">Desactivar
                    filtro</a>

                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card bg-transparent mb-3">
              <div class="card-body">
                <h6 [ngClass]="{'card-title':true ,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-palette-fill me-2" viewBox="0 0 16 16">
                    <path
                      d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                  </svg>
                  ELIMINAR COLORES
                </h6>
                <p [ngClass]="{'card-text': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Al
                  activar este filtro se eliminarán los colores de la página creando un alto
                  contraste entre el fondo y los textos.</p>
                <div class="d-grid">

                  <a [ngClass]="{'btn btn-primary btn-block': true, 'cursor': global.cursor_btn}"
                     *ngIf="!global.color_filter" (click)="change_color(true)">Activar
                    filtro</a>
                  <a [ngClass]="{'btn btn-dark btn-block': true, 'cursor': global.cursor_btn}"
                     *ngIf="global.color_filter" (click)="change_color(false)">Desactivar
                    filtro</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!--  Menu pages options for phones-->

  <nav class="navbar navbar bg-gray none-desktop only-phone none-ipad">
    <div class="container-fluid">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between">
        <div>
          <a href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                 class="bi bi-instagram me-2 mt-2" viewBox="0 0 16 16" style="color: #d63f87;">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
          </a>

          <a href="https://www.facebook.com/Asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-facebook mt-2"
                 viewBox="0 0 16 16" style="color: #4073b9;">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
          </a>

          <a href="https://www.tiktok.com/@asembis" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-tiktok mt-2 ms-2" viewBox="0 0 16 16" style="color: black;">
              <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
            </svg>
          </a>
        </div>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarToggleExternalContent3" aria-controls="navbarToggleExternalContent3"
                aria-expanded="false" aria-label="Toggle navigation">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list black"
               style="color: black;" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
      </div>
    </div>
  </nav>

  <div class="collapse" id="navbarToggleExternalContent3">
    <div class="bg-gray p-4">
      <ul class="navbar-nav text-center">
        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            aria-current="page" [routerLink]="['/']" href="/"
          >INICIO
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>

        <li class="nav-item me-2">
          <!--<a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/citas']"
          ><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                class="bi bi-calendar3 me-1 mb-1 turquesa" viewBox="0 0 16 16">
            <path
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
            <path
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg> AGENDAR CITA
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>-->
        </li>

        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/sobreasembis']" href="/sobreasembis" 
          >SOBRE ASEMBIS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/trabajosocial']" href="/trabajosocial"
          >TRABAJO SOCIAL
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/especialidades']" href="/especialidades"
          >ESPECIALIDADES
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <!--<li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}">ENCUENTRENOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>-->
        <!--<li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/planesdesalud']"
          >SERVICIOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>-->
        <li class="nav-item me-2" *ngIf="savedPopUp?.encendido"  >
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/promo']">PROMOCIÓN
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/convenios']" href="/convenios"
          >CONVENIOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
       <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/blog']" href="/blog"
          >BLOG
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li> 
        <!-- <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/anteojos']">
            ANTEOJOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li> -->
      </ul>

    </div>
  </div>



  <!--  Menu pages options for ipad - ipad pro-->

  <nav class="navbar navbar bg-gray only-ipad">
    <div class="container-fluid">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between">
        <!--<a
          [ngClass]="{'nav-link bold-f nav-text p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
          [routerLink]="['/citas']">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor"
               class="bi bi-calendar3 me-1 mb-1 yellow" viewBox="0 0 16 16">
            <path
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
            <path
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg>
          AGENDAR CITA
        </a>-->

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarToggleExternalContent2" aria-controls="navbarToggleExternalContent2"
                aria-expanded="false" aria-label="Toggle navigation">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list"
               viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
      </div>
    </div>
  </nav>

  <div class="collapse" id="navbarToggleExternalContent2">
    <div class="bg-gray p-4">
      <ul class="navbar-nav text-center">
        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            aria-current="page" [routerLink]="['/']" href="/"
          >INICIO
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/sobreasembis']" href="/sobreasembis"
          >SOBRE ASEMBIS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>

        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/trabajosocial']" href="/trabajosocial"
          >TRABAJO SOCIAL
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>


        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/especialidades']" href="/especialidades"
          >ESPECIALIDADES
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <!--<li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}">ENCUENTRENOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>-->
        <!--<li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/planesdesalud']"
          >SERVICIOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>-->
       <li class="nav-item me-2" *ngIf="savedPopUp?.encendido" >
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/promo']">PROMOCI&Oacute;N
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/convenios']" href="/convenios"
          >CONVENIOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
      <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/blog']" href="/blog"
          >BLOG
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li>
        <!-- <li class="nav-item me-2">
          <a
            [ngClass]="{'nav-link bold-f nav-text btn-line p-0 m-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
            [routerLink]="['/anteojos']">
            ANTEOJOS
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-arrow-right none-arrow ms-1 mb-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </a>
        </li> -->

      </ul>

      <div class="">
        <h3
          [ngClass]="{'bold-f text-big text-center mt-4': true, 'green': !global.color_filter, 'black': global.color_filter}">
          Encuentrenos en redes</h3>
        <div class="mb-5 text-center">
          <a href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                 class="bi bi-instagram ms-3 me-2" viewBox="0 0 16 16" style="color: #d63f87;">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
          </a>
          <a href="https://www.facebook.com/Asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                 class="bi bi-facebook ms-2"
                 viewBox="0 0 16 16" style="color: #4073b9;">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
          </a>
          <a href="https://www.tiktok.com/@asembis" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16" style="color: black;">
              <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>



</header>
