<app-aside></app-aside>


<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">
  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <div class="col-lg-9 pt-lg-4">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row d-flex justify-content-center">


              <div class="col-12">

                <div class="border-bottom mt-lg-2 pb-2 mb-5">
                  <h2 class="mt-lg-4">

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                      class="bi bi-images me-2 mb-1" viewBox="0 0 16 16" style="color: #6610f2;">
                      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                      <path
                        d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                    </svg>Editar portada
                  </h2>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Agregue las portadas de los componentes correspondientes.</p>
                  </div>
                </div>
              </div>

              <div class="alert alert-success alert-dismissible fade" role="alert" *ngIf="msm_success"
                [class.show]="msm_success">
                Se actualizó los datos de las configuraciones correctamente.
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div class="alert alert-danger alert-dismissible fade" role="alert" *ngIf="msm_error && msm_error !== ''"
                [class.show]="msm_error && msm_error !== ''">
                {{msm_error}}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>




              <div class="col-lg-12">
                <form #portadaForm="ngForm" (ngSubmit)="onSubmit(portadaForm)">
                  <div class="card border-0 box-shadow">

                    <div class="card-body">
                      <h4 class="mb-3 mt-4 text-center">Seleccione el tipo de portada principal <svg
                          xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                          class="bi bi-arrow-down-circle-fill" viewBox="0 0 20 20">
                          <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                        </svg></h4>
                      <div class="tab-content" style="margin-top: 20px;">
                        <div class="tab-pane fade active show">

                          <div class="form-group row align-items-center mb-4">
                            <div class="col-md-11">
                              <select class="form-select" id="select-input" [(ngModel)]="portada.tipo" name="tipo"
                                required>
                                <option value="Portada">Imagen / Video</option>
                                <option value="Slider">Slider</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group row align-items-center mt-5" id="logo-container">
                            <h5 class="text-center">Imagen o video desktop aquí <svg xmlns="http://www.w3.org/2000/svg"
                                width="25" height="25" fill="currentColor" class="bi bi-arrow-down-circle-fill"
                                viewBox="0 0 16 16">
                                <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                              </svg></h5>
                            <div class="col-md-12">
                              <div class="cz-file-drop-area">

                                <div *ngIf="desktopFileType == 'image'"
                                  class="d-flex justify-content-center align-items-center img-thumbnail rounded p-5">
                                  <img [src]="imgSelectPortada || url+'portada/resources/thumbnails/'+portada?.portada"
                                    alt="imagen de portada para desktop" style="width: 70%;">
                                </div>
                                <div *ngIf="desktopFileType == 'video'" style="width: 100%;"
                                  class="cz-file-drop-preview img-thumbnail rounded">
                                  <video *ngIf="imgSelectPortada" style="width: 100%;" autoplay loop [muted]="true">
                                    <source [src]="imgSelectPortada" [type]="filePortada.type">
                                  </video>
                                  <video *ngIf="!imgSelectPortada" style="width: 100%;" autoplay loop [muted]="true">
                                    <source [src]="url+'/portada/resources/thumbnails/' + portada?.portada"
                                      [type]="'video/' + portada?.portada?.split('.').pop()">
                                  </video>
                                </div>

                                <input type="file" class="form-control form-control-sm"
                                  (change)="imgSelected_portada($event)">
                              </div>
                              <p class="small text-muted mt-2">Tamaño recomendado: 1700px X 740px - Peso recomendado:
                                menos de 120kb en formato webp</p>

                            </div>
                          </div>

                          <div class="form-group row align-items-center mt-4" id="logo-container">
                            <h5 class="text-center">Imagen o video mobile aquí <svg xmlns="http://www.w3.org/2000/svg"
                                width="25" height="25" fill="currentColor" class="bi bi-arrow-down-circle-fill"
                                viewBox="0 0 16 16">
                                <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                              </svg></h5>
                            <div class="col-md-12">

                              <div class="cz-file-drop-area">

                                <div *ngIf="mobileFileType == 'image'"
                                  class="d-flex justify-content-center align-items-center img-thumbnail rounded p-5">
                                  <img
                                    [src]="imgSelectPortadaMobile || url+'portada/resources/thumbnails/'+portada?.portadaMobile"
                                    alt="Imagen de portada para mobile" style="width: 30%;">
                                </div>

                                <div *ngIf="mobileFileType == 'video'" style="width: 100%;"
                                  class="cz-file-drop-preview img-thumbnail rounded">
                                  <video *ngIf="imgSelectPortadaMobile" style="width: 100%;" autoplay loop
                                    [muted]="true">
                                    <source [src]="imgSelectPortadaMobile" [type]="file_portada_mobile.type">
                                  </video>
                                  <video *ngIf="!imgSelectPortadaMobile" style="width: 100%;" autoplay loop
                                    [muted]="true">
                                    <source [src]="url+'/portada/resources/thumbnails/' + portada?.portadaMobile"
                                      [type]="'video/' + portada?.portadaMobile?.split('.').pop()">
                                  </video>
                                </div>
                                <input type="file" class="form-control form-control-sm"
                                  (change)="imgSelectedPortadaMobile($event)">

                              </div>
                              <p class="small text-muted mt-2">Tamaño recomendado: 600px X 990px - Peso recomendado:
                                menos
                                de
                                50kb en formato webp</p>

                            </div>

                          </div>

                          <div class="form-group row align-items-center mt-5" id="logo-container">

                            <div class="col-md-12 border pt-5">
                              <h5 class="text-center">Slider desktop aquí <svg xmlns="http://www.w3.org/2000/svg"
                                  width="25" height="25" fill="currentColor" class="bi bi-arrow-down-circle-fill"
                                  viewBox="0 0 16 16">
                                  <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                                </svg></h5>

                              <div class="">
                                <label for="formFile" class="form-label mt-4">- click para seleccionar imagenes -
                                  <strong>Debe seleccionar mínimo 3 imagenes y máximo 5.</strong></label>
                                <input class="form-control" type="file" id="formFile" ngxFileDrop
                                  (change)="onSelectSlider($event)" [multiple]="true" accept="image/*">
                              </div>
                              <p class="small text-muted mt-2  mb-5">Tamaño recomendado: 1700px X 740px - Peso
                                recomendado:
                                menos de 50kb en formato webp</p>

                              <div class="row d-flex mb-5">
                                <div class="col-4" *ngFor="let f of filesSlider">
                                  <div class="card bgGray m-3">
                                    <img class="card-img-top" [src]="f.dataURL" alt="preview" />
                                    <div class="card-body">
                                      <h5 class="fs-6">Imagen del carrusel</h5>
                                      <a class="btn btn-dark rounded" (click)="onRemoveSlider(f)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                          <path
                                            d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                        Eliminar</a>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr>

                              <h5 class="text-center mt-5">Slider mobile aquí <svg xmlns="http://www.w3.org/2000/svg"
                                  width="25" height="25" fill="currentColor" class="bi bi-arrow-down-circle-fill"
                                  viewBox="0 0 16 16">
                                  <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                                </svg></h5>

                              <div class="mb-3">
                                <label for="formFile" class="form-label mt-4">- click para seleccionar imagenes -
                                  <strong>Debe seleccionar mínimo 3 imagenes y máximo 5.</strong></label>
                                <input class="form-control" type="file" id="formFile" ngxFileDrop
                                  (change)="onSelectSliderMobile($event)" [multiple]="true" accept="image/*">
                              </div>
                              <p class="small text-muted mt-2">Tamaño recomendado: 600px X 990px - Peso recomendado:
                                menos de
                                30kb en formato webp</p>

                              <div class="row d-flex">
                                <div class="col-4" *ngFor="let f of filesSliderMobile">
                                  <div class="card bgGray m-3">
                                    <img class="card-img-top" [src]="f.dataURL" alt="preview" />
                                    <div class="card-body">
                                      <h5 class="fs-6">Imagen del carrusel mobile</h5>
                                      <a class="btn btn-dark rounded" (click)="onRemoveSliderMobile(f)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                          fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                          <path
                                            d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                        Eliminar</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="card-footer pb-5">
                      <button *ngIf="!isUpdating" class="btn btn-primary mb-2 me-1" type="submit"
                        [disabled]="isUpdating">Actualizar
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                          class="bi bi-cloud-upload" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                          <path fill-rule="evenodd"
                            d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z" />
                        </svg>
                      </button>

                      <button *ngIf="isUpdating" class="btn btn-secondary mb-2 me-1" type="button" disabled>
                        Actualizando <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                          class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                        </svg>
                      </button>
                    </div>

                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
     
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
     
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">


            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2 mb-5">
                <h1 class="mt-lg-4">Editor de portadas</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Agregue las portadas de los componentes correspondientes.</p>

                </div>

              </div>
            </div>
            <div class="col-lg-12 form-group" *ngIf="msm_success">
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                Se actualizó los datos de las configuraciones correctamente.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="col-lg-12 form-group" *ngIf="msm_error && (msm_error != '')">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                {{msm_error}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <div class="col-lg-12">
              <form #portadaForm="ngForm" (ngSubmit)="onSubmit(portadaForm)">
                <div class="card border-0 box-shadow">

                  <div class="card-body">
                    <h3 class="mb-3">Portada principal</h3>
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Tipo de
                            portada</label>
                          <div class="col-md-10">
                            <select class="form-control custom-select" id="select-input" [(ngModel)]="portada.tipo"
                              name="tipo" required>
                              <option value="Portada">Imagen / Video</option>
                              <option value="Slider">Slider</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Portada</label>
                          <div class="col-md-10">
                            <div class="cz-file-drop-area">

                              <div *ngIf="desktopFileType == 'image'"
                                class="cz-file-drop-preview img-thumbnail rounded">
                                <img [src]="imgSelectPortada || url+'portada/resources/thumbnails/'+portada?.portada"
                                  alt="04.jpg">
                              </div>
                              <div *ngIf="desktopFileType == 'video'" style="width: 100%;"
                                class="cz-file-drop-preview img-thumbnail rounded">
                                <video *ngIf="imgSelectPortada" style="width: 100%;" autoplay loop [muted]="true">
                                  <source [src]="imgSelectPortada" [type]="filePortada.type">
                                </video>
                                <video *ngIf="!imgSelectPortada" style="width: 100%;" autoplay loop [muted]="true">
                                  <source [src]="url+'/portada/resources/thumbnails/' + portada?.portada"
                                    [type]="'video/' + portada?.portada?.split('.').pop()">
                                </video>
                              </div>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelected_portada($event)">
                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 1700px X 740px - Peso recomendado:
                              200kb</p>

                          </div>
                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Portada
                            Mobile</label>
                          <div class="col-md-10">
                            <div class="cz-file-drop-area">
                              <div *ngIf="mobileFileType == 'image'" class="cz-file-drop-preview img-thumbnail rounded">
                                <img
                                  [src]="imgSelectPortadaMobile ||url+'portada/resources/thumbnails/'+portada?.portadaMobile"
                                  alt="04.jpg">
                              </div>
                              <div *ngIf="mobileFileType == 'video'" style="width: 100%;"
                                class="cz-file-drop-preview img-thumbnail rounded">
                                <video *ngIf="imgSelectPortadaMobile" style="width: 100%;" autoplay loop [muted]="true">
                                  <source [src]="imgSelectPortadaMobile" [type]="file_portada_mobile.type">
                                </video>
                                <video *ngIf="!imgSelectPortadaMobile" style="width: 100%;" autoplay loop
                                  [muted]="true">
                                  <source [src]="url+'/portada/resources/thumbnails/' + portada?.portadaMobile"
                                    [type]="'video/' + portada?.portadaMobile?.split('.').pop()">
                                </video>
                              </div>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelectedPortadaMobile($event)">

                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 600px X 990px - Peso recomendado:
                              100kb</p>

                          </div>

                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Slider</label>
                          <div class="col-md-10">
                            <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'"
                              (change)="onSelectSlider($event)">
                              <ngx-dropzone-label>
                                <div>
                                  <h2>Arrastra 3 imagenes aquí</h2>
                                </div>
                              </ngx-dropzone-label>
                              <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                *ngFor="let f of filesSlider" [file]="f" [removable]="true"
                                (removed)="onRemoveSlider(f)">

                              </ngx-dropzone-image-preview>
                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 1700px X 740px - Peso recomendado:
                              200kb c/u</p>
                          </div>
                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Slider
                            Mobile</label>
                          <div class="col-md-10">
                            <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'"
                              (change)="onSelectSliderMobile($event)">
                              <ngx-dropzone-label>
                                <div>
                                  <h2>Arrastra 3 imagenes aquí</h2>
                                </div>
                              </ngx-dropzone-label>
                              <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                *ngFor="let f of filesSliderMobile" [file]="f" [removable]="true"
                                (removed)="onRemoveSliderMobile(f)">

                              </ngx-dropzone-image-preview>
                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 600px X 990px - Peso recomendado: 100kb
                              c/u</p>
                          </div>
                        </div>


                        <h3 class="mt-3 mb-3">Portada Mujer</h3>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Portada
                            Mujer</label>
                          <div class="col-md-10">
                            <div class="cz-file-drop-area">

                              <div class="cz-file-drop-preview img-thumbnail rounded">
                                <img
                                  [src]="imgSelectPortadaMujer || url+'portada/resources/thumbnails/'+portada?.portadaMujer"
                                  alt="04.jpg">
                              </div>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelectedPortadaMujer($event)">
                            </div>
                            <p class="small text-muted mt-2">Tamaño requerido: 600px X 600px - Peso recomendado:
                              100kb</p>

                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary btn-sm mb-2 mr-1" type="submit">Actualizar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div> -->