import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  public url;

  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
  }

  listar():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'promo',{headers:headers})
  }


  eliminar(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url + 'promo/eliminar/'+id,{headers:headers})
  }


  update(data):Observable<any>{
    const fd = new FormData();
    fd.append('titulo',data.titulo);
    fd.append('poster',data.poster);
    fd.append('detalle',data.detalle);
    fd.append('validez',data.validez);

    return this._http.put(this.url + 'promo/update/'+data._id,fd);
  }

  registro(data: { titulo: any; poster: any; detalle: any; validez: any; }):Observable<any>{
    const fd = new FormData();
    fd.append('titulo',data.titulo);
    fd.append('poster',data.poster);
    fd.append('detalle',data.detalle);
    fd.append('validez',data.validez);

    return this._http.post(this.url + 'promo/registro',fd);
  }
}
