<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">
  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-3">
          <app-menu></app-menu>
        </aside>

        <div class="col-9 pt-lg-4 pb-4 mb-3">
          <div class="row">

            <div class="toast-container">
              <div class="toast" id="dark-toast" data-delay="5000" role="alert" aria-live="assertive"
                   aria-atomic="true">
                <div class="toast-header">
                  <img src="..." class="rounded me-2" alt="...">
                  <strong class="me-auto">Notificación</strong>
                  <small class="text-muted">Ahora</small>
                  <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"
                          (click)="close_toast()"></button>
                </div>
                <div class="toast-body">
                  Se eliminó corractamente el registro.
                </div>
              </div>
            </div>

            <div class="col-11 border-bottom">
              <div class="mb-2 mt-4 pb-2">

                <h2><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" style="color: #6610f2;" fill="currentColor" class="bi bi-eyeglasses" viewBox="0 0 16 16">
                  <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
                </svg> Modulo de anteojos</h2>
                <p>Productos disponibles en el sitio web.</p>

                <div class="d-flex justify-content-star">
                  <a [routerLink]="['/admin/producto/registro']" style="cursor: pointer;">
                    <button type="button" class="btn btn-primary me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-plus-circle me-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                      </svg>
                      Nuevo Producto
                    </button>
                  </a>

                  <a [routerLink]="['/admin/producto/papelera']">
                    <button type="button" class="btn btn-dark me-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-trash me-1" viewBox="0 0 16 16">
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg>
                      Ir a Papelera
                    </button>
                  </a>

                  <form #searchForm="ngForm" (keyup)="search(searchForm)">
                    <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                      <button type="submit" class="btn btn-light">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-search" viewBox="0 0 16 16">
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                      </button>
                      <input class="form-control" type="text" placeholder="Nombre" aria-label="default input example"
                             name="filtro" [(ngModel)]="filtro" style="padding-left: 10px;">

                      <select class="form-select" name="search_categoria" aria-label="Default select example"
                              [(ngModel)]="search_categoria"
                              (change)="search_cat()">
                        <option value="" selected disabled>Categoría</option>
                        <option [value]="item._id" *ngFor="let item of categorias">{{item.nombre}}</option>
                      </select>

                    </div>
                  </form>

                </div>

              </div>
            </div>

            <div class="col-12">

              <div class="card mb-3 mt-3 ms-3" style="max-width: 80%"
                   *ngFor="let item of productos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img [src]="url+'productos_img_data/resources/thumbnails/'+item.poster"
                         class="img-fluid rounded" alt="...">
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h4 class="card-title">{{item.titulo}}</h4>
                      <div class="d-flex justify-content-start mb-3">
                        <p class="card-text me-3 mb-0">Precio: ₡{{item.precio_ahora}}</p>
                        <p class="card-text" *ngIf="item?.precio_antes">Antes: <span
                          class="text-decoration-line-through">₡{{item.precio_antes}}</span></p>
                      </div>
                      <div class="font-size-sm mb-4">
                        <span class="fw-bold">Estado:</span>
                        <span class="badge bg-warning text-dark ms-2"
                              *ngIf="item.status == 'Edición'">{{item.status}}</span>
                        <span class="badge bg-primary ms-2"
                              *ngIf="item.status == 'Activo'">{{item.status}}</span>
                        <span class="badge bg-secondary ms-2"
                              *ngIf="item.status == 'Desactivado'">{{item.status}}</span>
                      </div>


                      <button type="button" class="btn btn-primary"
                              *ngIf="item.status == 'Edición' || item.status == 'Desactivado'"
                              data-toggle="modal" [attr.data-target]="'#activar-'+item._id">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-check2 me-1" viewBox="0 0 16 16">
                          <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        Activar
                      </button>

                      <div class="modal fade" [attr.id]="'activar-'+item._id" tabindex="-1"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
                              <button type="button" class="btn-close" aria-label="Close" data-dismiss="modal"></button>

                            </div>
                            <div class="modal-body">
                              <p class="font-size-sm">Al aceptar, estás confirmando que el producto fué
                                editado correctamente y se mostrara en el sitio web.</p>
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">
                                Cerrar
                              </button>
                              <button class="btn btn-primary btn-sm" type="button"
                                      (click)="activar(item._id)">Aceptar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button type="button" class="btn btn-secondary" *ngIf="item.status == 'Activo'"
                              data-toggle="modal" [attr.data-target]="'#desactivar-'+item._id">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-x-lg me-1" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                          <path fill-rule="evenodd"
                                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                        </svg>
                        Desactivar
                      </button>

                      <div class="modal fade" [attr.id]="'desactivar-'+item._id" tabindex="-1"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel2">Confirmación</h5>
                              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="font-size-sm">Al aceptar el producto, no será visible en el sitio web. Podrás
                                hacerlo visible en el futuro.</p>
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">
                                Cerrar
                              </button>
                              <button class="btn btn-primary btn-sm" type="button"
                                      (click)="desactivar(item._id)">Aceptar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="btn-group" role="group">
                        <button type="button" class="btn btn-warning dropdown-toggle ms-2" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-card-checklist me-1" viewBox="0 0 16 16">
                            <path
                              d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path
                              d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                          </svg>
                          Opciones
                        </button>
                        <div class="dropdown-menu">
                          <a [routerLink]="['/admin/producto/',item._id]" class="dropdown-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                              <path fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>&nbsp;Editar</a>
                          <a [routerLink]="['/admin/producto/',item._id,'galeria']"
                             class="dropdown-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-card-image" viewBox="0 0 16 16">
                              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                              <path
                                d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
                            </svg>&nbsp;Galería</a>
                          <!--<a [routerLink]="['/admin/producto/',item._id,'selector']"
                             class="dropdown-item"><span class="czi-menu-circle"></span> &nbsp;Tallas</a>
                          <a [routerLink]="['/admin/producto/',item._id,'color']" class="dropdown-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-palette" viewBox="0 0 16 16">
                              <path
                                d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                              <path
                                d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8zm-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7z"/>
                            </svg> &nbsp;Colores</a>-->
                        </div>
                      </div>

                      <button type="button" class="btn btn-dark ms-2" data-toggle="modal"
                              [attr.data-target]="'#papelera-'+item._id">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-trash" viewBox="0 0 16 16">
                          <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                          <path fill-rule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                      </button>

                      <div class="modal fade" [attr.id]="'papelera-'+item._id" tabindex="-1"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel3">Confirmación</h5>
                              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="font-size-sm">El producto se moverá a la papelera de reciclaje.</p>
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">
                                Cerrar
                              </button>
                              <button class="btn btn-primary btn-sm" type="button"
                                      (click)="papelera(item._id)">Aceptar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>


            </div>

            <div class="col-lg-12">
              <ngb-pagination
                [(page)]="page"
                [pageSize]="pageSize"
                [collectionSize]="productos.length"></ngb-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>


