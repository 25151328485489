import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";
import { map } from 'rxjs/operators';
import { PopUp } from '../models/popUp';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {

  public url: string;
  private popUpSubject: BehaviorSubject<PopUp>;

  constructor(
    private _http: HttpClient,
  ) {
    this.url = GLOBAL.url;
    this.popUpSubject = new BehaviorSubject<PopUp>({ encendido: false });
  }

  createOrUpdatePopUp(data): Observable<any> {
    const fd = new FormData();
    fd.append('textSection1', data.textSection1);
    fd.append('textSection2', data.textSection2);
    fd.append('textSection3', data.textSection3);
    fd.append('landingCoverImage', data.landingCoverImage);
    fd.append('mobileCoverImage', data.mobileCoverImage);
    fd.append('popUpCoverImage', data.popUpCoverImage);
    fd.append('encendido', data.encendido ? 'true' : 'false');
    return this._http.post(this.url + 'popup', fd);
  }
  
  getPopUp(): Observable<PopUp> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get<{ popUp: PopUp }>(this.url + 'popup', { headers: headers }).pipe(
      map((response) => {
        if (response && response.popUp) {
          response.popUp.encendido = response.popUp.encendido === 'true';
          return response.popUp;
        }
        return null; // Manejo de un posible caso donde no haya popUp
      })
    );
  }

  setPopUp(popUp: PopUp): void {
    this.popUpSubject.next(popUp);
  }

  getLocalPopUp(): Observable<PopUp> {
    return this.popUpSubject.asObservable();
  }
}
