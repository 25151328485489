import { Component, OnInit } from '@angular/core';
import { ContactoService } from 'src/app/services/contacto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import * as moment from 'moment';
interface HtmlInputEvent extends Event{
  target : HTMLInputElement & EventTarget;
}
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-contacto-admin',
  templateUrl: './contacto-admin.component.html',
  styleUrls: ['./contacto-admin.component.css']
})
export class ContactoAdminComponent implements OnInit {

  public url;
  public identity;
  public mensajes : Array<any> = [];
  public page;
  public pageSize = 15;
  public count_cat;
  
  constructor(
    private _userService: UserService,
    private _router : Router,
    private _route :ActivatedRoute,
    private _contactoService :ContactoService
  ) { 
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this._contactoService.listar().subscribe(
      response => {
        this.mensajes = response.data.map(item => {
          return {
            ...item,
            createdAt: moment(item.createdAt).format('DD-MM-YYYY')
          };
        });
        this.count_cat = this.mensajes.length;
        this.page = 1;
      },
      error => {
        // Manejo de errores
      }
    );
    }


    eliminarMensajes() {
      this._contactoService.borrarTodos().subscribe(
        response => {
          this._contactoService.listar().subscribe(
            response => {
              this.mensajes = response.data.map(item => {
                return {
                  ...item,
                  createdAt: moment(item.createdAt).format('DD-MM-YYYY')
                };
              });
              this.count_cat = this.mensajes.length;
              this.page = 1;
            },
            error => {
              // Manejo de errores
            }
          );
          $('#exampleModal').modal('hide');
          // Aquí puedes añadir lógica adicional, como actualizar la interfaz de usuario
        },
        error => {
          alert(`Error: ${error.message}`);
        }
      );
    }

    exportarExcel() {
      this._contactoService.exportarExcel().subscribe(
        response => {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'contactos.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        },
        error => {
          alert(`Error: ${error.message}`);
        }
      );
    }

  }
