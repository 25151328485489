<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-9 pt-lg-4 pb-4 mb-3">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">


              <div class="toast-container toast-bottom-right">
                <div class="toast mb-3 fade hide" id="dark-toast" data-delay="5000" role="alert" aria-live="assertive"
                     aria-atomic="true">
                  <div class="toast-header bg-accent text-white"><i class="czi-time mr-2"></i>
                    <h6 class="font-size-sm text-white mb-0 mr-auto">Notificación</h6>
                    <button class="close text-white ml-2 mb-1" type="button" data-dismiss="toast" aria-label="Close"
                            (click)="close_toast()"><span aria-hidden="true">×</span></button>
                  </div>
                  <div class="toast-body text-accent">Se eliminó corractamente el registro.</div>
                </div>
              </div>


              <div class="col-lg-12">
                <div class="border-bottom mt-lg-2 pb-2 mb-5">
                  <h2 class="mt-lg-4"><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-file-richtext mb-1 me-1" style="color: #6610f2;"  viewBox="0 0 16 16">
                    <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                  </svg>Modulo de Blog</h2>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Aquí encontrará las entradas de blog disponibles en el sitio web </p>

                  </div>

                  <div class="d-flex justify-content-start">

                    <a [routerLink]="['/admin/article/new']" style="cursor: pointer;" href="/admin/article/new">
                      <button type="button" class="btn btn-primary me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-plus-circle me-1" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Nueva entrada
                      </button>
                    </a>


                    <form #searchForm="ngForm" (keyup)="search(searchForm)">
                      <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                        <button type="submit" class="btn btn-light">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-search" viewBox="0 0 16 16">
                            <path
                              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                          </svg>
                        </button>
                        <input class="form-control" type="text" placeholder="Título" aria-label="default input example"
                               name="filtro" [(ngModel)]="filtro" style="padding-left: 10px;">

                        <button type="button" (click)="resetSearch()" class="btn btn-dark"
                                title="Resetear filtro">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
                          </svg>
                        </button>
                      </div>

                    </form>

                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-sm">
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Creación</th>
                      <th>Opciones</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="count_art >= 1">
                    <tr *ngFor="let item of articles| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                      <td>{{item.nombre}}</td>
                      <td>{{ item.createdAt | date:'MMM d, y, h:mm:ss a' }}</td>
                      <td>
                        <!-- Example single danger button -->
                        <div class="btn-group">
                          <button type="button" class="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                              <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                            </svg>
                          </button>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" style="cursor: pointer;" [routerLink]="['/admin/article/',item._id]" href="/admin/article/{{item._id}}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square me-1" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                              </svg>
                              Modificar</a></li>

                            <li><a class="dropdown-item" style="cursor: pointer;" data-toggle="modal" [attr.data-target]="'#modal-'+item._id">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash me-1" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                              </svg>
                              Eliminar</a></li>
                          </ul>
                        </div>
                      </td>


                      <div class="modal fade" [attr.id]="'modal-'+item._id" tabindex="-1"
                           aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel3">Confirmación</h5>
                              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p class="font-size-sm">¿Desea eliminar definitivamente esta entrada?</p>
                              <span class="font-size-sm text-muted"><b>Artículo:</b> {{item.nombre}}</span>
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">
                                Cerrar
                              </button>
                              <button class="btn btn-primary btn-sm" type="button"
                                      (click)="eliminar(item._id)">Aceptar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="modal fade" [attr.id]="" tabindex="-1" role="dialog"
                           aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h4 class="modal-title">Confirmación</h4>
                              <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">×</span></button>
                            </div>
                            <div class="modal-body">
                              <p class="font-size-sm">¿Desea eliminar definitivamente esta entrada?</p>
                              <span class="font-size-sm text-muted"><b>Nombre:</b> {{item.nombre}}
                                - {{item._id}}</span>
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar
                              </button>
                              <button class="btn btn-primary btn-shadow btn-sm" type="button"
                                      (click)="eliminar(item._id)">Suprimir
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </tr>

                    </tbody>
                    <tbody *ngIf="count_art <= 0">
                    <tr>
                      <td colspan="6" class="text-center">No se encontró ningún dato, vuelva a intentar.</td>
                    </tr>

                    </tbody>

                  </table>
                  <ngb-pagination
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [collectionSize]="articles.length"></ngb-pagination>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  </div>
</section>
