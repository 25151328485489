import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular'
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import { UserService } from 'src/app/services/user.service';
import {NgForm} from "@angular/forms";
import {CloudUser} from "../../models/CloudUser";
import {GLOBAL} from "../../services/GLOBAL";
declare var grecaptcha: any;
import {CitaService} from "../../services/cita.service";

@Component({
  selector: 'app-loginv2',
  templateUrl: './loginv2.component.html',
  styleUrls: ['./loginv2.component.css']
})

export class Loginv2Component implements OnInit {
  public global = GLOBAL;
  public manager = false;
  public showCreateUser = false;
  public users: Array<any> = [];
  public newUser = new CloudUser('','');
  public convenios: Array<any> = [];
  public convenio = "";
  public browserRecaptchaKey = this.global.browserRecaptchaKey;
  public errorConvenios: string;
  public nombreUsuario: string;
  public usuarioCreado = false;

  constructor( private msalService: MsalService, private _http: HttpClient, private userService: UserService, private _citaService: CitaService) {
    this.users = [];
  }

  ngOnInit(): void {
    this.users = [];
    this.userService.getMe().subscribe(res => {
      // @ts-ignore
      this.nombreUsuario = res.mail;
      // @ts-ignore
      this.global.userMail = res.mail;
    });
  }

  onLogout(){
    this.msalService.logoutRedirect();
  }

  async usersManagment() {
    var users = await this.userService.usersManagment();
    // @ts-ignore
    this.users = users.value;
    this.showCreateUser = false;
    this.usuarioCreado = false;
  }

  loginManager() {
    this.userService.usersManager().subscribe(res => {
      this.manager = true;
      this.listarConvenios();
    },error => {
      this.errorConvenios = "No tienes permisos suficientes";
    });
  }

  createUser(userForm: NgForm) {
    if (this.convenio != "") {
      this.userService.createUser(this.newUser.mail).subscribe( async res => {
        this.setJobTitle(res.invitedUser.id);
        this.usuarioCreado = true;
        this.newUser = new CloudUser('','');
        this.convenio = "";
        this.errorConvenios = "";
      }, error => {
        this.errorConvenios = "No se pudo crear el usuario";
      })
    }
    else {
      this.errorConvenios = "Seleccione un convenio";
    }
  }

  setJobTitle(userId) {
    this.userService.setJobTitle(userId, this.convenio).subscribe( res => {
    });
  }

  listarConvenios() {
    this.convenios = [];
    var recaptchaAction = 'ListarConvenios';
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(this.browserRecaptchaKey, {action: recaptchaAction});
      var data = {
        recaptchaToken: token,
        recaptchaAction: recaptchaAction
      }
      this._citaService.listarConvenios(data).subscribe(
        response => {
          // @ts-ignore
          for (let key in response.convenios) {
            // @ts-ignore
            this.convenios.push ({key : key, value: response.convenios[key].Convenio});
          }
        },error => {
          this.errorConvenios = "No pudimos comunicarnos con el servidor, intente más tarde";
        }
      );
    });
  }

  deleteUser(userId) {
    this.userService.deleteUser(userId).subscribe(async res => {
      var users = await this.userService.usersManagment();
      // @ts-ignore
      this.users = users.value;
    });
  }

  showCreateUserForm() {
    this.showCreateUser = true;
  }
}
