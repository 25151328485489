<app-nav></app-nav>

<section class="graycolor">
  <div class="page-title-overlap pt-4" style="background-color: #b1a0a2;">
    <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
      <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
            <li class="breadcrumb-item"><a class="text-nowrap" [routerLink]="['/home/inicio']"
                                           routerLinkActive="router-link-active"><i class="czi-home"></i>Inicio</a></li>
            <li class="breadcrumb-item text-nowrap"><a [routerLink]="['/colecciones']"
                                                       routerLinkActive="router-link-active"><span
              class="czi-arrow-right text-nowrap"></span> Colecciones</a>
            </li>
            <li class="breadcrumb-item text-nowrap active" aria-current="page"><span
              class="czi-arrow-right text-nowrap"></span>My bag
            </li>
          </ol>
        </nav>
      </div>
      <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
        <h1 class="poppins text-light bigtext mb-0"><span class="czi-bag"></span> My bag</h1>
      </div>
    </div>
  </div>
  <!-- Page Content-->
  <div class="container pb-5">
    <div class="row">
      <!-- List of items-->
      <section class="col-lg-8">
        <div class="d-flex justify-content-between align-items-center pt-3 pb-2 pb-sm-5 mt-1">
          <h2 class="poppinsbold hugetext textwhite mb-0">Productos</h2>
        </div>
        <!-- Item-->
        <div class="alert alert-danger alert-with-icon" role="alert" *ngIf="error_stock">
          <div class="alert-icon-box">
            <i class="alert-icon czi-close-circle"></i>
          </div>
          Algún producto de su bolsa de compras no tiene stock.
        </div>

        <ng-container *ngIf="carrito.length != 0">
          <div *ngFor="let item of carrito; index as i"
               class="d-sm-flex justify-content-between align-items-center my-4 pb-3 border-bottom">

            <div class="media media-ie-fix d-block d-sm-flex align-items-center text-center text-sm-left"><a
              class="d-inline-block mx-auto mr-sm-4 mt-2" [routerLink]="['/producto/',item.producto.slug]"
              style="width: 10rem;"><img [src]="url+'productos_img_data/resources/thumbnails/'+item.producto.poster"
                                         alt="Product"></a>
              <div class="media-body pt-2">
                <h3 class="product-title font-size-base mb-2"><a [routerLink]="['/producto/',item.producto.slug]"
                                                                 style="color: black !important;">{{item.producto.titulo}}</a>
                </h3>

                <ng-container *ngIf="item.producto.stock > 0">
                  <div class="font-size-sm"><span class="text-muted mr-2">{{item.producto.nombre_selector}}
                    :</span>{{item.selector}}</div>
                  <div class="font-size-sm"><span class="text-muted mr-2">COLOR:</span>
                    {{item.color}}

                  </div>
                  <div class="font-size-lg text-dark pt-2">${{item.precio}}</div>
                </ng-container>
                <ng-container *ngIf="item.producto.stock == 0">
                  <span class="badge badge-danger">No hay stock del producto</span>
                </ng-container>

              </div>
            </div>
            <div class="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left" style="max-width: 9rem;">
              <div class="form-group mb-0">
                <label class="font-weight-medium" for="quantity1">Cantidad</label>
                <input class="form-control" type="number" id="quantity1" readonly [value]="item.cantidad">
              </div>
              <button class="btn btn-link px-0 text-danger" type="button" (click)="remove_producto(i)"><i
                class="czi-close-circle mr-2"></i><span class="font-size-sm">Eliminar</span></button>
            </div>

          </div>
        </ng-container>
        <div *ngIf="carrito.length == 0" class="my-4 pb-3 border-bottom">
          <!-- Alert with bakground image -->
          <div class="row">
            <div class="col-lg-12">
              <div class="alert alert-bg-image alert-dismissible fade show text-center py-5"
                   style="background-image: url(https://demo.createx.studio/cartzilla/img/components/alerts/alert-bg.jpg)"
                   role="alert">
                <i class="czi-time d-block text-dark my-4" style="font-size: 3rem;"></i>
                <h2 class="text-dark text-shadow">Tu bolsa actualmente esta vacía!</h2>
                <a [routerLink]="['/colecciones']" class="btn btn-outline-dark mb-3 mt-3">Ir a comprar</a>
                <button type="button" class="close text-dark" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class=" center-text-component">
          <a class="btn btn-dark btn-sm pl-2" [routerLink]="['/colecciones']" routerLinkActive="router-link-active"><i
            class="czi-arrow-left mr-2"></i>Continuar comprando</a>
        </div>

      </section>
      <!-- Sidebar-->
      <aside class="col-lg-4 pt-4 pt-lg-0">
        <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
          <div class="text-center mb-4 pb-3 border-bottom">
            <h2 class="h6 mb-3 pb-1">Total de su compra</h2>
            <h3 class="font-weight-normal">${{subtotal ? subtotal : 0}}</h3>
          </div>
          <div class="accordion mb-4 cart-data-venta" id="order-options" *ngIf="carrito.length != 0">


          </div>
          <div class="invalid-feedback" style="display: block;" *ngIf="msm_error">{{msm_error}}</div>

          <button class="btn btn-dark btn-block mt-4 mb-4" *ngIf="carrito.length != 0" id="btn-verify-data"
                  (click)="verify_data()"><i class="czi-card font-size-lg mr-2"></i>Pagar ahora
          </button>

          <button class="btn btn-dark btn-block mt-4 mb-4" id="btn-back-data" (click)="back_data()"><i
            class="czi-reply font-size-lg mr-2"></i>Regresar
          </button>
          <div *ngIf="show_alternatice_paymente">
            <p class="poppins regulartext textblack text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                   class="bi bi-cash-coin mt-2 mb-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                <path
                  d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                <path
                  d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
              </svg>
              <br>
              También podés hacer tu pago a través de SINPE móvil o transferencia bancaria. Ponéte en contacto con
              nosotros.
            </p>
            <p class="smalltext textblack poppins text-center" style="line-height: 1;">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
              </svg> +506 7260 1197

            </p>
            <p class="poppins smalltext textblack text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor"
                   class="bi bi-envelope-fill mr-1" viewBox="0 0 16 16">
                <path
                  d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
              </svg>
              info@amagnr.com
            </p>
          </div>


          <div class="card" id="card-pay">
            <div class="card-header">
              <h3 style="    font-size: 1rem;
              margin-bottom: 0px;">Escogé tu forma de pago</h3>
            </div>
            <div #paypal>

            </div>
          </div>

        </div>
      </aside>
    </div>
  </div>

</section>


<section class="graycolor">
  <div class="container pt-5 pb-5" >
    <h2 class="poppins hugetext text-center pb-4">Quizás te interese</h2>
    <div class="row">
      <div class="col-6 col-md-3 col-lg-3 text-center mb-5 mt-0 mt-md-3"
           *ngFor="let slide of news_productos; let i=index">
        <img  [src]="over[i] ? url+'productos_img_data/resources/thumbnails/'+slide.hoverPoster:  url+'productos_img_data/resources/thumbnails/'+slide.poster"
              [routerLink]="['/producto/',slide.slug]"
              (mouseover)="over[i] = true" (mouseout)="over[i] = false"  style="cursor: pointer;">
        <h4 class="poppinsbold regulartext textblack mt-2" style="margin-bottom: 1%"><a class="poppins regulartext textblack"
                                                                                        style="color: black;"
                                                                                        [routerLink]="['/producto/',slide.slug]">{{slide.titulo.substr(0, 68)}}</a>
        </h4>
        <p class="roboto bigtext textblack mt-2 mt-md-2 mt-lg-2 mt-xl-2 mb-2 mb-md-2 mb-lg-2 mb-xl-2"
           style="margin-bottom: 2%">$ {{slide.precio_ahora}}</p>
        <a [routerLink]="['/producto/',slide.slug]">
          <button type="button" class="btn btn-dark btn-sm" style="">Ver producto
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </button>
        </a>
      </div>
    </div>
  </div>

</section>


<app-foot></app-foot>
