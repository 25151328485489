<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-promos.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          NUESTRAS PROMOCIONES</h1>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container mb-5">
    <div class="row">
      <div class="col-12 mb-5">
        <h2
          [ngClass]="{'bigger-t mt-5 mb-3 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ENCUENTRA LA PROMO <span
            [ngClass]="{'huge-t p-1': true, 'blue': !global.color_filter, 'black': global.color_filter}">PARA TI</span>
        </h2>
      </div>

      <div class="col-12 col-md-4 mb-4" *ngFor="let item of promos">
        <div class="card border-0 rounded-0">
          <a data-toggle="modal" [attr.data-target]="'#modal-'+item._id" style="cursor: pointer;"> <img
              [src]="url+'promo_img_data/resources/thumbnails/'+item.poster" class="card-img-top" alt="..."></a>

          <div class="card-body">
            <h5
              [ngClass]="{'mb-0 mt-1': true, 'bold-f': !global.dislex, 'dislex': global.dislex, 'big-t': !global.sizetext, 'sizetext': global.sizetext}">
              {{item.titulo}}</h5>
            <p
              [ngClass]="{'mb-0 mt-2': true, 'dislex': global.dislex, 'text-small': !global.sizetext, 'sizetext': global.sizetext}">
              {{item.detalle}}</p>
            <!-- <p
              [ngClass]="{'mb-0 mt-2': true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Validez: {{item.validez}}</p> -->

          </div>
        </div>


        <div class="modal fade" [attr.id]="'modal-'+item._id" tabindex="-1" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <img [src]="url+'promo_img_data/resources/thumbnails/'+item.poster" class="card-img-top"
                  alt="...">
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>






<section class="none-phone">
  <div
    [ngClass]="{'container corners-rounded mb-5 mt-5': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-2">
        <img class="mt-4 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">
      </div>

      <div class="col-12 col-md-8 col-lg-4">
        <h3
          [ngClass]="{'big-t mt-4 mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>
        <div class="d-flex justify-content-start mb-3">
          <a class="text-decoration-none me-4" style="cursor: pointer;"
            href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-instagram me-2 mb-1" viewBox="0 0 16 16">
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
              </svg>
              clínicas_asembis
            </h6>
          </a>

          <a class="text-decoration-none" style="cursor: pointer;" href="https://www.facebook.com/Asembis/"
            target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-facebook me-2 mb-1" viewBox="0 0 16 16">
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
              Clínicas Asembis
            </h6>
          </a>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
                TELÉFONOS
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Llamadas: <strong>+506 2285 5881</strong></p>
              <p
                [ngClass]="{'ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Whatsapp: <strong>+506 8814 5881</strong></p>
            </div>
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                  class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
                  <path
                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                </svg>
                CORREOS
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                contacto@asembis.org</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="only-phone">
  <div class="container mb-5 mt-5">
    <div class="row d-flex justify-content-center">

      <div
        [ngClass]="{'col-9 corners-rounded': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
        <img class="mt-5 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">

        <h3
          [ngClass]="{'big-t mt-4 text-center mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>

        <div class="d-flex justify-content-center mb-5 mt-5">
          <a [ngClass]="{'text-decoration-none me-4': true, 'white': !global.color_filter, 'black': global.color_filter}"
            style="cursor: pointer;" href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
              class="bi bi-instagram me-3" viewBox="0 0 16 16">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
            </svg>
          </a>

          <a [ngClass]="{'text-decoration-none': true, 'white': !global.color_filter, 'black': global.color_filter}"
            style="cursor: pointer;" href="https://www.facebook.com/Asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
              class="bi bi-facebook ms-3" viewBox="0 0 16 16">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
            </svg>
          </a>
        </div>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
            class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
          </svg>
          TELÉFONOS
        </h5>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Llamadas: +506 2214 5881</p>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Whatsapp: +506 8114 5881</p>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
            class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
            <path
              d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
          </svg>
          CORREOS
        </h5>
        <p
          [ngClass]="{'text-center mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          contacto@asembis.org</p>


      </div>

    </div>
  </div>
</section>

<app-foot></app-foot>