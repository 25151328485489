<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-planes.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESPECIALIDADES MÉDICAS</h1>
      </div>
    </div>
  </div>
</section>


<section class="none-phone">
  <div class="container-fluid">
    <div class="row d-flex justify-content-center">

      <div class="col-12 col-md-4 col-lg-3">
        <div
          [ngClass]="{'card card-data mb-4 mt-5 border-0 corners-rounded bg-gray': true}"
          style="max-width: 90%; margin-left: 5%;">
          <div class="row g-0">
            <div class="col-md-4">
              <img src="assets/img/icons/esp-1.png" class="img-fluid rounded-start mt-4 mb-2"
                   style="width: 60%; margin-left: 15%;">
            </div>
            <div class="col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'big-t mt-3 mb-0': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    +30 años</h5>
                </div>
                <p
                  [ngClass]="{'p-1': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Sirviendóle al pueblo de Costa Rica</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 col-lg-3">
        <div
          [ngClass]="{'card card-data mb-4 mt-5 border-0 corners-rounded bg-gray': true}"
          style="max-width: 90%; margin-left: 5%;">
          <div class="row g-0">
            <div class="col-md-4">
              <img src="assets/img/icons/esp-2.png" class="img-fluid rounded-start mt-4 mb-2"
                   style="width: 60%; margin-left: 15%;">
            </div>
            <div class="col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'big-t mt-3 mb-0': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    +30.000</h5>
                </div>
                <p
                  [ngClass]="{'p-1': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Pacientes atendidos mensualmente</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 col-lg-3">
        <div
          [ngClass]="{'card card-data mb-4 mt-5 border-0 corners-rounded bg-gray': true}"
          style="max-width: 90%; margin-left: 5%;">
          <div class="row g-0">
            <div class="col-md-4">
              <img src="assets/img/icons/esp-3.png" class="img-fluid rounded-start mt-4 mb-2"
                   style="width: 60%; margin-left: 15%;">
            </div>
            <div class="col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'big-t mt-3 mb-0': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    Calidad</h5>
                </div>
                <p
                  [ngClass]="{'p-1': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  En todos nuestros servicios médicos</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="only-phone">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-4">
        <div
          [ngClass]="{'card card-data mb-4 mt-5 border-0 corners-rounded bg-gray': true}"
          style="max-width: 90%; margin-left: 5%;">
          <div class="row g-0">
            <div class="col-3 col-md-4">
              <img src="assets/img/icons/esp-1.png" class="img-fluid rounded-start data-box-icon mt-4 mb-3">
            </div>
            <div class="col-9 col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'huge-t mt-3 mb-0': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    +30 años</h5>
                </div>
                <p
                  [ngClass]="{'p-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Sirviendóle al pueblo de Costa Rica.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div
          [ngClass]="{'card card-data mb-3 mb-md-4 mt-2 mb-2 border-0 corners-rounded bg-gray': true}"
          style="max-width: 90%; margin-left: 5%;">
          <div class="row g-0">
            <div class="col-3 col-md-4">
              <img src="assets/img/icons/esp-2.png" class="img-fluid rounded-start data-box-icon mt-4 mb-3">
            </div>
            <div class="col-9 col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'huge-t mt-3 mb-0': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    +30.000</h5>
                </div>
                <p
                  [ngClass]="{'p-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Pacientes atendidos mensualmente</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4" #target>
        <div
          [ngClass]="{'card card-data mt-2 mb-2 border-0 corners-rounded bg-gray': true}"
          style="max-width: 90%; margin-left: 5%;">
          <div class="row g-0">
            <div class="col-3 col-md-4">
              <img src="assets/img/icons/esp-3.png" class="img-fluid rounded-start data-box-icon mt-4 mb-3">
            </div>
            <div class="col-9 col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5 class=""
                      [ngClass]="{'text-big mt-3 mb-0': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    Calidad asegurada</h5>
                </div>
                <p
                  [ngClass]="{'p-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Pacientes atendidos mensualmente.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>


<!--<section class="mb-5" >

  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 [ngClass]="{'bigger-t mt-5 mb-4 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">SELECCIONE UN ÁREA MÉDICA</h2>
      </div>
    </div>
  </div>

  <div class="container" >
    <div class="row">
      <div class="col-12 col-md-4" *ngFor="let item of areacats; let i = index">
        <div [ngClass]="{'card card-data m-2 corners-rounded border-0': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}">
          <div class="card-body text-center ">
            <img src="assets/img/icons/icon-medicalarea.png" style="width: 15%;">
            <h5 [ngClass]="{'big-t mt-2 mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex}">
              {{item.name}}</h5>
            <a
              [ngClass]="{'btn btn-block': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}" (click)="populate_especialidades_toshow (item._id, item.name, target)">Ver
              especialidades
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>-->


<section class="mb-5 mt-3">


  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2
          [ngClass]="{'bigger-t mt-5 text-center': true, 'blue': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          CONOZCA NUESTRAS ESPECIALIDADES MÉDICAS</h2>
        <p
          [ngClass]="{'mb-3 text-center':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
          Haga clic en la especialidad que quiera leer
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
            <path
              d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
          </svg>
        </p>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-4" *ngFor="let item of especialidades">

        <div class="accordion m-2" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" [attr.id]="'heading' + item._id">
              <button
                [ngClass]="{'accordion-button collapsed': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}"
                style="border: solid #10A4B0 2px" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse' + item._id" aria-expanded="false"
                [attr.aria-controls]="'collapse' + item._id">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     class="bi bi-shield-check yellow me-2" viewBox="0 0 16 16">
                  <path
                    d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                {{item.title}}
              </button>
            </h2>
            <div [attr.id]="'collapse' + item._id" class="accordion-collapse collapse"
                 [attr.aria-labelledby]="'heading' + item._id" data-bs-parent="#accordionExample">

              <div class="accordion-body">
                <p
                  [ngClass]="{'mb-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">{{item.description}}</p>
                <div class="row">
                  <div class="col-12" *ngFor="let item of item.services.split(',')">
                    <h6 [ngClass]="{'mb-3':true, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-check-circle-fill blue" viewBox="0 0 16 16">
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      {{item}}</h6>
                  </div>

                  <!--<div class="d-grid">
                    <a class="" [routerLink]="['/citas']"
                       [ngClass]="{'btn mt-2': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-calendar-check mb-1 me-1" viewBox="0 0 16 16">
                        <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                      </svg>
                      AGENDA TU CITA
                    </a>
                  </div>-->

                  <h4 class="mt-5 turquesa big-t text-center none-phone">Agende su cita al 2285 5881</h4>

                  <a href="tel:+50622855881"
                     [ngClass]="{'btn btn-md-lg mt-md-5 only-phone': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg> AGENDA TU CITA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</section>


<section class="bg-gray">
  <div class="container-fluid no-gutters">
    <div class="row no-gutters">

      <div class="col-12 col-md-6 bg-quality no-gutters"></div>

      <div class="col-12 col-md-6 no-gutters">
        <div class="card bg-transparent border-0" style="width: 90%;margin-left: 5%">
          <div class="card-body">
            <h2
              [ngClass]="{'bigger-t title-line-height mt-5 mb-3 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}">
              CALIDAD HUMANA Y TÉCNICA</h2>
            <p
              [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              El trato personalizado, agradable y profesional, hacen una gran diferencia en el servicio que le
              brindamos.<br>
              Nos enfocamos mucho en que nuestra calidad humana y técnica esté siempre presente en cada detalle de la
              atención brindada.<br>
              Esta calidad humana estará presente ya sea en sus consultas telefónicas o en las visita que usted realice
              en cada una de nuestras clínicas.
            </p>
            <h4
              [ngClass]="{'text-big mt-4 mb-5 text-center line-height-phone': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              <span [ngClass]="{'p-2': true, 'bg-yellow': !global.color_filter}">INVERTIMOS EN LO MEJOR</span></h4>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<app-foot></app-foot>
