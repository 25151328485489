import { Component, OnInit, DoCheck } from '@angular/core';
import { Article } from "../../../../models/Article";
import { ArticleService } from "../../../../services/article.service";
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';

interface HtmlInputEvent extends Event{
  target : HTMLInputElement & EventTarget;
}

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-create-article',
  templateUrl: './create-article.component.html',
  styleUrls: ['./create-article.component.css']
})
export class CreateArticleComponent implements OnInit,DoCheck {

  public file :File;
  public imgSelect : { banner: String | ArrayBuffer , mobile_banner: String | ArrayBuffer} = {banner: '', mobile_banner: ''};
  public article = new Article('','','','','');
  public msm_error;
  public msm_success;
  public identity;
  public config;

  constructor(
    private _articleService : ArticleService,
    private _userService: UserService,
    private _router : Router,
    private _route :ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.identity = this._userService.getIdentity();

  }


  ngOnInit(): void {

        this.config = {
          height: 800,
          menubar: 'file edit view insert format tools table help',
          language : 'es',
          skin: 'material-classic',
          plugins: [
            'print preview fullpage paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons spellchecker mediaembed pageembed linkchecker powerpaste formatpainter casechange'
          ],
          toolbar:
            'casechange undo redo  bold italic underline strikethrough  fontselect fontsizeselect formatselect alignleft aligncenter alignright alignjustify outdent indent numlist bullist  forecolor backcolor removeformat pagebreak charmap emoticons fullscreen preview save print insertfile image media template link anchor codesample fullpage ltr rtl styleselect pageembed formatpainter',

        };
  }

  ngDoCheck(): void {

  }

  imgSelected(event: HtmlInputEvent, fieldName){
    if(event.target.files  && event.target.files[0]){
      const reader = new FileReader();
      reader.onload = e => this.imgSelect[fieldName] = reader.result;
      reader.readAsDataURL(<File>event.target.files[0]);
      this.article[fieldName]  = <File>event.target.files[0];
    }

  }

  close_alert(){
    this.msm_error = '';
    this.msm_success = '';
  }

  onSubmit(articleForm){

    if(articleForm.valid){
      let data = {
        nombre : articleForm.value.nombre,
        banner : this.article['banner'] ,
        mobile_banner : this.article['mobile_banner'] ,
         body: articleForm.value.body
      }

      console.log(data);


      this._articleService.registro(data).subscribe(
        response =>{
          console.log(response);
          this.article = new Article('','','','','');
          this.imgSelect = {banner: '', mobile_banner: ''};
          this.msm_success = 'La categoria se registró con exito.'
          this._router.navigate(['admin/articles']);
        },
        error=>{
          this.msm_error = error.error.message;
        }
      );

    }else{
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }
  }
  
}
