<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-citas.png">
        <h1
          [ngClass]="{'bigger-t mt-2 mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          SOLICITE SU CITA MÉDICA</h1>
      </div>
    </div>
  </div>
</section>


<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <h5 class="roboto fs-5 mb-3" *ngIf="userMail">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
               class="bi bi-person-circle mb-1 me-1" style="color: #6610f2;" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            <path fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
          </svg>
          Hola, {{userMail}}</h5>

        <mat-stepper class="mb-5 bg-gray corners-rounded" style="padding: 0px 0px 0px 0px;">
          <mat-step>

            <ng-template matStepLabel>Especialidad</ng-template>

            <div class="container-fluid g-0 g-md-4">

              <div class="row g-0 g-md-4 d-flex justify-content-center">


                <div class="col-12 g-0 g-md-4 text-center">
                  <h3 *ngIf="btnStart"
                      [ngClass]="{'big-t title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                    CLICK PARA SOLICITAR SU CITA
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                         class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
                      <path
                        d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
                    </svg>
                  </h3>
                  <button *ngIf="btnClick" type="button"
                          [ngClass]="{'btn mb-5 btn-lg': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}"
                          (click)="listarServicios()">
                    SOLICITAR UNA CITA
                  </button>
                  <button class="btn btn-warning btn-lg" type="button" disabled *ngIf="btnSpinner && !errorStep1">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    CARGANDO INFORMACIÓN...
                  </button>
                  <p *ngIf="errorStep1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-exclamation-triangle-fill" style="color: red;" viewBox="0 0 16 16">
                      <path
                        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    Error: {{errorStep1}}</p>


                  <h3 *ngIf="!btnStart"
                      [ngClass]="{'big-t title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                    SELECCIONE LA ESPECIALIDAD, EL SERVICIO Y LA FECHA

                  </h3>
                </div>

                <div class="col-12 col-md-8 col-lg-7 g-0 g-md-4">
                  <label *ngIf="!btnStart" class="form-label"><span class="badge rounded-pill bg-primary">1</span>
                    Seleccione la especialidad</label>
                  <select *ngIf="!btnStart" class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                          [(ngModel)]="selectedEspecialidadServicios"
                          name="selectEspecialidad" (change)="resetStep2()">
                    <option *ngFor="let s of especialidades | keyvalue" [ngValue]="s.value">{{s.key}}</option>
                  </select>

                </div>


                <div class="col-12 col-md-6 g-0 g-md-4">
                  <div class="row">

                    <label *ngIf="selectedEspecialidadServicios.length > 0" class="form-label mt-4"><span
                      class="badge rounded-pill bg-primary">2</span>
                      Seleccione el servicio</label>
                    <select *ngIf="selectedEspecialidadServicios.length > 0" class="form-select form-select-lg mb-3"
                            aria-label=".form-select-lg example"
                            [(ngModel)]="selectedServicio" name="selectServicios" (change)="resetStep2()">
                      <option *ngFor="let s of selectedEspecialidadServicios" [ngValue]="s">{{s.value.nombre}}</option>
                    </select>

                    <p [ngClass]="{'p-0 mt-4': true,'dislex': global.dislex, 'sizetext': global.sizetext}"
                       *ngIf="selectedServicio">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                          d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                      </svg>
                      Observaciones: {{selectedServicio?.value.observaciones}}</p>
                    <p *ngIf="selectedServicio">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                          d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                      </svg>
                      Preparación: {{selectedServicio?.value.preparacion}}</p>
                    <p *ngIf="errorAgenda">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-exclamation-triangle-fill" style="color: red;" viewBox="0 0 16 16">
                        <path
                          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                      </svg>
                      Error: {{errorAgenda}}</p>

                  </div>

                </div>


                <div class="col-12 col-md-6 g-0 g-md-4">
                  <div class="row">

                    <div class="">
                      <label *ngIf="selectedServicio" class="form-label mt-4"><span
                        class="badge rounded-pill bg-primary">3</span> Seleccione la fecha</label>
                      <input *ngIf="selectedServicio" type="text" class="form-control form-control-lg"
                             placeholder="Username"
                             aria-label="Username" aria-describedby="basic-addon1" [(ngModel)]="dateSelected"
                             name="selectDate" type="date" [min]="tomorrow" (change)="resetStep2()">

                      <div class="d-flex justify-content-end">

                        <button *ngIf="dateSelected" class="btn btn-primary btn-lg mt-5" (click)="listarAgendas()"
                                type="button" mat-button
                                matStepperNext>
                          Ver
                          lugares disponibles
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                               class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                            <path
                              d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                          </svg>
                        </button>

                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </div>

            <div class="text-center">
              <p class="text-muted text-small mt-5">This site is protected by reCAPTCHA Enterprise and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            </div>

          </mat-step>


          <mat-step>

            <ng-template matStepLabel>Sede</ng-template>


            <button type="button" class="btn btn-warning btn-lg mt-2 mb-4 ms-2" matStepperPrevious (click)="returnStep1()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-arrow-left-circle-fill me-2" viewBox="0 0 16 16">
                <path
                  d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
              </svg>
              Ir atrás
            </button>

            <div class="container-fluid g-0 g-md-4">
              <div class="row g-0 g-md-4">

                <div class="col-12 col-md-12 col-lg-6 g-0 g-md-4 border-end">

                  <div class="row p-3">

                    <h3
                      [ngClass]="{'big-t title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                      SELECCIONE SU SEDE Y HORARIO:</h3>

                    <div class="col-12 col-md-6 ">
                      <div class="d-flex align-items-center" *ngIf="spinner2">
                        <div class="spinner-grow me-3" style="width: 1.5rem; height: 1.5rem;" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <strong>Cargando datos...</strong>
                      </div>
                      <label *ngIf="lugarSelected" class="form-label mt-4"><span
                        class="badge rounded-pill bg-success">1</span> Seleccione la sede de ASEMBIS</label>
                      <select *ngIf="lugarSelected" class="form-select mb-3" aria-label=".form-select-lg example"
                              [(ngModel)]="placeSelected" (change)="resetCitasHorarios()" name="selectPlace">
                        <option *ngFor="let s of places | keyvalue" [ngValue]="s">{{s.key.split('-')[0]}}</option>
                        <option *ngIf="!places" selected disabled>No disponible</option>
                      </select>

                      <label *ngIf="placeSelected != undefined" class="form-label mt-4"><span
                        class="badge rounded-pill bg-success">2</span> Seleccione el especialista</label>
                      <select *ngIf="placeSelected != undefined" [(ngModel)]="agendaSelected" (change)="getCitas()"
                              class="form-select mb-3"
                              aria-label=".form-select-lg example" name="selectAgenda">
                        <option *ngFor="let s of placeSelected?.value | keyvalue" [ngValue]="s">Código de agenda
                          ({{s.key}})
                        </option>
                      </select>

                      <p *ngIf="agendaSelected">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-info-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                        Observaciones: {{agendaSelected?.value.observaciones}}</p>

                      <p *ngIf="agendaSelected">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-check-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path
                            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                        </svg>
                        Preparación: {{agendaSelected?.value.preparacion}}</p>

                    </div>

                    <div class="col-12 col-md-6">
                      <div class="d-flex align-items-center" *ngIf="horario">
                        <div class="spinner-grow me-3" style="width: 1.5rem; height: 1.5rem;" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <strong>Cargando horarios...</strong>
                      </div>

                      <p *ngIf="emptyHorarios">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-exclamation-triangle-fill" style="color: red;" viewBox="0 0 16 16">
                          <path
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                        No hay horarios disponibles, seleccione otra agenda.
                      </p>

                      <p *ngIf="errorAgenda">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-exclamation-triangle-fill" style="color: red;" viewBox="0 0 16 16">
                          <path
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                        Error: {{errorAgenda}}</p>

                      <label *ngIf="citas && citas.length != 0" class="form-label mt-4"><span
                        class="badge rounded-pill bg-success">3</span>
                        Seleccione su horario</label>

                      <select *ngIf="citas && citas.length != 0" class="form-select mb-3"
                              aria-label=".form-select-lg example"
                              [(ngModel)]="citaSelected" (change)="debug()" name="selectPlace">
                        <option *ngFor="let s of citas | keyvalue" [ngValue]="s">{{s.key}}</option>
                        <option *ngIf="citas.length == 0" selected disabled>No disponible</option>
                      </select>

                      <p *ngIf="agendaSelected && !emptyHorarios" class="mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-clock-history" viewBox="0 0 16 16">
                          <path
                            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                          <path
                            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                        Duración de la consulta: {{agendaSelected?.value.tiempo_consulta}} min
                      </p>


                    </div>

                  </div>

                </div>


                <div class="col-12 col-md-12 col-lg-6 g-0 g-md-4">

                  <div class="row p-3">

                    <h3 *ngIf="citaSelected"
                        [ngClass]="{'big-t title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                      INGRESAR DATOS DEL PACIENTE:</h3>

                    <div class="col-12 col-md-12">
                      <div class="row d-flex justify-content-center">

                        <div class="col-12 col-md-6">
                          <label *ngIf="citaSelected" class="form-label mt-3"><span
                            class="badge rounded-pill bg-info text-dark">1</span>
                            Cédula de identidad </label>
                          <input *ngIf="citaSelected" class="form-control" aria-label="default input example"
                                 [(ngModel)]="cedulaPaciente" (change)="completarCitaForm()"
                                 type="text" (input)= "validateChar($event)" name="fcedula" autocomplete="off" #cedula placeholder="Números o letras">
                        </div>

                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">2</span>
                        Nombre <span class="text-small" style="color: red">*</span></label>
                      <input *ngIf="citaSelected && showcompleteForm" class="form-control" aria-label="default input example"
                             [(ngModel)]="nombrePaciente" type="text" name="fname" placeholder="">
                    </div>
                    <div class="d-flex align-items-center" *ngIf="obteniendoDatosPaciente && !(citaSelected && showcompleteForm)">
                      <div class="spinner-grow me-3" style="width: 1.5rem; height: 1.5rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <strong>Cargando datos del paciente...</strong>
                    </div>
                    <div class="col-12 col-md-6">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">3</span>
                        Primer apellido <span class="text-small" style="color: red">*</span></label>
                      <input *ngIf="citaSelected && showcompleteForm" class="form-control" aria-label="default input example"
                             [(ngModel)]="primerApellidoPaciente" type="text" name="fname" placeholder="">
                    </div>
                    <div class="col-12 col-md-6">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">4</span>
                        Segundo apellido </label>
                      <input *ngIf="citaSelected && showcompleteForm" class="form-control" aria-label="default input example"
                             [(ngModel)]="segundoApellidoPaciente" type="text" name="fname" placeholder="">
                    </div>

                    <div class="col-12 col-md-6">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">5</span>
                        Correo electrónico <span class="text-small" style="color: red">*</span></label>
                      <input *ngIf="citaSelected && showcompleteForm" class="form-control"
                             aria-label="default input example" [(ngModel)]="correoPaciente"
                             type="text" name="fcorreo" placeholder="">
                    </div>
                    <div class="col-12 col-md-6">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">6</span>
                        Número de teléfono </label>
                      <input *ngIf="citaSelected && showcompleteForm" class="form-control"
                             aria-label="default input example" [(ngModel)]="telefonoPaciente"
                             type="text" name="ftelefono" placeholder=""></div>


                    <div class="col-12 col-md-6">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">7</span>
                        Seleccione género </label>
                      <select *ngIf="citaSelected && showcompleteForm" [(ngModel)]="sexoPaciente" name="fsexo" class="form-select mb-3"
                              aria-label=".form-select-lg example">
                        <option value="1">Mujer</option>
                        <option value="2">Hombre</option>
                      </select>
                    </div>
                    <div class="col-12 col-md-6" *ngIf="userMail">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">8</span>
                        Número de expediente <span class="text-small" style="color: red">*</span></label>
                      <input *ngIf="citaSelected && showcompleteForm" class="form-control"
                             aria-label="default input example" [(ngModel)]="expediente"
                             type="text" name="fexpediente" placeholder=""></div>
                    <div class="col-12 col-md-6" *ngIf="userMail">
                      <label *ngIf="citaSelected && showcompleteForm" class="form-label mt-3"><span
                        class="badge rounded-pill bg-info text-dark">9</span>
                        Fecha de nacimiento <span class="text-small" style="color: red">*</span></label>
                      <input *ngIf="citaSelected && showcompleteForm" class="form-control"
                             aria-label="default input example" [(ngModel)]="fechaNacimiento"
                             type="date" [max]="tomorrow"  name="ffechaNacimiento"></div>

                    <div class="col-12 col-md-6 d-flex justify-content-end">
                      <div>
                        <button
                          *ngIf="nombrePaciente && cedulaPaciente && correoPaciente && primerApellidoPaciente"
                          (click)="agendarCita()" type="button"
                          class="btn btn-success btn-lg mt-4 mt-md-5" mat-button
                          matStepperNext>AGENDAR CITA
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path
                              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                          </svg>
                        </button>
                      </div>
                    </div>


                  </div>

                </div>

              </div>
            </div>
            <div class="text-center">
              <p class="text-muted text-small mt-5">This site is protected by reCAPTCHA Enterprise and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            </div>
          </mat-step>


          <mat-step>

            <ng-template matStepLabel>Confirmación</ng-template>


            <div class="container-fluid">

              <div class="row">

                <div class="col-12" *ngIf="spinner">
                  <div class="d-flex justify-content-center mt-5">
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 ">
                  <div class="mt-5 text-center">
                    <svg *ngIf="confirmacionCita" xmlns="http://www.w3.org/2000/svg" width="65" height="65"
                         fill="currentColor" class="bi bi-check-circle turquesa" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                    </svg>
                    <h3 *ngIf="confirmacionCita"
                        [ngClass]="{'bigger-t title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                      CITA AGENDADA</h3>
                    <p *ngIf="confirmacionCita"
                       [ngClass]="{'text-big title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                      Número de cita: {{confirmacionCita}}</p>
                  </div>

                  <div class="mt-5 text-center">
                    <svg *ngIf="errorCita" xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor"
                         class="bi bi-x-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    <h3 *ngIf="errorCita"
                        [ngClass]="{'text-big title-line-height mt-3 mb-3': true, 'dislex': global.dislex}">
                      {{errorCita}}</h3>
                  </div>

                  <div class="col-12 text-center">
                    <button *ngIf="confirmacionCita" (click)="volver()" type="button"
                            [ngClass]="{'btn m-2': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                      Volver a página de inicio
                    </button>
                  </div>
                  <div class="col-12 text-center">
                    <button *ngIf="errorCita" type="button" matStepperPrevious (click)="back()"
                            [ngClass]="{'btn m-2': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                      Atrás
                    </button>
                  </div>

                </div>

              </div>


            </div>
            <div class="text-center">
              <p class="text-muted text-small mt-5">This site is protected by reCAPTCHA Enterprise and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
            </div>
          </mat-step>

        </mat-stepper>
      </div>
    </div>
  </div>
</section>

<section class="none-phone">
  <div
    [ngClass]="{'container corners-rounded mb-5 mt-5': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-2">
        <img class="mt-4 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">
      </div>

      <div class="col-12 col-md-8 col-lg-4">
        <h3
          [ngClass]="{'big-t mt-4 mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>
        <div class="d-flex justify-content-start mb-3">
          <a class="text-decoration-none me-4" style="cursor: pointer;"
             href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-small': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-instagram me-1 mb-1" viewBox="0 0 16 16">
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
              </svg>
              clínicas_asembis
            </h6>
          </a>

          <a class="text-decoration-none" style="cursor: pointer;" href="https://www.facebook.com/Asembis/"
             target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-small': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-facebook me-1 mb-1"
                   viewBox="0 0 16 16">
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg>
              Clínicas Asembis
            </h6>
          </a>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                TELÉFONOS
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Llamadas: +506 2285 5881</p>
              <p
                [ngClass]="{'ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Whatsapp: +506 8814 5881</p>
            </div>
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
                  <path
                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                </svg>
                CORREO
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                contacto@asembis.org</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="only-phone">
  <div class="container mb-5 mt-5">
    <div class="row d-flex justify-content-center">

      <div
        [ngClass]="{'col-9 corners-rounded': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
        <img class="mt-5 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">

        <h3
          [ngClass]="{'big-t mt-4 text-center mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>

        <div class="d-flex justify-content-center mb-5 mt-5">
          <a
            [ngClass]="{'text-decoration-none me-4': true, 'white': !global.color_filter, 'black': global.color_filter}"
            style="cursor: pointer;" href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 class="bi bi-instagram me-3" viewBox="0 0 16 16">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
          </a>

          <a [ngClass]="{'text-decoration-none': true, 'white': !global.color_filter, 'black': global.color_filter}"
             style="cursor: pointer;" href="https://www.facebook.com/Asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 class="bi bi-facebook ms-3"
                 viewBox="0 0 16 16">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
          </a>
        </div>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>
          TELÉFONOS
        </h5>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Llamadas: +506 2285 5881</p>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Whatsapp: +506 8814 5881</p>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
            <path
              d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
          </svg>
          CORREOS
        </h5>
        <p
          [ngClass]="{'text-center mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          contacto@asembis.org</p>


      </div>

    </div>
  </div>
</section>


<app-foot></app-foot>
