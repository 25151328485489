import { Component, OnInit } from '@angular/core';
import {GLOBAL} from "../../services/GLOBAL";

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.css']
})
export class PlanesComponent implements OnInit {

  public global = GLOBAL;

  constructor() { }

  ngOnInit(): void {
  }

}
