import {Component, OnInit} from '@angular/core';
import {GLOBAL} from 'src/app/services/GLOBAL';
import {PromoService} from '../../../services/promo.service';
import {Promo} from '../../../models/Promo';

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-promocion',
  templateUrl: './promocion.component.html',
  styleUrls: ['./promocion.component.css']
})
export class PromocionComponent implements OnInit {

  public promos : Array<any> = [];
  public promo = new Promo('', '', '','','');
  public file: File;
  public imgSelect : {poster: String | ArrayBuffer, hoverPoster: String | ArrayBuffer} = {poster: '', hoverPoster: ''};



  public url;
  public identity;
  public msm_error = false;
  public msm_success = false;
  public promocion: any = {};

  constructor(
    private _promoService : PromoService
  )
  {
    this.url = GLOBAL.url;

  }

  ngOnInit(): void {
    this.initData();
  }


  imgSelected(event: HtmlInputEvent, fieldName){
    if(event.target.files  && event.target.files[0]){
      this.file = <File>event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imgSelect[fieldName]= reader.result;
      reader.readAsDataURL(this.file);
      this.promo[fieldName] = <File>event.target.files[0];
    }

  }

  initData() {
    this._promoService.listar().subscribe(
      response => {
        this.promos = response.promos;
      },
      error => {

      }
    );
  }


  eliminar(id) {
    this._promoService.eliminar(id).subscribe(
      response => {

        $('#modal-'+id).modal('hide');
        $('.modal-backdrop').remove();
        $(document.body).removeClass("modal-open");
        this.initData();

      },
      error => {

      }
    );
  }


  onSubmit(promocionForm) {
    if (promocionForm.valid) {
      let data = {
        titulo: promocionForm.value.titulo,
        poster : this.promo.poster,
        detalle: promocionForm.value.detalle,
        validez: promocionForm.value.validez,
      }

      this._promoService.registro(data).subscribe(
        response => {
          this.promo = new Promo('','','','','')
          this.initData();

          this.msm_error = false;
          this.msm_success = true;
          window.scroll(0, 0);
        },
        error => {
          console.log(error);
          window.scroll(0, 0);
          this.msm_error = true;
          this.msm_success = false;
        }
      )
    } else {

      window.scroll(0, 0);
      this.msm_error = true;
      this.msm_success = false;
    }
  }

  update(promocionForm, _id) {
    if (promocionForm.valid) {
      let data = {
        _id: _id,
        titulo: promocionForm.value.titulo,
        poster : this.promo.poster,
        detalle: promocionForm.value.detalle,
        validez: promocionForm.value.validez,
      }

      this._promoService.update(data).subscribe(
        response => {
          this.promo = new Promo('','','','','');

          $('#Modal').modal('hide');
          $('.modal-backdrop').remove();
          $(document.body).removeClass("modal-open");
          this.initData();

        },
        error => {
          console.log(error);
          window.scroll(0, 0);
          this.msm_error = true;
          this.msm_success = false;
        }
      )
    }
  }




}
