<app-nav></app-nav>

<section>

  <div class="container">
    <div class="row d-flex justify-content-between">

      <div class="col-12 col-md-12 col-lg-9">
        <h1  [ngClass]="{'bigger-t title-line-height mt-5 mb-1': true, 'turquesa': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">{{article?.nombre}}</h1>
        <p class="blockquote-footer mt-2 mb-3">Blog ASEMBIS</p>
        <img class="mt-5" [src]="url+'article/resources/thumbnails/'+article.banner" style="width: 100%;">
        <div id="detalle" class="mb-5 mt-5">
          {{article.body}}
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-3">
        <h1 [ngClass]="{'text-big text-center mt-2 mt-lg-4 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">QUIZÁS TE INTERESE</h1>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-12" *ngFor="let article of randomArticles">
            <div class="card mt-4 mb-4">
              <img [src]="url+'article/resources/thumbnails/'+article.banner" class="card-img-top" alt="...">
              <div class="card-body">
                <img src="assets/img/icons/icon-one-article.png" class="mt-2 mb-3" style="width: 13%;">
                <h5 [ngClass]="{'text-big mt-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" style="line-height: 1;">{{article.nombre}}</h5>
                <p class="blockquote-footer mt-2 mb-3">Blog ASEMBIS</p>
                <div class="d-flex justify-content-center">
                  <div class="col-10 d-grid">
                    <a [routerLink]="['/blog/'+article.slug]" href="/blog/{{article.slug}}"
                       [ngClass]="{'btn btn-block': true, 'cursor': global.cursor_btn,  'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Leer
                      entrada
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>



</section>

<app-foot></app-foot>
