<app-aside></app-aside>


<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>


<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-lg-9 pt-lg-4">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">


              <div class="col-lg-12">
                <div class="border-bottom mt-lg-2 pb-2">
                  <h2 class="mt-lg-4"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-shield-check mb-1" style="color: #6610f2;" viewBox="0 0 16 16">
                    <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                    <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  </svg> Especialidades médicas</h2>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Cree, edite y elimine las especialidades que se mostrarán en su página.</p>
                  </div>
                </div>
              </div>

              <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show"
                   role="alert">
                {{msm_success}}
                <button type="button" class="btn-close btn-close-dark me-2 m-auto" data-dismiss="alert"
                        aria-label="Close"></button>
              </div>

              <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show"
                   role="alert">
                {{msm_error}}
                <button type="button" class="btn-close btn-close-dark me-2 m-auto" data-dismiss="alert"
                        aria-label="Close"></button>
              </div>


              <div class="col-lg-12">
                <form #espedarshboardForm="ngForm" (ngSubmit)="onSubmit(espedarshboardForm)">
                  <div class="card border-0 box-shadow">
                    <div class="card-body">
                      <div class="tab-content" style="margin-top: 20px;">
                        <div class="tab-pane fade active show">
                          <div class="form-group row align-items-center">

                           <!-- <div class="col-12">
                              <h5>Seleccione el área médica a la que pertenece la especialidad <span
                                style="color: red;">*</span></h5>
                              <select class="form-select form-select-lg mb-4" aria-label="Default select example"
                                      name="area"
                                      [(ngModel)]="especialidad.area" id="select-input">
                                <option selected disabled value="">Seleccione área:</option>

                                <option *ngFor="let item of areacats"
                                        [value]="item._id">{{item.name}}</option>
                              </select>
                            </div>-->

                            <div class="col-md-5">

                              <h5 class="smalltext">Título de la especialidad <span style="color: red;">*</span></h5>
                              <input class="form-control" type="text" placeholder="Agregar título" name="title"
                                     maxlength="50" [(ngModel)]="especialidad.title">
                            </div>
                            <div class="col-md-6">
                              <h5 class="smalltext mt-3">Descripción de la especialidad <span
                                style="color: red;">*</span></h5>
                              <textarea class="form-control" id="textarea-input" rows="4"
                                        placeholder="Agrege una descriptción"
                                        name="description" [(ngModel)]="especialidad.description"></textarea>
                            </div>
                            <div class="col-12">
                              <h5 class="smalltext mt-3">Ingrese los nombres de los servicios <span
                                style="color: red;">separados por comas:</span></h5>
                              <textarea class="form-control" id="textarea-input" rows="4"
                                        placeholder="Agrege los servicios"
                                        name="services" [(ngModel)]="especialidad.services"></textarea>
                            </div>
                          </div>


                        </div>
                        <div class="">
                          <button class="btn btn-success mt-2 mb-3 btn-lg mr-1" type="submit">Añadir especialidad</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              <!--<div class="col-12">
                <h4 class="mt-5 mb-3">
                  Haga clic en la especialidad para ver su información
                </h4>
              </div>

              <div class="col-md-3" *ngFor="let item of areacats; let i = index">
                <div class="p-2 d-grid">
                  <a type="button" [ngClass]="{'btn': true,'btn-secondary' : toggle[i], 'btn-primary': !toggle[i]}"
                     (click)="populate_especialidades_toshow (item._id)">{{item.name}}</a>
                </div>

              </div>-->

              <div class="col-12">
                <h4 class="mt-5 mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                       class="bi bi-info-circle-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                    <path
                      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                  </svg>
                  Se enlistaran las especialidades guardadas en la base de datos:
                </h4>
              </div>

             <!-- <div class="col-12 col-md-6 mt-3 mb-3" *ngFor="let item of especialidades_show">


                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [attr.id]="'heading' + item._id">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + item._id" aria-expanded="false" [attr.aria-controls]="'collapse' + item._id">
                       <h6>{{item.title}}</h6>
                      </button>
                    </h2>
                    <div [attr.id]="'collapse' + item._id" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + item._id" data-bs-parent="#accordionExample">

                      <div class="accordion-body">
                        <p>{{item.description}}</p>

                        <div class="col-12  d-flex justify-content-center">
                          <a data-toggle="modal" [attr.data-target]="'#editespecialidad-'+item._id"
                             class="btn btn-primary mt-3 mb-3 me-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                              <path fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                            Editar información
                          </a>

                          <a data-toggle="modal" [attr.data-target]="'#delete-'+item._id"
                             class="btn btn-dark btn-icon mt-3 mb-3 ms-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-trash" viewBox="0 0 16 16">
                              <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                              <path fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                            Eliminar especialidaad
                          </a>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>


                <div class="modal fade" [attr.id]="'delete-'+item._id" tabindex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <p class="font-size-sm">¿Desea eliminar definitivamente esta especialidad?</p>
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar
                        </button>
                        <button class="btn btn-primary btn-shadow btn-sm" type="button"
                                (click)="eliminar(item._id)">
                          Suprimir
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade" [attr.id]="'editespecialidad-'+item._id" tabindex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel2">Editar Especialidad</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">

                        <form #espedarshboardForm="ngForm" (ngSubmit)="update(espedarshboardForm,item._id)">
                          <div class="card border-0 box-shadow">
                            <div class="card-body">
                              <div class="tab-content" style="margin-top: 20px;">
                                <div class="tab-pane fade active show">
                                  <div class="form-group row align-items-center">


                                    <div class="col-md-6">
                                      <h5 class="smalltext">Nombre de la especialidad</h5>
                                      <input class="form-control" type="text" placeholder="Agregar nombre"
                                             #name="ngModel" name="title"
                                             maxlength="50" [(ngModel)]="item.title">
                                    </div>
                                    <div class="col-md-6">
                                      <h5 class="smalltext mt-3">Descripción de la especialidad</h5>
                                      <textarea class="form-control" id="textarea-input" rows="4"
                                                placeholder="Agregar ubicación escrita"
                                                name="description" [(ngModel)]="item.description"></textarea>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="d-flex justify-content-start">
                              <button class="btn btn-primary mb-2 ms-3" type="submit">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-check2-circle" viewBox="0 0 16 16">
                                  <path
                                    d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                  <path
                                    d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                </svg>
                                Actualizar
                              </button>
                              <button class="btn btn-secondary mb-2 ms-2" type="button" data-dismiss="modal">
                                Cancelar
                              </button>
                            </div>

                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">

                      </div>
                    </div>
                  </div>
                </div>

              </div>-->

              <div class="col-12 col-md-6 mt-3 mb-3" *ngFor="let item of especialidades">

                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [attr.id]="'heading' + item._id">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + item._id" aria-expanded="false" [attr.aria-controls]="'collapse' + item._id">
                        <h6>{{item.title}}</h6>
                      </button>
                    </h2>
                    <div [attr.id]="'collapse' + item._id" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + item._id" data-bs-parent="#accordionExample">

                      <div class="accordion-body">
                        <p>{{item.description}}</p>
                        <div class="row">
                          <div class="col-12 col-md-6" *ngFor="let item of item.services.split(',')">
                            <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill blue" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg> {{item}}</p>
                          </div>
                        </div>

                        <div class="col-12  d-flex justify-content-center">
                          <a data-toggle="modal" [attr.data-target]="'#editespecialidad-'+item._id"
                             class="btn btn-primary mt-3 mb-3 me-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                              <path fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                            Editar información
                          </a>

                          <a data-toggle="modal" [attr.data-target]="'#delete-'+item._id"
                             class="btn btn-dark btn-icon mt-3 mb-3 ms-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-trash" viewBox="0 0 16 16">
                              <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                              <path fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                            Eliminar especialidad
                          </a>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>


                <div class="modal fade" [attr.id]="'delete-'+item._id" tabindex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <p class="font-size-sm">¿Desea eliminar definitivamente esta especialidad?</p>
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar
                        </button>
                        <button class="btn btn-primary btn-shadow btn-sm" type="button"
                                (click)="eliminar(item._id)">
                          Suprimir
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade" [attr.id]="'editespecialidad-'+item._id" tabindex="-1"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel2">Editar Especialidad</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">

                        <form #espedarshboardForm="ngForm" (ngSubmit)="update(espedarshboardForm,item._id)">
                          <div class="card border-0 box-shadow">
                            <div class="card-body">
                              <div class="tab-content" style="margin-top: 20px;">
                                <div class="tab-pane fade active show">
                                  <div class="form-group row align-items-center">


                                    <div class="col-md-6">
                                      <h5 class="smalltext">Nombre de la especialidad</h5>
                                      <input class="form-control" type="text" placeholder="Agregar nombre"
                                             #name="ngModel" name="title"
                                             maxlength="50" [(ngModel)]="item.title">
                                    </div>
                                    <div class="col-md-6">
                                      <h5 class="smalltext mt-3">Descripción de la especialidad</h5>
                                      <textarea class="form-control" id="textarea-input" rows="4"
                                                placeholder="Agregar ubicación escrita"
                                                name="description" [(ngModel)]="item.description"></textarea>
                                    </div>
                                    <div class="col-12">
                                      <h5 class="smalltext mt-3">Igrese los nombres de los servicios <span
                                        style="color: red;">separados por comas:</span></h5>
                                      <textarea class="form-control" id="textarea-input" rows="4"
                                                placeholder="Agrege los servicios"
                                                name="services" [(ngModel)]="item.services"></textarea>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="d-flex justify-content-start">
                              <button class="btn btn-primary mb-2 ms-3" type="submit">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-check2-circle" viewBox="0 0 16 16">
                                  <path
                                    d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                  <path
                                    d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                </svg>
                                Actualizar
                              </button>
                              <button class="btn btn-secondary mb-2 ms-2" type="button" data-dismiss="modal">
                                Cancelar
                              </button>
                            </div>

                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </section>
      </div>
    </div>
  </div>

</section>
