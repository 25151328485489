<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-sobreasembis.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          CONOZCA ASEMBIS</h1>
      </div>
    </div>
  </div>
</section>

<section class="bg-texture">

  <div class="container">
    <div class="row d-flex justify-content-center">

      <div class="col-12 col-md-12 mt-5">
        <h3
          [ngClass]="{'bigger-t mt-2 text-center center-ipad': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"
          style="line-height: 1.5;">TE CONTAMOS SOBRE <br><span
          [ngClass]="{'huge-t': true, 'blue': !global.color_filter, 'black': global.color_filter}"
          style="line-height: 1;">QUE NOS MUEVE</span>
        </h3>
      </div>

      <div class="col-12 col-md-6 col-lg-5 mt-4">
        <div
          [ngClass]="{'card card-w mt-4 mb-2 mb-md-5 border-0 corners-rounded': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}"
          >
          <div class="card-body">
            <h5
              [ngClass]="{'text-big text-center mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              MISIÓN</h5>
            <span class="decor-mv mb-2"></span>
            <p
              [ngClass]="{'p-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Somos una empresa social que brinda
              servicios de salud a toda la población, con precio justo,
              alta
              tecnología y capital humano comprometido con la mejora continua y la calidad. </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-5 mt-4">
        <div
          [ngClass]="{'card card-w mt-4 mb-2 mb-md-5 border-0 corners-rounded': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}"
          >
          <div class="card-body">
            <h5
              [ngClass]="{'text-big text-center mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              VISIÓN</h5>
            <span class="decor-mv mb-2"></span>
            <p
              [ngClass]="{'p-3': true,'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Replicar a nivel internacional el
              modelo de empresa social <strong>ASEMBIS</strong>, para que
              todas las
              poblaciones, tengan acceso a servicios de salud que permitan mejorar su calidad de vida.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-10 mt-4">
        <div
          [ngClass]="{'card card-w mb-2 mb-md-5 border-0 corners-rounded': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}"
          >
          <div class="card-body">
            <h5
              [ngClass]="{'text-big text-center mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              POLÍTICA DE CALIDAD</h5>
            <span class="decor-mv mb-2"></span>
            <p
              [ngClass]="{'ps-3 pe-3 pt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              La Alta Dirección de Asembis Lab se compromete a
              promover una “Cultura de la Calidad Total”

              , donde
              se exhorta a los colaboradores hacer bien las cosas
              desde la primera vez, bajo el lema “la calidad
              empieza en nosotros, no en las cosas”. Nuestra
              política debe dar cumplimiento a las normas y
              estándares de la calidad, tanto en el ámbito
              nacional como internacional, en caso de que
              aplique.<br><br>
              La intención es que cada colaborador se considere
              “dueño de su propio proceso”, bajo un ambiente de
              confianza, de participación y empoderamiento que
              promuevan la mejora continua de sus individuos,
              procesos y equipos. Nuestro fin último busca con
              espíritu de servicio y pasión, cumplir con las
              necesidades y exceder las expectativas de nuestros
              clientes internos y externos.</p>
            <img class="logo-lab me-3 ms-3 mb-3" src="assets/img/logo-lab.png">
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="container mt-5 mt-md-3">
    <div class="row d-flex justify-content-center">

      <div class="col-11 col-md-12 col-lg-10">
        <div class="card bg-gray border-0 corners-rounded mb-5">
          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <h2
                  [ngClass]="{'bigger-t text-center mt-4 mb-3': true, 'blue': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                  SOMOS EMPRESA DE INTERÉS NACIONAL</h2>
              </div>

              <div class="col-12 col-md-12 col-lg-6">
                <img class="corners-rounded mb-4" src="assets/img/kids.jpg" style="width: 100%;">
              </div>

              <div class="col-12 col-md-12 col-lg-6">
                <p
                  [ngClass]="{'mb-1 ps-3 pe-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Con mucha satisfacción hacemos de su conocimiento que ASEMBIS, fue declarada Empresa de Interés
                  Nacional,
                  por el Sr. Presidente de la Républica y el Ministro de Justicia y Gracia, el 18 de Julio de 1994.
                  Dicha
                  declaratoria consta en el Decreto número 23531-J, publicado en el Diario Oficial La Gaceta, número
                  159,
                  del Martes veintitrés de agosto de 1994.</p>
                <p
                  [ngClass]="{'mb-3 ps-3 pe-3':true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Por ser una empresa dedicada a: Promover el desarrollo de los servicios médicos a comunidades urbanas
                  y
                  suburbanas, para el mejoramiento de la salud en general.</p>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  </div>
</section>

<section class="bg-gray">
  <div class="container-fluid no-gutters">
    <div class="row no-gutters">

      <div class="col-12 col-md-12 col-lg-6 no-gutters">

        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="3"
                    aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="4"
                    aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="5"
                    aria-label="Slide 6"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="6"
                    aria-label="Slide 7"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="7"
                    aria-label="Slide 8"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="8"
                    aria-label="Slide 9"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="9"
                    aria-label="Slide 10"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="10"
                    aria-label="Slide 11"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="11"
                    aria-label="Slide 12"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="12"
                    aria-label="Slide 13"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="13"
                    aria-label="Slide 14"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="14"
                    aria-label="Slide 15"></button>
            <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="15"
                    aria-label="Slide 16"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="assets/img/old-photos/a-1.jpg" class="" style="width: 100%;" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-2.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-3.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-4.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-5.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-6.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-7.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-8.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-9.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-10.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-11.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-12.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-13.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-14.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-15.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="assets/img/old-photos/a-16.jpg" class="d-block w-100" alt="...">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

      </div>


      <div class="col-12 col-md-12 col-lg-6 no-gutters">
        <div class="card border-0 bg-transparent mb-4">
          <div class="card-body" style="width: 100%;">
            <h2
              [ngClass]="{'bigger-t title-line-height mt-3 mt-md-5 mb-2 mb-md-2 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
              FUNDADO EN <span
              [ngClass]="{'huge-t': true,'black': global.color_filter, 'blue': !global.color_filter}">1991</span>
            </h2>
            <p
              [ngClass]="{'p-4 mb-3 mb-md-5':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <strong>ASEMBIS</strong> fue fundada el 09 de Setiembre de 1991 por la señora Rebeca
              Villalobos Vargas, quien inició el proyecto con la módica suma de $300 y el firme deseo de ayudar a
              poblaciones de bajos recursos ofreciéndoles servicios médicos de alta calidad a precios justos,
              especialmente en el área de la salud visual. Fue en Barrio Aranjuez donde se estableció la primera clínica
              ASEMBIS, en este momento somos 20 clínicas alrededor de todo Costa Rica</p>

          </div>
        </div>
      </div>


    </div>
  </div>
</section>

<!--<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 bg-photo-kids">

      </div>

      <div class="col-12 col-md-6">
        <div class="card border-0 box-socialwork">
          <div class="card-body">
            <h2 [ngClass]="{'bigger-t title-line-height mt-5 mb-3 text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">SOLICITE EL APOYO DE <br>TRABAJO
              SOCIAL</h2>
            <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">El Departamento de Trabajo Social realiza un estudio a toda persona que
              no cuente con los medios económicos para el pago de un servicio.
              Nuestro objetivo es que toda persona pueda acceder a nuestros servicios de salud de forma igualitaria.
              Servicios tales como anteojos, consultas médicas, prótesis dentales, cirugías y diferentes especialidades.
              Consulte por Trabajo Social en la sucursal más cercana será un gusto poder apoyarle.</p>
            <p [ngClass]="{'mt-4 text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">Salud al alcance de todos.</p>
            <div class="text-center mb-5">
              <span [ngClass]="{'text-normal p-2 text-center': true, 'white': !global.color_filter, 'black': global.color_filter, 'bg-yellow': !global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">TEL: 2222 2222</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>-->

<!--<section [ngClass]="{'bg-green': !global.color_filter, 'bg-gray': global.color_filter}">

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4 d-flex justify-content-center">
        <div class="card border-0 box-info mt-5 mb-4 mb-md-5">
          <img src="assets/img/icons/icon-sa-1.png" class="icon-box mt-3" alt="...">
          <div class="card-body">
            <h4 [ngClass]="{'text-big text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">Nuestro trabajo social</h4>
            <p [ngClass]="{' ps-3 pe-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 d-flex justify-content-center">
        <div class="card border-0 box-info mt-md-5 mb-4 mb-md-5">
          <img src="assets/img/icons/icon-sa-2.png" class="icon-box mt-3" alt="...">
          <div class="card-body">
            <h4 [ngClass]="{'text-big text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">Equipo médico de primera</h4>
            <p [ngClass]="{'ps-3 pe-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4  d-flex justify-content-center">
        <div class="card border-0 box-info mt-md-5 mb-5 mb-md-5">
          <img src="assets/img/icons/icon-sa-3.png" class="icon-box mt-3" alt="...">
          <div class="card-body">
            <h4 [ngClass]="{'text-big text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">Enfocado al servicio al cliente</h4>
            <p [ngClass]="{'ps-3 pe-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 mb-5">
        <h4 [ngClass]="{'big-t text-center mb-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"><span
          [ngClass]="{'p-2 line-height-phone': true, 'bg-yellow': !global.color_filter}">NUESTROS PROYECTOS DE BIEN SOCIAL</span></h4>

        <div id="carouselExampleControls" class="carousel slide mb-5 mt-5" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5 [ngClass]="{'text-big': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">1. Ver para Aprender</h5>
                      <p [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">Dirigido a escuelas de atención prioritaria su objetivo es apoyar a personas
                        menores de edad en la mejora de su calidad visual.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5 [ngClass]="{'text-big': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">2. Atención a organizaciones de bien social</h5>
                      <p [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">Atención de casos referidos por instituciones que trabajan con población en
                        condición de vulnerabilidad.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5 [ngClass]="{'text-big': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">3. Proyectos comunitarios</h5>
                      <p [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">Dirigidos a la niñez, adolescencia, población adulta mayor en temáticas de
                        salud y prevención.<br><span style="color: transparent;">text</span></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-2 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5 [ngClass]="{'text-big': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">4. Donativos a organizaciones</h5>
                      <p [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">Apoyo a diferentes organizaciones con donativos solicitados para la atención
                        de personas en condición de pobreza y vulnerabilidad.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5 [ngClass]="{'text-big': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">5. Gestión Ambiental</h5>
                      <p [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">Concientización al personal colaborador de ASEMBIS en el adecuado manejo de
                        los residuos y el impacto ambiental.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <button [ngClass]="{'carousel-control-prev': true,'cursor': global.cursor_btn}" type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev">
            <span [ngClass]="{'carousel-control-prev-icon': true,'cursor': global.cursor_btn}" aria-hidden="true"></span>
            <span [ngClass]="{'visually-hidden': true,'cursor': global.cursor_btn}" >Previous</span>
          </button>

          <button [ngClass]="{'carousel-control-next': true,'cursor': global.cursor_btn}" type="button" data-bs-target="#carouselExampleControls"
                  data-bs-slide="next">
            <span [ngClass]="{'carousel-control-next-icon': true,'cursor': global.cursor_btn}" aria-hidden="true"></span>
            <span [ngClass]="{'visually-hidden': true,'cursor': global.cursor_btn}">Next</span>
          </button>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6 mb-5 border-start">
        <h4 class="white bold-f big-t text-center mb-4" [ngClass]="{'big-t text-center mb-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"><span [ngClass]="{'p-2': true, 'bg-yellow': !global.color_filter}">RESULTADO DE PROYECTOS</span></h4>
        <div class="d-flex justify-content-center">
          <ul>
            <li [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">Atención a la salud visual de la niñez</li>
            <li [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">Aporte a la educación de la niñez y adolescencia</li>
            <li [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">Mejora en la calidad de vida de personas</li>
            <li [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">Acceso a servicios médicos y atención de calidad</li>
            <li [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">Prevención y tratamiento en temas de salud</li>
          </ul>
        </div>
        <a href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/" target="_blank"><img
          [ngClass]="{'mb-5 ods-logo': true,'cursor': global.cursor_btn}" src="assets/img/icons/img-objetivos.png"></a>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="card border-0" style="width: 90%;margin-left: 5%">
          <div class="card-body">
            <h2 [ngClass]="{'bigger-t title-line-height mt-5 mb-3 text-center': true, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">NUESTROS OBJETIVOS</h2>
            <p [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">Desde su creación el objetivo principal de ASEMBIS ha sido beneficiar a
              la clase de escasos recursos de Costa Rica, así nace el departamento de trabajo social, a través del cual
              nuestros pacientes se benefician con exoneraciones parciales o totales en los diferentes servicios que
              brindamos. Este servicio se ofrece en todas las sucursales.</p>
            <p [ngClass]="{'mt-2 mb-5': true, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">Solo en los últimos cinco años ASEMBIS ha brindado en ayuda social
              a
              los pacientes, un promedio de ¢257.946.520, contribuyendo al mejoramiento de la salud de nuestra
              población.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6"></div>

    </div>
  </div>
</section>-->


<app-foot></app-foot>

