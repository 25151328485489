<header class="bg-light box-shadow-sm">

<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand fs-6" href="#">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-circle me-2" viewBox="0 0 16 16" style="color: #6610f2;">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
      </svg>
      Hola,<small> Admin ASEMBIS</small></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="text-end">
      <a class="text-muted" style="cursor: pointer; text-decoration: none;" (click)="logout()">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"/>
          <path fill-rule="evenodd" d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
        </svg>
        Cerrar sesión
      </a>
    </div>
  </div>
</nav>

<!--  <div class="navbar navbar-expand-lg navbar-light">
      <div class="container">
          <a *ngIf="general" class="navbar-brand d-none d-sm-block mr-4 order-lg-1" href="https://linkdesign.cr/" target="_blank" style="min-width: 7rem;"><img width="142" [src]="url+'congeneral/resources/thumbnails/'+general.logo" alt="" /></a>
          <a class="navbar-brand d-sm-none mr-2 order-lg-1"  style="min-width: 4.625rem;"><img width="50" src="" alt="" /></a>
          &lt;!&ndash; Toolbar&ndash;&gt;
          <div class="navbar-toolbar d-flex align-items-center order-lg-3">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"><span class="navbar-toggler-icon"></span></button>


              <div class="navbar-tool dropdown ml-2">
                  <a class="navbar-tool-icon-box border dropdown-toggle" ><img width="32" src="../../../assets/img/user.png" alt="Createx Studio" /></a>
                  <a class="navbar-tool-text ml-n1"><small>Hola, </small>Admin AMAG</a>

                  <div class="dropdown-menu dropdown-menu-right" style="min-width: 14rem;">
                      <h6 class="dropdown-header">Cuenta</h6>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item d-flex align-items-center" (click)="logout()" style="cursor: pointer;"><i class="czi-sign-out opacity-60 mr-2"></i>Cerrar sesión</a>
                  </div>
              </div>

          </div>
          <div class="collapse navbar-collapse mr-auto order-lg-2" id="navbarCollapse">
              &lt;!&ndash; Search&ndash;&gt;
              <div class="input-group-overlay d-lg-none my-3">
                  <div class="input-group-prepend-overlay">
                      <span class="input-group-text"><i class="czi-search"></i></span>
                  </div>
                  <input class="form-control prepended-form-control" type="text" placeholder="Search marketplace" />
              </div>
              &lt;!&ndash; Categories dropdown&ndash;&gt;
              <ul class="navbar-nav mega-nav pr-lg-2 mr-lg-2">
                 &lt;!&ndash;  <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle pl-0" href="#" data-toggle="dropdown"><i class="czi-menu align-middle mt-n1 mr-2"></i>Categories</a>
                      <ul class="dropdown-menu py-1">
                          <li class="dropdown">
                              <a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">Photos</a>
                              <ul class="dropdown-menu">
                                  <li class="dropdown-item product-title font-weight-medium">
                                      <a href="#">All Photos<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                                  </li>
                                  <li class="dropdown-divider"></li>
                                  <li><a class="dropdown-item" href="#">Abstract</a></li>
                                  <li><a class="dropdown-item" href="#">Animals</a></li>
                                  <li><a class="dropdown-item" href="#">Architecture</a></li>
                                  <li><a class="dropdown-item" href="#">Beauty &amp; Fashion</a></li>
                                  <li><a class="dropdown-item" href="#">Business</a></li>
                                  <li><a class="dropdown-item" href="#">Education</a></li>
                                  <li><a class="dropdown-item" href="#">Food &amp; Drink</a></li>
                                  <li><a class="dropdown-item" href="#">Holidays</a></li>
                                  <li><a class="dropdown-item" href="#">Industrial</a></li>
                                  <li><a class="dropdown-item" href="#">People</a></li>
                                  <li><a class="dropdown-item" href="#">Sports</a></li>
                                  <li><a class="dropdown-item" href="#">Technology</a></li>
                              </ul>
                          </li>

                      </ul>
                  </li> &ndash;&gt;
              </ul>
              &lt;!&ndash; Primary menu&ndash;&gt;

          </div>
      </div>
  </div>-->

</header>

