<app-aside></app-aside>

<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg corners-rounded">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">

            <div class="col-lg-12">
                <div  class="border-bottom mt-lg-2 pb-2 mb-4">
                    <h1 class="mt-lg-4">Tallas del producto</h1>
                      <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                        <p class="text-muted">Listado de opciones de tallas para el producto.</p>
                        <div class="btn-group" role="group" aria-label="Solid button group">

                          <a [routerLink]="['/admin/productos']" class="btn btn-dark btn-sm" style="    padding-top: 10px;"> <span class="czi-reply"></span> Regresar</a>
                        </div>

                      </div>

                </div>

                <div class="row">
                  <div class="col-lg-8 form-group">
                    <form #selectForm="ngForm" (ngSubmit)="onSubmit(selectForm)">
                      <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings" style="width: 100%;">
                          <input type="text" class="form-contorl" name="input_selector" [(ngModel)]="input_selector" style="padding-left: 10px;width: 100%" required autocomplete="off">
                          <button type="submit" class="btn btn-info">
                            <i class="czi-add"></i> Agregar
                        </button>
                      </div>
                     </form>
                  </div>
                  <div class="col-lg-8" *ngIf="count_selec == 0">
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                      <span class="font-weight-medium">Mensaje:</span> No hay ninguna talla en este producto.
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-10" *ngIf="count_selec > 0">
                    <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">Item</th>

                            <th class="text-center">Eliminar</th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let item of selectores">
                          <tr>
                            <td class="text-center">{{item.titulo}}</td>

                            <td class="text-center">
                              <button class="btn btn-danger btn-sm" (click)="eliminar(item._id)">Eliminar</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>


          </div>

        </div>
      </section>
    </div>
  </div>
</div>
