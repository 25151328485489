<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-optica.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ÓPTICAS ASEMBIS</h1>
      </div>
    </div>
  </div>
</section>


<section>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 d-flex justify-content-center" *ngFor="let categoria of categorias;let indice=index">
        <div class="card mt-5 mb-2 corners-rounded" style="max-width: 560px;">
          <div class="row g-0">
            <div class="col-5 col-md-12 col-lg-6">
              <img [src]="url+'/categoria/resources/thumbnails/'+ categoria.banner" class="img-fluid rounded-start"
                alt="...">
            </div>

            <div class="col-7 col-md-12 col-lg-6">
              <div class="card-body">
                <img class="none-phone" src="assets/img/icons/icon-glasses.png" style="width: 42%;">
                <h1
                  [ngClass]="{'anteojos-t title-line-height mb-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                  {{categoria.nombre}}</h1>
                <div class="col-12 col-md-10 d-grid">
                  <a [routerLink]="['/productos/'+categoria.nombre.toLowerCase().trim()]" href="/productos/{{categoria.nombre.toLowerCase().trim()}}" 
                    [ngClass]="{'btn me-3': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                    Ver anteojos
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-4 mb-4">
  <div class="container">
    <div class="row">

      <div class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card mb-3 mt-5 mt-md-5 border-0">
          <div class="row g-0">
            <div class="col-3 col-md-3 g-0">
              <img class="mt-3 mt-md-0" src="assets/img/icons/icon-process.png" style="width: 90%; margin-left: 10%;"
                alt="...">
            </div>
            <div class="col-9 col-md-9 g-0">
              <div class="card-body">
                <p [ngClass]="{'big-t mt-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
                  style="line-height: 0;">FABRICACIÓN</p>
                <p
                  [ngClass]="{'':true, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Producimos más de <strong>15.625</strong> lentes al año</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card mb-3 mt-2 mt-md-5 border-0">
          <div class="row g-0">
            <div class="col-3 col-md-3 g-0">
              <img class="mt-3 mt-md-0" src="assets/img/icons/icon-quality-2.png" style="width: 100%; margin-left: 0%;"
                alt="...">
            </div>
            <div class="col-9 col-md-9 g-0">
              <div class="card-body">
                <p [ngClass]="{'big-t mt-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
                  style="line-height: 0;">MATERIALES</p>
                <p
                  [ngClass]="{'':true, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Utilizamos materiales de primera calidad</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card mb-3 mt-2 mt-md-5 mb-3 mb-md-5 mb-md-0 border-0">
          <div class="row g-0">
            <div class="col-3 col-md-3 g-0">
              <img class="mt-3 mt-md-0" src="assets/img/icons/icon-shake.png" style="width: 100%; margin-left: 0%;"
                alt="...">
            </div>
            <div class="col-9 col-md-9 g-0">
              <div class="card-body">
                <p [ngClass]="{'big-t mt-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
                  style="line-height: 0;">CON AMOR</p>
                <p
                  [ngClass]="{'':true, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Hacemos todo con amor para nuestros clientes</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<section class="mt-5 bg-gray">
  <div class="container-fluid g-0">
    <div class="row g-0">

      <div class="col-12 col-md-12 col-lg-6 col-xl-6 g-0">
        <video class="bg-blue none-phone" style="width: 100%" autoplay [muted]="true" playsinline loop>
          <source src="assets/img/video-opt-big.mp4" type="video/mp4">
        </video>
        <video class="bg-blue only-phone" style="width: 100%" autoplay [muted]="true" playsinline loop>
          <source src="assets/img/video-opt-mobile.mp4" type="video/mp4">
        </video>
      </div>

      <div class="col-12 col-md-12 col-lg-6 col-xl-6">
        <div class="card bg-transparent border-0 p-2 p-md-4" style="width: 90%;margin-left: 5%">
          <div class="card-body">
            <img class="mt-4 none-phone" src="assets/img/icons/icon-eye.png" style="width: 12%; margin-left: 44%;">
            <img class="mt-4 only-phone" src="assets/img/icons/icon-eye.png" style="width: 30%; margin-left: 35%;">
            <h2
              [ngClass]="{'bigger-t title-line-height mt-3 mb-3 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}">
              FABRICAMOS SUS LENTES</h2>
            <p
              [ngClass]="{'':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Fabricamos lentes oftalmológicos con el mayor cuidado y dedicación para brindar a nuestros clientes
              productos de la más alta calidad. Cada uno de nuestros anteojos es creado con precisión, utilizando
              tecnología de vanguardia y materiales de primera categoría.
            </p>

            <p
              [ngClass]="{'mb-4':true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Nuestros lentes no solo son productos de calidad, sino también son hechos con amor. Valoramos la
              importancia de la visión y entendemos cómo influye en la vida de las personas. Por eso, ponemos todo
              nuestro corazón en cada par de anteojos que fabricamos, sabiendo que estamos contribuyendo a mejorar la
              calidad de vida de nuestros clientes.</p>

          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<app-foot></app-foot>