<app-nav></app-nav>

<section class="headPromos">
  <img class="none-phone" [src]="url+'popup/resources/thumbnails/'+savedPopUp?.landingCoverImage"
    alt="Imagen de promoción" style="width: 100%;">
  <img class="only-phone" [src]="url+'popup/resources/thumbnails/'+savedPopUp?.mobileCoverImage"
    alt="Imagen de promoción para telefonos" style="width: 100%;">
</section>

<section>
  <div class="container mb-5">
    <div class="row g-0 d-flex justify-content-center">

      <div class="col-12 col-md-10 g-0" style="margin-top: -45px; z-index: 100;">
        <div class="row g-0 corners-rounded shadow-box" style="overflow: hidden;">

          <div class="col-12 col-md-5 p-4 p-md-5 bg-blue g-0">
            <div class="imgBox" style="width: 100%;">
              <picture>
                <source srcset="assets/img/icon-promos1.webp" type="image/webp">
                <source srcset="assets/img/icon-promos1.png" type="image/png">
                <img src="assets/img/icon-promos1.png" alt="icono decorativo" class="mb-3 mt-3"
                  style="width: 70%; margin-left: 15%;">
              </picture>
            </div>
            <h1
              [ngClass]="{'bigger-t mb-3': true, 'white': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
              Complete sus datos y le contactaremos lo antes posible</h1>
            <p
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Su bienestar es nuestra prioridad. Aproveche nuestra promoción especial y déjenos cuidar de Usted.</p>
          </div>

          <div class="col-12 col-md-7 g-0 p-4 p-md-5 bg-white">
            <form class="mb-3" #contactoForm="ngForm" (ngSubmit)="onSubmit(contactoForm)">
              <div class="row">

                <div class="alert alert-success mt-2 mb2" role="alert" *ngIf="msm_success" (click)="close_alert()">
                  Se envió su mensaje satisfactoriamente, pronto nos pondremos en contacto.
                </div>

                <div class="alert alert-danger" role="alert" *ngIf="msm_error" (click)="close_alert()">
                  Ocurrió un error al enviar la solicitud, vuelva a intentar.
                </div>

                
                <div class="col-12">
                  <p [ngClass]="{'text-normal mt-3': true,'heavy-f': !global.dislex, 'dislex': global.dislex}">Datos
                    personales:</p>
                  <div class="form-group center-text-component mb-3">
                    <label class="poppins smalltext textblack" for="cf-name">Nombre y apellido:&nbsp;<span
                        class="text-danger">*</span></label>
                    <input class="form-control" type="text" id="cf-name" placeholder="" required name="nombres"
                      [(ngModel)]="contacto.nombres">

                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group center-text-component mb-3 mb-md-0">
                    <label class="poppins smalltext textblack " for="cf-email">Correo:&nbsp;<span
                        class="text-danger">*</span></label>
                    <input class="form-control" type="email" id="cf-email" placeholder="email@email.com" required
                      name="correo" [(ngModel)]="contacto.correo">

                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group center-text-component mb-3 mb-md-0">
                    <label class="poppins smalltext textblack" for="cf-phone">Teléfono:&nbsp;<span
                        class="text-danger">*</span></label>
                    <input class="form-control" type="number" id="cf-phone" placeholder="5555 5555" required
                      name="telefono" [(ngModel)]="contacto.telefono">

                  </div>
                </div>
                <p [ngClass]="{'text-normal mt-3': true,'heavy-f': !global.dislex, 'dislex': global.dislex}">Cuándo y
                  cómo prefiere ser contactado:</p>
                <div class="col-12 col-md-6">
                  <div class="form-group center-text-component mb-3 mb-md-0">
                    <label class="poppins smalltext textblack" for="cf-horario">Horario preferido: <span
                        class="text-danger">*</span></label>
                    <select class="form-select" aria-label="Default select example" id="cf-horario" name="horario"
                      [(ngModel)]="contacto.horario">
                      <option value="De 8am a 12pm">De 8am a 12pm</option>
                      <option value="De 12pm a 5pm">De 12pm a 5pm</option>
                      <option value="Cualquier horario">Cualquier horario</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group center-text-component mb-3 mb-md-0">
                    <label class="poppins smalltext textblack" for="cf-medio">Medio de contacto: <span
                        class="text-danger">*</span></label>

                    <select class="form-select" aria-label="Default select example" id="cf-medio" name="medio"
                      [(ngModel)]="contacto.medio">
                      <option value="Llamada">Llamada</option>
                      <option value="whatsapp">Whatsapp</option>
                      <option value="Correo">Correo</option>
                      <option value="Cualquiera">Cualquiera</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group center-text-component mt-3">
                <label class="poppins smalltext textblack" for="cf-message">¿Qué servicio le interesa agendar?:&nbsp;<span
                    class="text-danger">*</span></label>
                <textarea class="form-control" id="cf-message" rows="3"
                  placeholder="Indique los detalles de su solicitud" required name="mensaje"
                  [(ngModel)]="contacto.mensaje"></textarea>

              </div>
              <button class="btn btn-dark mt-4" type="submit" [disabled]="btn_send">Enviar mensaje</button>
            </form>
          </div>
        </div>
      </div>

      <div class="col-11 col-md-10">
        <p
          [ngClass]="{'mt-5 mb-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
          {{savedPopUp.textSection1}}
        </p>
        <p
          [ngClass]="{'mt-3 mb-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
          {{savedPopUp.textSection2}}
        </p>
        <p
          [ngClass]="{'mt-3 mb-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
          {{savedPopUp.textSection3}}
        </p>
      </div>
    </div>
  </div>

</section>




<app-foot></app-foot>