import { Component, OnInit } from '@angular/core';
import {GLOBAL} from "../../services/GLOBAL";

@Component({
  selector: 'app-trabajosocial',
  templateUrl: './trabajosocial.component.html',
  styleUrls: ['./trabajosocial.component.css']
})
export class TrabajosocialComponent implements OnInit {
  public global = GLOBAL;
  constructor() { }

  ngOnInit(): void {
  }

}
