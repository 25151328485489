<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-lg-9 pt-lg-4">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">


              <div class="col-lg-12">
                <div class="border-bottom mt-lg-2 pb-2 mb-5">
                  <h2 class="mt-lg-4"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                      class="bi bi-tags" style="color: #6610f2;" viewBox="0 0 16 16">
                      <path
                        d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
                      <path
                        d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
                    </svg> Banner promocional</h2>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Datos del banner de la página de promociones.</p>

                  </div>

                </div>
              </div>
              <div class="col-lg-12 form-group" *ngIf="msm_success">
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                  Se actualizó los datos de las configuraciones correctamente.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="col-lg-12 form-group" *ngIf="msm_error">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                  Ocurrió un error al guardar las configuraciones.
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div class="col-lg-12">
                <form #promocionForm="ngForm" (ngSubmit)="onSubmit(promocionForm)">
                  <div class="card border-0 box-shadow">

                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <label class="smalltext" for="text-input">Titulo<span style="color: red;">*</span></label>
                          <div class="">
                            <input class="form-control" type="text" placeholder="Titulo de la promo" name="titulo"
                              [(ngModel)]="promocion.titulo">
                          </div>

                          <!-- <label class="smalltext mt-4 " for="text-input">Validez</label>
                          <div class="">
                            <input class="form-control" type="text"
                                   placeholder="Ejemplo: Del 24-03-22 al 30-04-23"
                                   name="validez" [(ngModel)]="promocion.validez">
                          </div> -->
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="smalltext" for="text-input">Descripción<span
                              style="color: red;">*</span></label>
                          <div class="">
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"
                              placeholder="Descripción de la promo" name="detalle"
                              [(ngModel)]="promocion.detalle"></textarea>
                          </div>
                        </div>


                        <div class="col-12 d-flex justify-content-center">
                          <div class="form-group">

                            <div class="col-md-12">

                              <div class="row mt-5 mb-5">
                                <label class="smalltext mt-4 " for="text-input">Imágen de la promoción<span
                                    style="color: red;">*</span></label>
                                <div class="col-12 border text-center">
                                  <div *ngIf="!promo['poster']" class="">
                                    <a (click)="vamos.click()" style="cursor: pointer;">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor"
                                        class="bi bi-image text-muted mb-5 mt-5" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        <path
                                          d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                                      </svg>
                                    </a>
                                  </div>
                                  <div *ngIf="promo['poster']" class="">
                                    <a (click)="vamos.click()" style="cursor: pointer;"><img class="mb-4 mt-4"
                                        src="{{imgSelect['poster']}}" style="width: 80%;"></a>
                                  </div>
                                  <input class="form-control mb-3" type="file" id="formFile" #vamos
                                    (change)="imgSelected($event, 'poster')">
                                  <p class="small text-muted mt-2 mb-3">Peso
                                    recomendado: 300kb</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="card-footer">
                      <button class="btn btn-primary mb-2 mr-1" type="submit">Registrar promoción</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>


          <div class="container">
            <div class="row">

              <div class="col-12">
                <h4 class="mt-5 mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-info-circle-fill mb-2" style="color: #0D6EFD;" viewBox="0 0 16 16">
                    <path
                      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                  </svg>
                  A continuacion encontrara todas las promociones agregadas:
                </h4>
              </div>


              <div class="col-12 col-md-4" *ngFor="let item of promos">

                <div class="card" style="width: 18rem;">
                  <img [src]="url+'promo_img_data/resources/thumbnails/'+item.poster" class="img-fluid rounded"
                    alt="...">
                  <div class="card-body">
                    <h5 class="card-title">{{item.titulo}}</h5>
                    <p class="card-text">{{item.detalle}}</p>
                    <!-- <p>Validez: {{item.validez}}</p> -->
                    <div class="d-flex justify-content-start">
                      <a href="#" class="btn btn-primary me-3" data-toggle="modal"
                        [attr.data-target]="'#editpromo-'+item._id">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-pencil-square mb-1" viewBox="0 0 16 16">
                          <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                        Editar
                      </a>
                      <a href="#" class="btn btn-dark" data-toggle="modal" [attr.data-target]="'#modal-'+item._id">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash3 mb-1" viewBox="0 0 16 16">
                          <path
                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                        Eliminar
                      </a>
                    </div>

                  </div>
                </div>

                <div class="modal fade" [attr.id]="'modal-'+item._id" tabindex="-1" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <p class="font-size-sm">¿Desea eliminar definitivamente esta promoción?</p>
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar
                        </button>
                        <button class="btn btn-primary btn-shadow btn-sm" type="button" (click)="eliminar(item._id)">
                          Suprimir
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade" [attr.id]="'editpromo-'+item._id" tabindex="-1"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Confirmación</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <form #promocionForm="ngForm" (ngSubmit)="update(promocionForm,item._id)">
                          <div class="card border-0 box-shadow">

                            <div class="card-body">
                              <div class="row">
                                <div class="col-12 col-md-6">
                                  <label class="smalltext" for="text-input">Titulo</label>
                                  <div class="">
                                    <input class="form-control" type="text" placeholder="Titulo de la promo"
                                      #titulo="ngModel" name="titulo" [(ngModel)]="item.titulo">
                                  </div>

                                  <!-- <label class="smalltext mt-4 " for="text-input">Validez</label>
                                  <div class="">
                                    <input class="form-control" type="text"
                                           placeholder="Ejemplo: Del 24-03-22 al 30-04-23"
                                           #validez="ngModel" name="validez" [(ngModel)]="item.validez">
                                  </div> -->
                                </div>
                                <div class="col-12 col-md-6">
                                  <label class="smalltext" for="text-input">Descripción</label>
                                  <div class="">
                                    <textarea class="form-control" rows="5" placeholder="Descripción de la promo"
                                      #detalle="ngModel" name="detalle" [(ngModel)]="item.detalle"></textarea>
                                  </div>
                                </div>


                                <div class="col-12 d-flex justify-content-center">
                                  <div class="form-group">

                                    <div class="col-md-12">

                                      <div class="row mt-5 mb-5">
                                        <label class="smalltext mt-4 " for="text-input">Seleccione una nueva imágen *
                                          solo si necesita actualizarla *</label>

                                        <div class="col-12 border text-center">

                                          <input class="form-control mb-3 mt-4" type="file"
                                            (change)="imgSelected($event, 'poster')">

                                        </div>

                                      </div>


                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div class="card-footer">
                              <button class="btn btn-primary mb-2 me-1" type="submit">Actualizar promoción</button>
                              <button class="btn btn-secondary mb-2 ms-1" type="button" data-dismiss="modal">Cancelar
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

</section>