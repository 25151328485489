<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">
<div class="container pb-5">
  <div class="bg-light box-shadow-lg corners-rounded">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-3">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-9 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">


              <div class="col-12">
                  <div class="border-bottom mt-lg-2 pb-2 mb-5">
                      <h1 class="mt-lg-4">Papelera de productos</h1>
                      <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                        <p class="text-muted">Haz clic en "mover a entradas" para mantener el producto</p>

                      </div>
                      <div class="btn-toolbar" role="toolbar" aria-label="Settings toolbar">
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Settings group">

                            <a [routerLink]="['/admin/productos']" class="btn btn-dark me-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                   class="bi bi-arrow-90deg-left me-1" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
                              </svg> Regresar a Entradas
                            </a>

                          </div>
                        <form #searchForm="ngForm" (keyup)="search(searchForm)">
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                            <button type="submit" class="btn btn-light">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                   class="bi bi-search" viewBox="0 0 16 16">
                                <path
                                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                              </svg>
                            </button>
                            <input class="form-control" type="text" placeholder="Nombre" aria-label="default input example"
                                   name="filtro" [(ngModel)]="filtro" style="padding-left: 10px;">

                            <select class="form-select" name="search_categoria" aria-label="Default select example"
                                    [(ngModel)]="search_categoria"
                                    (change)="search_cat()">
                              <option value="" selected disabled>Categoría</option>
                              <option [value]="item._id" *ngFor="let item of categorias">{{item.nombre}}</option>
                            </select>

                          </div>
                        </form>
                          <div class="btn-group mb-2" role="group" aria-label="Delete settings">
                            <button type="button" (click)="resetSearch()" class="btn bg-faded-danger btn-icon" title="Resetear filtro">
                              <i class="czi-reply"></i>
                            </button>
                          </div>
                        </div>
                  </div>

                <div class="col-12">

                  <div class="col-12 mb-4" *ngIf="count_cat == 0">
                    <div class="alert alert-dark mb-0" role="alert" >
                      <div class="alert-icon-box"><i class="alert-icon czi-security-announcement"></i></div>No hay ningún elemento en la papelera de reciclaje.
                    </div>
                  </div>

                  <div class="card mb-3 mt-3 ms-3" style="max-width: 80%"
                       *ngFor="let item of productos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img [src]="url+'productos_img_data/resources/thumbnails/'+item.poster"
                             class="img-fluid rounded" alt="...">
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h4 class="card-title">{{item.titulo}}</h4>
                          <div class="d-flex justify-content-start">
                            <p class="card-text me-3">Precio: ₡{{item.precio_ahora}}</p>
                            <p class="card-text">Antes: <span
                              class="text-decoration-line-through">₡{{item.precio_antes}}</span></p>
                          </div>

                          <div class="col-12">
                            <button type="button" class="btn btn-primary" *ngIf="item.status == 'Papelera'" data-toggle="modal" [attr.data-target]="'#mover-'+item._id" >Mover a entradas</button>

                            <div class="modal fade" [attr.id]="'mover-'+item._id" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h4 class="modal-title">Confirmación</h4>
                                    <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                    <p class="font-size-sm">Al aceptar, estás confirmando que el producto regresará a las entradas en estado <b>DESACTIVADO</b>.</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cerrar</button>
                                    <button class="btn btn-primary btn-shadow btn-sm" type="button" (click)="desactivar(item._id)">Aceptar</button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <p class="text-muted mt-2" *ngIf="item.status == 'Papelera'" style="font-size: 13px;">Este producto no está disponible en la tienda.</p>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <ngb-pagination
                      [(page)]="page"
                      [pageSize]="pageSize"
                      [collectionSize]="count_cat"></ngb-pagination>
                  </div>

                </div>


<!--
                  <div class="card-check">
                      <div class="row">
                        <div class="col-lg-12 mb-4" *ngIf="count_cat == 0">
                          <div class="alert alert-dark mb-0" role="alert" >
                            <div class="alert-icon-box"><i class="alert-icon czi-security-announcement"></i></div>No hay ningún elemento en la papelera de reciclaje.
                          </div>
                        </div>
                          <div class="col-11 mb-3"
                               *ngFor="let item of productos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                            <div class="card">
                                <div class="row no-gutters">
                                  <div class="col-sm-4">
                                    <img [src]="url+'productos_img_data/resources/thumbnails/'+item.poster" class="rounded-left" alt="Card image">
                                  </div>
                                  <div class="col-md-8">
                                    <div class="card-body">
                                      <h5 class="card-title">{{item.titulo}}</h5>
                                      <div class="d-flex justify-content-start">
                                        <p class="card-text me-3">Precio: ₡{{item.precio_ahora}}</p>
                                        <p class="card-text">Antes: <span
                                          class="text-decoration-line-through">₡{{item.precio_antes}}</span></p>
                                      </div>
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <button type="button" class="btn btn-primary" *ngIf="item.status == 'Papelera'" data-toggle="modal" [attr.data-target]="'#mover-'+item._id" >Mover a entradas</button>

                                            <div class="modal fade" [attr.id]="'mover-'+item._id" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
                                              <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">Confirmación</h4>
                                                    <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <p class="font-size-sm">Al aceptar, estás confirmando que el producto regresará a las entradas en estado <b>DESACTIVADO</b>.</p>
                                                  </div>
                                                  <div class="modal-footer">
                                                    <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cerrar</button>
                                                    <button class="btn btn-primary btn-shadow btn-sm" type="button" (click)="desactivar(item._id)">Aceptar</button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                        </div>
        <br>
                                        <span class="text-muted" *ngIf="item.status == 'Papelera'" style="font-size: 13px;">Este producto no está disponible en la tienda.</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                          </div>


                      </div>
                  </div>-->

              </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div>
</section>
