<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark" >
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

<div class="container pb-5">
  <div class="bg-light box-shadow-lg corners-rounded">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-3">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-9 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">
            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2 mb-5">
                <h1 class="mt-lg-4">Edición de artículo</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Complete correctamente el formulario.</p>
                  <a class="btn btn-dark me-1" [routerLink]="['/admin/articles']" href="/admin/articles">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-arrow-90deg-left me-1" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
                    </svg>
                    Regresar</a>
                </div>
              </div>

              <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{msm_error}}
                <button class="btn-close" type="button" data-dismiss="alert" aria-label="Close"></button>
              </div>

              <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show" role="alert">
                {{msm_success}}
                <button class="btn-close" type="button" data-dismiss="alert" aria-label="Close"></button>
              </div>


              <form #articleForm="ngForm" (ngSubmit)="onSubmit(articleForm)">
                <div class="card border-0 box-shadow">

                  <div class="card-body" >
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Titulo</label>
                          <div class="col-md-9">
                            <input class="form-control" type="text" placeholder="Titulo de la categoría" #nombre="ngModel" name="nombre" [(ngModel)]="article.nombre">
                          </div>
                        </div>

                        <div class="form-group row align-items-center">
                          <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Portada</label>
                          <div class="col-md-9">

                            <div class="row mt-5 mb-5">
                              <div class="col-1">
                              </div>
                              <div class="col-8 border text-center">

                                <div class="">
                                  <a (click)="vamos.click()" style="cursor: pointer;"><img class="mb-4 mt-4"
                                                                                           [src]="imgSelect?.banner || url+'article/resources/thumbnails/'+article?.banner"
                                                                                           style="width: 80%;"></a>
                                </div>

                                <input class="form-control mb-3" type="file" id="formFile" #vamos
                                       (change)="imgSelected($event, 'banner')">
                              </div>

                            </div>

                          </div>
                        </div>


                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="textarea-input">Cuerpo</label>
                          <div class="col-md-10">
                            <tinymce #body="ngModel" [(ngModel)]="article.body" required name="body"
                                     [config]="config"></tinymce>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary mb-2 mr-1" type="submit">Actualizar campos</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div>
</section>
