<div class="d-flex flex-column flex-shrink-0 mt-3 p-3 text-dark bg-light border-end" style="width: 290px;">
  <a class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-toggles me-3"
      style="color: #6610f2;" viewBox="0 0 16 16">
      <path
        d="M4.5 9a3.5 3.5 0 1 0 0 7h7a3.5 3.5 0 1 0 0-7h-7zm7 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-7-14a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm2.45 0A3.49 3.49 0 0 1 8 3.5 3.49 3.49 0 0 1 6.95 6h4.55a2.5 2.5 0 0 0 0-5H6.95zM4.5 0h7a3.5 3.5 0 1 1 0 7h-7a3.5 3.5 0 1 1 0-7z" />
    </svg>
    <span class="fs-4 text-dark">Dashboard</span>
  </a>
  <hr>
  <ul class="nav nav-pills flex-column mb-auto">

    <h5 class="text-muted fs-6 mb-2">General</h5>

    <li>
      <a class="nav-link mb-1 border-bottom" [routerLink]="['/admin/portada']" [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-images me-2"
          viewBox="0 0 16 16">
          <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
          <path
            d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
        </svg>
        Portada
      </a>
    </li>

    <li>
      <a href="#" class="nav-link mb-1 border-bottom" [routerLink]="['/admin/especialidadesadmin']"
        [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-shield-check me-2" viewBox="0 0 16 16">
          <path
            d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
          <path
            d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
        </svg>
        Especialidades
      </a>
    </li>
    <li>
      <a href="#" class="nav-link mb-1 border-bottom" [routerLink]="['/admin/location']"
        [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt me-2"
          viewBox="0 0 16 16">
          <path
            d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
        Sucursales
      </a>
    </li>
    <li>
      <a href="#" class="nav-link mb-1 border-bottom" [routerLink]="['/admin/promocion-admin']"
        [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-patch-check me-2" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
          <path
            d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
        </svg>
        Promociones
      </a>
    </li>
    <!--<li>
      <a href="#" class="nav-link mb-1 border-bottom" [routerLink]="['/admin/hitos']" [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase me-2" viewBox="0 0 16 16">
          <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Convenios
      </a>
    </li>-->

    <h5 class="text-muted fs-6 mb-2 mt-4">Productos</h5>
    <li>
      <a class="nav-link mb-1 border-bottom" [routerLink]="['/admin/productos']" [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eyeglasses me-2"
          viewBox="0 0 16 16">
          <path
            d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
        </svg>
        Anteojos
      </a>
    </li>


    <h5 class="text-muted fs-6 mb-2 mt-4 ">Entradas</h5>
    <li>
      <a href="#" class="nav-link mb-1 border-bottom" [routerLink]="['/admin/articles']"
        [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-file-richtext me-2" viewBox="0 0 16 16">
          <path
            d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
          <path
            d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
        </svg>
        Blog
      </a>
    </li>

    <h5 class="text-muted fs-6 mb-2 mt-4 ">Promo PopUp</h5>
    <li>
      <a href="#" class="nav-link mb-1 border-bottom" [routerLink]="['/admin/popup']" [routerLinkActive]="['active']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-pencil-square me-2" viewBox="0 0 16 16">
          <path
            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
          <path fill-rule="evenodd"
            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
        </svg>
        Editar promo
      </a>
    </li>
    <li class="border-bottom mb-0"><a class="nav-link mb-1 border-bottom" [routerLink]="['/admin/mensajes']"
        routerLinkActive="active"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-chat-dots me-2" viewBox="0 0 16 16">
          <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
          <path
            d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2" />
        </svg>Mensajes</a>
    </li>
  </ul>
  <hr>
</div>

<!-- Account menu toggler (hidden on screens larger 992px)-->
<!--
<div class="d-block d-lg-none p-4"><a class="btn btn-outline-accent d-block" href="#account-menu" data-toggle="collapse"><i class="czi-menu mr-2"></i>Menú lateral</a></div>
-->
<!-- Actual menu-->
<!--<div class="cz-sidebar-static h-100 p-0">
  <div class="secondary-nav collapse border-right" id="account-menu">
    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">General</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0">
        <a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/dashboard']" routerLinkActive="active" >
          <i class="czi-home opacity-60 mr-2"></i> Dashboard
        </a>
      </li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/portada']" routerLinkActive="active" ><i class="czi-check opacity-60 mr-2"></i>Portada</a></li>

    </ul>
    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">Tienda</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/categorias']" routerLinkActive="active" ><i class="czi-heart opacity-60 mr-2"></i>Colecciones</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/productos']" routerLinkActive="active"><i class="czi-heart-circle opacity-60 mr-2"></i>Productos</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/ventas/modulo']" routerLinkActive="active" ><i class="czi-package opacity-60 mr-2"></i>Ordenes</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/mensajes']" routerLinkActive="active" ><i class="czi-chat opacity-60 mr-2"></i>Mensajes</a></li>
    </ul>

&lt;!&ndash;
    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">Logistica</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/medios-postales']" routerLinkActive="active"><i class="czi-truck opacity-60 mr-2"></i>Medios de envios</a></li>
    </ul>&ndash;&gt;

    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">Entradas</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/articles']" routerLinkActive="active" ><i class="czi-bookmark opacity-60 mr-2"></i>Artículos</a></li>
    </ul>
    <ul class="list-unstyled mb-0">
    </ul>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/preguntas']" routerLinkActive="active" ><i class="czi-message opacity-60 mr-2"></i>Preguntas Frecuentes</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/hitos']" routerLinkActive="active" ><i class="czi-star opacity-60 mr-2"></i>Hitos AMAG</a></li>
    </ul>
  </div>
</div>-->