<div *ngIf="isLoaded">
  <app-nav></app-nav>


  <section *ngIf="portada?.tipo == 'Portada'">
    <div *ngIf="desktopFileType=='image'" class="card bg-dark text-white none-phone"
      style="border-radius: 0 !important; background-color: transparent !important;">
      <img [src]="url+'/portada/resources/thumbnails/' + portada?.portada" class="bgGray" alt="..."
        style="border-radius: 0 !important; max-width: 100%;" loading="eager">
      <div class="card-img-overlay">
      </div>
    </div>

    <div *ngIf="mobileFileType=='image'" class="card bg-dark text-white none-desktop"
      style="border-radius: 0 !important; background-color: transparent !important;">
      <img [src]="url+'/portada/resources/thumbnails/' + portada?.portadaMobile" class="bgGray w-100" alt="..."
        style="border-radius: 0 !important;" loading="eager">
      <div class="card-img-overlay">
      </div>
    </div>

    <div *ngIf="desktopFileType=='video'" class="card bg-dark text-white none-phone"
      style="border-radius: 0 !important; background-color: transparent !important;">
      <video style="width: 100%;" autoplay loop [muted]="true">
        <source [src]="url+'/portada/resources/thumbnails/' + portada?.portada"
          [type]="'video/' + this.portada.portada.split('.').pop()">
      </video>
      <div class="card-img-overlay">
      </div>
    </div>


    <div *ngIf="mobileFileType=='video'" class="card bg-dark text-white none-desktop"
      style="border-radius: 0 !important; background-color: transparent !important;">
      <video style="width: 100%;" autoplay loop [muted]="true">
        <source [src]="url+'/portada/resources/thumbnails/' + portada?.portadaMobile"
          [type]="'video/' + this.portada.portadaMobile.split('.').pop()">
      </video>
      <div class="card-img-overlay">
      </div>
    </div>
  </section>


  <section class="second-cover" *ngIf="portada?.tipo == 'Slider'">
    <!-- Carrusel Desktop -- Los tiempos de slide se manejan desde el .ts en el ngAfterViewInit -->
    <div id="portadaamagdesktop" class="carousel" *ngIf="!isMobile && portada" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active" *ngIf="portada?.slider1">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider1" class="d-block w-100"
            alt="Imagen decorativa de portada 1" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider2">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider2" class="d-block w-100"
            alt="Imagen decorativa de portada 2" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider3">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider3" class="d-block w-100"
            alt="Imagen decorativa de portada 3" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider4">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider4" class="d-block w-100"
            alt="Imagen decorativa de portada 4" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider5">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider5" class="d-block w-100"
            alt="Imagen decorativa de portada 5" loading="eager">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#portadaamagdesktop" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#portadaamagdesktop" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Carrusel Mobile  -- Los tiempos de slide se manejan desde el .ts en el ngAfterViewInit  -->
    <div id="portadaamagmobile" class="carousel" *ngIf="isMobile && portada" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active" *ngIf="portada?.slider1Mobile">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider1Mobile" class="d-block w-100"
            alt="Imagen decorativa de portada 1 mobile" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider2Mobile">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider2Mobile" class="d-block w-100"
            alt="Imagen decorativa de portada 2 mobile" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider3Mobile">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider3Mobile" class="d-block w-100"
            alt="Imagen decorativa de portada 3 mobile" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider4Mobile">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider4Mobile" class="d-block w-100"
            alt="Imagen decorativa de portada 4 mobile" loading="eager">
        </div>
        <div class="carousel-item" *ngIf="portada?.slider5Mobile">
          <img [src]="url+'/portada/resources/thumbnails/' + portada.slider5Mobile" class="d-block w-100"
            alt="Imagen decorativa de portada 5 mobile" loading="eager">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#portadaamagmobile" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#portadaamagmobile" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </section>


  <section class="bg-gray">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-12 col-lg-7">
          <div class="card mt-4 mb-4 border-0 corners-rounded">
            <div class="row g-0">
              <div class="col-1"></div>
              <div class="col-12 col-md-6">
                <div class="card-body">
                  <h5 [ngClass]="{'bigger-t title-line-height mt-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex, 'blue':!global.color_filter,
          'black': global.color_filter}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor"
                      class="bi bi-calendar3 only-phone mb-3" viewBox="0 0 16 16">
                      <path
                        d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                      <path
                        d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>
                    SOLICITE SU CITA<span class="only-phone"> MÉDICA</span>
                  </h5>
                  <p [ngClass]="{'mb-0 roboto mb-3': true, 'dislex': global.dislex, 'sizetext': global.sizetext}"> Su
                    salud
                    es nuestra prioridad. Agende ya su cita médica!</p>

                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="d-grid pe-4 ps-4 pb-4 pe-md-5">
                  <!--  <a [routerLink]="['/citas']"
                       [ngClass]="{'btn btn-md-lg mt-md-5': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-calendar-check mb-1 me-1" viewBox="0 0 16 16">
                        <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                      </svg> AGENDA TU CITA
                    </a>-->
                  <h4 class="mt-5 turquesa bigger-t text-center none-phone">Llame al <br>2285 5881</h4>

                  <a (click)="onPhoneCallClick($event, 'tel:+50622855881')"
                    [ngClass]="{'btn btn-md-lg mt-md-5 only-phone': true, 'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-telephone-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                    AGENDA TU CITA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="bg-gray">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-12 text-center">
          <h2 [ngClass]="{'bigger-t title-line-height mt-5': true, 'bold-f': !global.dislex, 'dislex': global.dislex, 'turquesa':!global.color_filter,
          'black': global.color_filter}">ENCUENTRE SU ASEMBIS</h2>
          <h1 [ngClass]="{'extrahuge-t bold-f mb-4': !global.color_filter, 'blue': !global.color_filter,
            'black': !global.dislex }" style="line-height: 0.8;">MÁS CERCANO</h1>
        </div>


        <div class="col-12 col-md-11">

          <mat-stepper class="mb-5">
            <mat-step>

              <ng-template matStepLabel>Provincia</ng-template>
              <h3
                [ngClass]="{'big-t title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                SELECCIONE SU PROVINCIA
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                  class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
                  <path
                    d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z" />
                </svg>
              </h3>
              <div class="container-fluid">

                <div class="row">

                  <div class="col-12 col-md-4 d-grid" *ngFor="let item of provinciasToShow">
                    <button (click)="selectProvincia(item.name)" class="btn btn-warning btn-lg mt-4" mat-button
                      matStepperNext>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path
                          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                      </svg>
                      {{item.name}}
                    </button>
                  </div>


                </div>

              </div>


            </mat-step>
            <mat-step>

              <ng-template matStepLabel>Sucursales</ng-template>

              <button type="button" class="btn btn-warning btn-lg mt-2 mb-4 ms-2" matStepperPrevious>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-arrow-left-circle-fill me-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                </svg>
                Ir atrás
              </button>

              <div class="container g-0">

                <div class="row">


                  <div class="col-12">
                    <h3
                      [ngClass]="{'big-t title-line-height mt-3 mb-3': true, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                      NOS PUEDE ENCONTRAR EN:</h3>
                  </div>

                  <div class="col-12 col-md-6 d-flex" *ngFor="let item of locationsToShow">


                    <div class="card mb-3 mb-md-4" style="width: 100%;">
                      <div class="card-body">
                        <h5
                          [ngClass]="{'text-big': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                          {{item.name}}</h5>

                        <p [ngClass]="{'': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                            class="bi bi-geo-alt blue me-2" viewBox="0 0 16 16">
                            <path
                              d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          <strong>Dirección:</strong> {{item.located}}
                        </p>

                        <p [ngClass]="{'mb-0': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                            class="bi bi-calendar blue me-2" viewBox="0 0 16 16">
                            <path
                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                          </svg>
                          <strong>Horarios:</strong>
                        </p>

                        <p [ngClass]="{'': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                          <strong>• Lunes a viernes</strong> {{item.weekday}} <br>
                          <strong>• Sábados</strong> {{item.saturday}} <br>
                          <strong *ngIf="item?.sunday">• Domingos </strong>{{item.sunday}}
                        </p>

                        <div class="row">
                          <!--<div class="col-12 col-md-8">
                            <p [ngClass]="{'': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                   class="bi bi-envelope me-2 blue" viewBox="0 0 16 16">
                                <path
                                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                              </svg>
                              {{item.mail}}</p>
                          </div>-->
                          <div *ngIf="isDesktop" class="col-12 col-md-12 none-phone">
                            <p [ngClass]="{'': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}"
                              *ngIf="item?.phone">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-telephone blue me-2" viewBox="0 0 16 16">
                                <path
                                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                              </svg>
                              <strong>Agenda tu cita:</strong> {{item.phone}}
                            </p>
                          </div>
                        </div>


                        <div class="d-flex justify-content-start">
                          <a href="{{item?.maps}}" target="_blank" *ngIf="item?.maps">
                            <button type="button"
                              [ngClass]="{'btn m-2': true,'cursor': global.cursor_btn, 'btn-primary': !global.color_filter, 'btn-dark': global.color_filter}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg>
                              Google Maps
                            </button>
                          </a>

                          <a href="{{item?.waze}}" target="_blank" *ngIf="item?.waze">
                            <button type="button" [ngClass]="{'btn btn-dark m-2': true,'cursor': global.cursor_btn}">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg>
                              Waze
                            </button>
                          </a>
                        </div>

                        <div class="d-grid">
                          <a class="only-phone" href="tel:+50622855881"
                            [ngClass]="{'btn btn-md-lg m-2': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                              class="bi bi-telephone-fill me-1" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            AGENDA TU CITA
                          </a>
                        </div>


                      </div>
                    </div>

                  </div>
                </div>


              </div>

            </mat-step>
          </mat-stepper>

        </div>
      </div>
    </div>
  </section>


  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-3 mb-5">
          <!-- -- Los tiempos de slide se manejan desde el .ts en el ngAfterViewInit -->
          <div id="carouselExampleDark" class="carousel carousel-dark mt-5" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3"
                aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4"
                aria-label="Slide 4"></button>
            </div>

            <div class="carousel-inner">

              <div class="carousel-item active">
                <div class="row d-flex justify-content-center">
                  <div class="col-10 col-lg-10">
                    <div class="card bg-gray border-0 mb-3 corners-rounded">
                      <div class="row g-0">
                        <div class="col-md-6">
                          <picture class="my-element__image">
                            <source srcset="assets/img/slide-aiLens.webp" type="image/webp">
                            <source srcset="assets/img/slide-aiLens.jpg" type="image/jpeg">
                            <img src="assets/img/slide-aiLens.jpg" class=""
                              alt="Imagen de portada - aiLens: diseñados por inteligencia artificial"
                              style="width: 100%;">
                          </picture>
                        </div>
                        <div class="col-md-6">
                          <div class="card-body">
                            <img src="assets/img/icons/icon-eye.png" class="card-img-top eye-icon" alt="...">
                            <h2 [ngClass]="{'big-t title-line-height mt-2 mb-2 text-center': true,
              'turquesa': !global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex }">Disfruta de la
                              mejor tecnología en la confección de lentes progresivos</h2>
                            <ul
                              [ngClass]="{'mb-0 ps-5 pe-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                              <li>Visión natural como nunca antes.</li>
                              <li>Zona de lectura más confortable.</li>
                              <li>Sin Cansancio visual para el uso de computadoras.</li>
                              <li>Precisión de imágenes en alta definición</li>
                              <li>Adaptación única.</li>
                              <li>Visión Protegida y saludable.</li>
                              <li>Beneficios de una visión sin reflejos</li>

                            </ul>
                            <p class="green"
                              [ngClass]="{'green ps-4 pe-3 mt-3': !global.color_filter,
              'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                              Solicita ahora un examen de la vista GRATIS al 2285-5881</p>
                            <div class="container">
                              <div class="row justify-content-center">
                                <div class="col-8 col-md-5 align-self-center d-grid">
                                  <!--<a href="#"
                                     [ngClass]="{'btn btn-block btn-sm': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Ir
                                    a óptica
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-arrow-right" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                    </svg>
                                  </a>-->
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row d-flex justify-content-center">
                  <div class="col-10 col-lg-10">
                    <div class="card bg-gray border-0 mb-3 corners-rounded">
                      <div class="row g-0">
                        <div class="col-md-6">
                          <picture>
                            <source *ngIf="isDesktop" srcset="assets/img/slide-1.webp" type="image/webp">
                            <source *ngIf="isDesktop" srcset="assets/img/slide-1.png" type="image/png">
                            <source *ngIf="isMobile" srcset="assets/img/slide-1m.webp" type="image/webp">
                            <source *ngIf="isMobile" srcset="assets/img/slide-1m.jpg" type="image/jpeg">
                            <img src="assets/img/slide-1.png" class="img-fluid corners-rounded border-0 none-phone"
                              alt="Imagen de examen de la vista">
                          </picture>
                        </div>
                        <div class="col-md-6">
                          <div class="card-body">
                            <img src="assets/img/icons/icon-eye.png" class="card-img-top eye-icon" alt="...">
                            <h2 [ngClass]="{'big-t title-line-height mt-2 mb-2 text-center': true,
              'turquesa': !global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex }">ÓPTICAS ASEMBIS
                            </h2>
                            <p
                              [ngClass]="{'mb-0 ps-3 pe-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                              Más de 30 años a su servicio, respaldan nuestro compromiso con la calidad. Poseemos un
                              laboratorio de
                              producción de lentes (ASEMBIS LAB), único en el país, el cual nos permite fabricar lentes
                              con mayores
                              resoluciones ópticas para optimizar la calidad de la visión.<br>
                              Visítenos y compruebe, que en ASEMBIS, hacemos la diferencia.
                            </p>
                            <p class="green"
                              [ngClass]="{'green ps-3 pe-3': !global.color_filter,
              'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                              Somos la empresa número uno en venta y fabricación de lentes con la más alta
                              tecnología y equipos en Costa Rica</p>
                            <div class="container">
                              <div class="row justify-content-center">
                                <div class="col-8 col-md-5 align-self-center d-grid">
                                  <!--<a href="#"
                                     [ngClass]="{'btn btn-block btn-sm': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Ir
                                    a óptica
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-arrow-right" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                    </svg>
                                  </a>-->
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row d-flex justify-content-center">
                  <div class="col-10 col-lg-10">
                    <div class="card bg-gray border-0 mb-3 corners-rounded">
                      <div class="row g-0">
                        <div class="col-md-6">
                          <picture>
                            <source *ngIf="isDesktop" srcset="assets/img/slide-2.webp" type="image/webp">
                            <source *ngIf="isDesktop" srcset="assets/img/slide-2.png" type="image/png">
                            <source *ngIf="isMobile" srcset="assets/img/slide-2m.webp" type="image/webp">
                            <source *ngIf="isMobile" srcset="assets/img/slide-2m.jpg" type="image/jpeg">
                            <img src="assets/img/slide-2.png" class="img-fluid corners-rounded border-0 none-phone"
                              alt="Imagen de consulta odontológica">
                          </picture>
                        </div>
                        <div class="col-md-6">
                          <div class="card-body">
                            <img src="assets/img/icons/icon-dentist.png" class="card-img-top eye-icon" alt="...">
                            <h2 class=" " [ngClass]="{'big-t title-line-height mt-2 mb-2 text-center': true,
              'turquesa': !global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex }">ODONTOLOGÍA</h2>
                            <p class=""
                              [ngClass]="{'mb-0 ps-3 pe-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                              Para mantener una buena salud bucodental, es necesario mantener buenos hábitos de higiene
                              además de una limpieza dental profesional por lo menos una vez al año.<br>
                              Nuestro servicio de Odontología está a su disposición con los mejores especialistas.
                              Además,
                              le ofrecemos: Estética dental, implantes dentales, entre otros servicios.
                            </p>
                            <p class="green"
                              [ngClass]="{'green ps-3 pe-3': !global.color_filter,
              'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                              Solicite ya una valoración odontológica sin pagar de más.</p>
                            <!-- <div class="container">
                               <div class="row justify-content-center">
                                 <div class="col-8 col-md-5 align-self-center d-grid">
                                   <a href="#"
                                      [ngClass]="{'btn btn-block btn-sm': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Conocer
                                     más
                                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                          class="bi bi-arrow-right" viewBox="0 0 16 16">
                                       <path fill-rule="evenodd"
                                             d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                     </svg>
                                   </a>
                                 </div>
                               </div>
                             </div>-->

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row d-flex justify-content-center">
                  <div class="col-10 col-lg-10">
                    <div class="card bg-gray border-0 mb-3 corners-rounded">
                      <div class="row g-0">
                        <div class="col-md-6">
                          <picture>
                            <source *ngIf="isDesktop" srcset="assets/img/slide-3.webp" type="image/webp">
                            <source *ngIf="isDesktop" srcset="assets/img/slide-3.png" type="image/png">
                            <source *ngIf="isMobile" srcset="assets/img/slide-3m.webp" type="image/webp">
                            <source *ngIf="isMobile" srcset="assets/img/slide-3m.jpg" type="image/jpeg">
                            <img src="assets/img/slide-3.png" class="img-fluid corners-rounded border-0 none-phone"
                              alt="Imagen de consulta médica">
                          </picture>
                        </div>
                        <div class="col-md-6">
                          <div class="card-body">
                            <img src="assets/img/icons/icon-human.png" class="card-img-top eye-icon" alt="...">
                            <h2 class=" " [ngClass]="{'big-t title-line-height mt-2 mb-2 text-center': true,
              'turquesa': !global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex }">MEDICINA
                              GENERAL</h2>
                            <p
                              [ngClass]="{'mb-0 ps-3 pe-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                              Su salud es primordial para nosotros, por esa razón le ofrecemos el servicio de Medicina
                              General.<br>
                              La Medicina General es el primer nivel de atención médica cuyo objetivo es prevenir,
                              detectar y tratar enfermedades comunes, así como también derivar con el especialista
                              indicado, en caso de ser necesario.
                              Dependiendo del caso se procede a la identificación de factores de riesgo para
                              enfermedades
                              latentes o potenciales en corto, mediano y largo plazo; orientando al paciente sobre la
                              manera más adecuada de prevenirlas e incentivando estilos de vida saludables. Agende su
                              cita
                              ahora y realice un chequeo médico.

                            </p>
                            <p class="green"
                              [ngClass]="{'green ps-3 pe-3': !global.color_filter,
              'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                              Empiece ahora mismo con un control de
                              medicina preventiva. Su salud no puede esperar.</p>
                            <!-- <div class="container">
                               <div class="row justify-content-center">
                                 <div class="col-8 col-md-5 align-self-center d-grid">
                                   <a href="#"
                                      [ngClass]="{'btn btn-block btn-sm': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Conocer
                                     más
                                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                          class="bi bi-arrow-right" viewBox="0 0 16 16">
                                       <path fill-rule="evenodd"
                                             d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                     </svg>
                                   </a>
                                 </div>
                               </div>
                             </div>-->

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row d-flex justify-content-center">
                  <div class="col-10 col-lg-10">
                    <div class="card bg-gray border-0 mb-3 corners-rounded">
                      <div class="row g-0">
                        <div class="col-md-6">
                          <picture>
                            <source *ngIf="isDesktop" srcset="assets/img/slide-4.webp" type="image/webp">
                            <source *ngIf="isDesktop" srcset="assets/img/slide-4.png" type="image/png">
                            <source *ngIf="isMobile" srcset="assets/img/slide-4m.webp" type="image/webp">
                            <source *ngIf="isMobile" srcset="assets/img/slide-4m.jpg" type="image/jpeg">
                            <img src="assets/img/slide-4.png" class="img-fluid corners-rounded border-0 none-phone"
                              alt="Imagen de consulta audiológica">
                          </picture>
                        </div>
                        <div class="col-md-6">
                          <div class="card-body">
                            <img src="assets/img/icons/icon-ears.png" class="card-img-top eye-icon" alt="...">
                            <h2 class=" "
                              [ngClass]="{'big-t title-line-height mt-2 mb-2 text-center': true,'turquesa': !global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex }">
                              AUDIOMETRÍA</h2>
                            <p class=""
                              [ngClass]="{'mb-0 ps-3 pe-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                              Escuchar bien para socializar mejor.
                              Con nuestro servicios de Audiometría le ayudamos a escuchar mejor y así llevar una mejor
                              vida social.<br>
                              ¿Qué es una audiometría?, Es una evaluación auditiva en la que se mide la sensibilidad de
                              los
                              órganos del oído a través de la percepción de sonidos. Este examen auditivo se aplica con
                              el
                              fin de saber hasta qué punto una persona puede oír.
                              Le ofrecemos Audiometrías Clínicas y de Rastreo las cuales le ayudarán a obtener un
                              diagnóstico certero. Las Audiometrías de Rastreo <strong>son gratuitas para Adultos
                                Mayores.</strong>
                              También le ofrecemos variedad de audífonos según su necesidad, además <strong>aceptamos
                                recetas de
                                la C.C.S.S.</strong>
                            <p class="green"
                              [ngClass]="{'green ps-3 pe-3': !global.color_filter,'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                              Acerquese a ASEMBIS y escuche como le podemos ayudar.</p>
                            <!-- <div class="container">
                               <div class="row justify-content-center">
                                 <div class="col-8 col-md-5 align-self-center d-grid">
                                   <a href="#"
                                      [ngClass]="{'btn btn-block btn-sm': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Conocer
                                     más
                                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                          class="bi bi-arrow-right" viewBox="0 0 16 16">
                                       <path fill-rule="evenodd"
                                             d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                     </svg>
                                   </a>
                                 </div>
                               </div>
                             </div>-->

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
              data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section [ngClass]="{'border-turq': true, 'bg-blue': !global.color_filter}">
    <div class="container">
      <div class="row">

        <div class="col-1 col-md-2 col-lg-4"></div>
        <div class="col-10 col-md-8 col-lg-4 text-center">
          <img class="icon-logo mt-5" *ngIf="!global.color_filter" src="assets/img/logoASEMBIS-white.svg"
            alt="Logo de ASEMBIS">
          <img class="icon-logo mt-5" *ngIf="global.color_filter" src="assets/img/logoASEMBIS-black.svg"
            alt="Logo de ASEMBIS">
          <p
            [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
            Somos una clínica de especialidades médicas que nos apasiona el servicio y la salud de las personas</p>
        </div>

        <div class="col-1 col-md-2 col-lg-4 "></div>

        <div *ngIf="isDesktop" class="col-12 col-md-6 col-lg-4 none-phone">
          <div [ngClass]="{'card-about mb-3 mt-3 card': true, 'bg-transparent': !global.color_filter,
          'bg-gray': global.color_filter}" style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-3 col-md-4">
                <img src="assets/img/icons/icon-about-heart.png" alt="Icono de trabajo social"
                  class="img-fluid rounded-start mt-2" style="width: 60%; margin-left: 15%;">
              </div>
              <div class="col-9 col-md-8">
                <div class="card-body p-1">
                  <h5
                    [ngClass]="{'big-t mt-2': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    + 31.000</h5>
                  <p
                    [ngClass]="{'white': !global.color_filter, 'black': global.color_filter,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                    personas subsidiadas en trabajo social</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isDesktop" class="col-12 col-md-6 col-lg-4 none-phone">
          <div [ngClass]="{'card-about mb-3 mt-3 card': true, 'bg-transparent': !global.color_filter,
          'bg-gray': global.color_filter}" style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-3 col-md-4 col-md-4">
                <img src="assets/img/icons/icon-about-heart.png" alt="Icono de trabajo social"
                  class="img-fluid rounded-start mt-2" style="width: 60%; margin-left: 15%;">
              </div>
              <div class="col-9 col-md-8 col-md-8">
                <div class="card-body p-1">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'big-t mt-2': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      + 750 millones</h5>
                  </div>
                  <p
                    [ngClass]="{'white': !global.color_filter, 'black': global.color_filter,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                    de colones en donaciones realizadas</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isMobile" class="col-12 only-phone">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div [ngClass]="{'card-about mb-3 mt-3 card': true, 'bg-transparent': !global.color_filter,
                'bg-gray': global.color_filter}" style="max-width: 90%; margin-left: 5%;">
                  <div class="row g-0">
                    <div class="col-3 col-md-4">
                      <img src="assets/img/icons/icon-about-heart.png" alt="Icono de trabajo social"
                        class="img-fluid rounded-start mt-4" style="width: 60%; margin-left: 15%;">
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="card-body p-1">
                        <h5
                          [ngClass]="{'data-size mt-2': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex }">
                          +31.000</h5>
                        <p
                          [ngClass]="{'white': !global.color_filter, 'black': global.color_filter,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                          personas subsidiadas en trabajo social</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div [ngClass]="{'card-about mb-3 mt-3 card': true, 'bg-transparent': !global.color_filter,
                'bg-gray': global.color_filter}" style="max-width: 90%; margin-left: 5%;">
                  <div class="row g-0">
                    <div class="col-3 col-md-4">
                      <img src="assets/img/icons/icon-about-heart.png" alt="Icono de trabajo social"
                        class="img-fluid rounded-start mt-4" style="width: 60%; margin-left: 15%;">
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="card-body p-1">
                        <div class="d-flex justify-content-start">
                          <h5
                            [ngClass]="{'data-size mt-2 mt-2': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                            + 750</h5>
                          <h5
                            [ngClass]="{'text-normal mt-3': true, 'white': !global.color_filter, 'black': global.color_filter,'medium-f': !global.dislex, 'dislex': global.dislex}">
                            millones</h5>
                        </div>
                        <p
                          [ngClass]="{'white': !global.color_filter, 'black': global.color_filter,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                          De colones en donaciones realizadas</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-4">
          <h2
            [ngClass]="{'ipad-alignment big-t title-line-height mt-3 mt-md-3 mt-lg-5': true, 'white': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
            <span>EN LOS ÚLTIMOS</span>
          </h2>
          <h3
            [ngClass]="{'huge-t ipad-alignment': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
            10 AÑOS</h3>
        </div>


        <div class="col-1 col-md-2 col-lg-3"></div>
        <div class="col-10 col-md-8 col-lg-6 text-center">
          <p
            [ngClass]="{'big-t mt-5': true, 'white': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
            CONOCE MÁS SOBRE NOSOTROS</p>
          <div class="row justify-content-center mb-5">
            <div class="col-12 col-md-6 d-grid">

              <a [routerLink]="['/sobreasembis']" href="/sobreasembis"
                [ngClass]="{'btn mb-4': true,'cursor': global.cursor_btn, 'btn-light': !global.color_filter, 'btn-dark': global.color_filter}">Conocer
                más
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="">
    <div class="container-fluid no-gutters">
      <div class="row no-gutters">
        <div class="col-12 bg-business no-gutters">
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row mt-5 mb-5">

        <div class="col-12 col-md-12 col-lg-6 d-flex border-end">
          <div class="card border-0 corners-rounded bg-gray mb-3" style="width: 90%; margin-left: 5%;">
            <div class="card-body">
              <div class="">
                <img class="mt-2 busisness-icon text-center" src="assets/img/icons/icon-business-green.png"
                  alt="Icono de servicios empresariales">
                <h2
                  [ngClass]="{'title-line-height mt-2 mb-4 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  CONOZCA NUESTROS<br><span
                    [ngClass]="{'turquesa': !global.color_filter, 'black': global.color_filter,'p-1': !global.dislex, 'p-0': global.dislex}">CONVENIOS
                    EMPRESARIALES</span>
                </h2>
              </div>

              <div class="row justify-content-center">
                <div class="col-10">
                  <p
                    [ngClass]="{'mb-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                    El principal objetivo de ASEMBIS es llevar salud a la mayor cantidad de personas.
                    Y qué mejor forma de hacerlo que a través de alianzas comerciales con empresas de todo tipo, las
                    cuales deseen trasladar el beneficio de la salud a todos sus colaboradores.
                    <br>Esta labor la podemos realizar estableciendo un convenio comercial con cada empresa o entidad
                    gubernamental en el cual se contemplen servicios que se ajusten a sus necesidades.
                    Son excelentes beneficios que le serán de gran interés
                  </p>

                  <div *ngIf="isDesktop" class="d-flex justify-content-center none-phone">
                    <!-- <div class="col-6 col-md-6 d-grid">
                       <a [routerLink]="['/convenios']"
                          [ngClass]="{'btn mb-5 me-3': true,'cursor': global.cursor_btn, 'btn-success': !global.color_filter, 'btn-dark': global.color_filter}">
                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-search me-1" viewBox="0 0 16 16">
                           <path
                             d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                         </svg>
                         Buscar mi empresa
                       </a>
                     </div>-->

                    <div class="col-6 d-grid">
                      <a [routerLink]="['/convenios']" href="/convenios"
                        [ngClass]="{'btn mb-5': true, 'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Leer
                        más
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                      </a>
                    </div>

                  </div>

                  <div *ngIf="isMobile" class="only-phone">
                    <!--<div class="col-12 d-grid">
                      <a [routerLink]="['/convenios']"
                         [ngClass]="{'btn mb-3 mt-2': true, 'cursor': global.cursor_btn, 'btn-success': !global.color_filter, 'btn-dark': global.color_filter}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-search me-1" viewBox="0 0 16 16">
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                        Buscar mi empresa
                      </a>
                    </div>-->

                    <div class="col-12 d-grid">
                      <a [routerLink]="['/convenios']" href="/convenios"
                        [ngClass]="{'btn mb-5': true, 'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Leer
                        más
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                      </a>
                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="col-12 col-md-12 col-lg-6 d-flex">
          <div class="card border-0 corners-rounded bg-gray mb-3" style="width: 90%; margin-left: 5%;">
            <div class="card-body">
              <div class="">
                <img class="mt-2 busisness-icon" src="assets/img/icons/icon-map-green.png" alt="Mapa de Costa Rica">
                <h2
                  [ngClass]="{'title-line-height mt-2 mb-4 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
                  CONOZCA SOBRE<br><span
                    [ngClass]="{'turquesa': !global.color_filter, 'black': global.color_filter, 'p-1': !global.dislex, 'p-0': global.dislex}">FRANQUICIAS
                    ASEMBIS </span>
                </h2>
              </div>

              <div class="row justify-content-center">
                <div class="col-10">
                  <p
                    [ngClass]="{'mb-3': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                    Los invitamos a conocer el modelo de franquicia de ASEMBIS. Invierta en un proyecto de vida con
                    bases
                    sólidas y con más de 30 años en el mercado. Conozca cómo impactar su comunidad y consolidar su
                    futuro
                    familiar en manos del líder en servicios de salud en Costa Rica. </p>

                  <div class="d-flex justify-content-center">
                    <div class="col-12 col-md-6 d-grid">
                      <a [routerLink]="['/convenios']" href="/convenios"
                        [ngClass]="{'btn mb-5': true, 'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Leer
                        más
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                      </a>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
    </div>


  </section>

  <section class="bg-texture">

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-5 mt-md-5 mb-md-2 mt-lg-5 mt-lg-5">
          <img class="green-logo mt-5 mt-md-1 mt-lg-5 mb-0 mb-md-1 mb-lg-5" *ngIf="!global.color_filter"
            src="assets/img/green-logo-new.png" alt="logo de ASEMBIS">
          <img class="green-logo mt-5 mt-md-1 mt-lg-5 mb-0 mb-md-1 mb-lg-5" *ngIf="global.color_filter"
            src="assets/img/logo-blackasembis.png" alt="logo de ASEMBIS">
        </div>

        <div *ngIf="isMobile" class="col-1 only-phone"></div>

        <div class="col-10 col-md-12 col-lg-7 mt-md-5 mb-md-2 mt-lg-3 mt-lg-5">
          <h2
            [ngClass]="{'bigger-t title-line-height mt-3 mt-md-1 mt-lg-5 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
            UNETE A NUESTRA FAMILIA</h2>
          <p
            [ngClass]="{'roboto': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
            Gracias por querer ser parte de la gran familia de ASEMBIS.</p>
          <p
            [ngClass]="{'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
            Con ingresar una vez tus datos en el formulario, ya estas participando oficialmente en nuestras ofertas de
            empleo, si deseas confirmar si contamos con tu información puedes llamarnos al (506) 2285-5881 con el equipo
            de Desarrollo Humano.</p>
          <div class="d-flex justify-content-star mb-5">
            <div class="col-12 col-md-5 d-grid">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfpfybssGOosw9B88qhCfxtR8k4qFOPSG4KZXKvcaTcefJ6BA/viewform"
                target="_blank"
                [ngClass]="{'btn mb-2': true, 'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Abrir
                formulario
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isDesktop" class="container-fluid none-phone">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div [ngClass]="{'card card-data mb-4 mb-md-2 mb-lg-4 mt-5 border-0': true, 'bg-blue': !global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="assets/img/icons/icon-data1.png" alt="Icono de salud"
                  class="img-fluid rounded-start mt-4 mb-lg-4 mb-xl-4" style="width: 60%; margin-left: 15%;">
              </div>
              <div class="col-md-8">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'text-normal mt-3  mt-md-4 mt-lg-4 mt-xl-3': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      +30 años</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    Sirviendole al pueblo de Costa Rica.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div [ngClass]="{'card card-data mb-4 mb-md-2 mb-lg-4 mt-5 border-0': true, 'bg-blue': !global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="assets/img/icons/icon-data2.png" alt="Icono de salud"
                  class="img-fluid rounded-start mt-4 mb-lg-4 mb-xl-4" style="width: 60%; margin-left: 15%;">
              </div>
              <div class="col-md-8">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'text-normal mt-3 mt-md-4 mt-lg-4 mt-xl-3': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      +38 especialidades</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    Puestas al servicio de todas las personas.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div [ngClass]="{'card card-data mb-4 mb-md-2 mb-lg-4 mt-5 border-0': true, 'bg-blue': !global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="assets/img/icons/icon-data3.png" alt="Icono de salud"
                  class="img-fluid rounded-start mt-4 mb-lg-4 mb-xl-4" style="width: 60%; margin-left: 15%;">
              </div>
              <div class="col-md-8">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'text-normal mt-3 mt-md-4 mt-lg-4 mt-xl-3': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      +30.000</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    Pacientes atendidos mensualmente.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div [ngClass]="{'card card-data mb-4 mb-md-2 mb-lg-4 mt-5 border-0': true, 'bg-blue': !global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-md-4">
                <img src="assets/img/icons/icon-data4.png" alt="Icono de salud"
                  class="img-fluid rounded-start mt-4 mb-lg-4 mb-xl-4" style="width: 60%; margin-left: 15%;">
              </div>
              <div class="col-md-8">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'text-normal  mt-3 mt-md-4 mt-lg-4 mt-xl-3': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      16 Clínicas</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter,'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    4 franquicias en todo <br>el país.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h2
            [ngClass]="{'bigger-t title-line-height text-center mt-4 mb-5': true, 'orange': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
            ¡LE ESPERAMOS EN
            <span [ngClass]="{'huge-t': true, 'blue': !global.color_filter, 'black': global.color_filter}">SU PRÓXIMA
              VISITA!</span>
          </h2>
        </div>
      </div>
    </div>

    <div *ngIf="isMobile" class="container-fluid only-phone">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div
            [ngClass]="{'card card-data mb-2 mt-5 border-0': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-3">
                <img src="assets/img/icons/icon-data1.png" alt="Icono de salud"
                  class="img-fluid rounded-start data-box-icon">
              </div>
              <div class="col-9">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'big-t mt-3 mb-0': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      +30 años</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    Sirviendole al pueblo de <br>Costa Rica.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div
            [ngClass]="{'card card-data mb-2 mt-2 border-0': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-3">
                <img src="assets/img/icons/icon-data2.png" alt="Icono de salud"
                  class="img-fluid rounded-start data-box-icon">
              </div>
              <div class="col-9">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'big-t mt-3 mb-0': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      +38 Especialidades</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    Puestas al servicio de todas las personas.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div
            [ngClass]="{'card card-data mb-2 mt-2 border-0': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-3">
                <img src="assets/img/icons/icon-data3.png" alt="Icono de salud"
                  class="img-fluid rounded-start data-box-icon">
              </div>
              <div class="col-9">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'big-t mt-3 mb-0': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      +30.000</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    Pacientes atendidos <br>mensualmente.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div
            [ngClass]="{'card card-data mb-2 mt-2 border-0': true, 'bg-blue': !global.color_filter, 'bg-gray': global.color_filter}"
            style="max-width: 90%; margin-left: 5%;">
            <div class="row g-0">
              <div class="col-3">
                <img src="assets/img/icons/icon-data4.png" alt="Icono de salud"
                  class="img-fluid rounded-start data-box-icon">
              </div>
              <div class="col-9">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-start">
                    <h5
                      [ngClass]="{'big-t mt-3 mb-0': true, 'white': !global.color_filter, 'black': global.color_filter,'heavy-f': !global.dislex, 'dislex': global.dislex}">
                      16 Clinicas</h5>
                  </div>
                  <p
                    [ngClass]="{'p-0': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex, 'sizetext': global.sizetext}">
                    4 franquicias en todo <br>el país.</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="isMobile" class="col-12 only-phone">
          <h2
            [ngClass]="{'bigger-t title-line-height text-center mt-4 mb-5': true, 'orange': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
            ¡LE ESPERAMOS EN
            <span [ngClass]="{'big-t': true, 'blue': !global.color_filter, 'black': global.color_filter}"><br>SU PRÓXIMA
              VISITA!</span>
          </h2>
        </div>
      </div>
    </div>

    <div class="">
      <img *ngIf="isDesktop" class="none-phone" src="assets/img/line-decor.png" alt="linea de decoración"
        style="width: 100%;">
      <img *ngIf="isMobile" class="only-phone" src="assets/img/decor-m.jpg" alt="linea de decoración"
        style="width: 100%;">
    </div>

  </section>

  <!--  <section>
      <button (click)="listarServicios()" type="button">Listar Servicios</button> <br>
      <select  [(ngModel)]="selectedServicio"  name="selectServicios" >
        <option *ngFor="let s of servicios | keyvalue"   [ngValue]="s">{{s.value.nombre}}</option>
      </select>
      <p *ngIf="selectedServicio">Observaciones: {{selectedServicio?.value.observaciones}}</p>
      <p *ngIf="selectedServicio">preparacion: {{selectedServicio?.value.preparacion}}</p>
      <p *ngIf="selectedServicio">Especialidad: {{selectedServicio?.value.especialidad}}</p>
      <p *ngIf="errorAgenda">Error: {{errorAgenda}}</p>

      <input *ngIf="selectedServicio" [(ngModel)]="dateSelected" name = "selectDate" type="date" [min]="tomorrow">

      <button *ngIf="selectedServicio" (click)="listarAgenda()" type="button">Seleccionar Lugar</button>

      <select *ngIf="places" [(ngModel)]="placeSelected"  (change)="debug()" name="selectPlace">
        <option *ngFor="let s of places | keyvalue" [ngValue]="s">{{s.key.split('-')[0]}}</option>
      </select>

      <select *ngIf="placeSelected != undefined" [(ngModel)]="agendaSelected"  name="selectAgenda">
        <option *ngFor="let s of placeSelected?.value | keyvalue" [ngValue]="s">{{s.key}}</option>
      </select>

      <p *ngIf="placeSelected">Observaciones: {{agendaSelected?.value.observaciones}}</p>
      <p *ngIf="placeSelected">preparacion: {{agendaSelected?.value.preparacion}}</p>
      <p *ngIf="placeSelected">tiempo_consulta: {{agendaSelected?.value.tiempo_consulta}}</p>

      <select *ngIf="agendaSelected" [(ngModel)]="citaSelected"  name="selectCita">
        <option *ngFor="let s of agendaSelected?.value.citas | keyvalue" [ngValue]="s.key">{{s.key}}</option>
      </select>

      <input *ngIf="citaSelected" [(ngModel)]="nombrePaciente"  type="text" name="fname" placeholder="Nombre"><br>
      <input *ngIf="citaSelected" [(ngModel)]="cedulaPaciente" type="text" name="fcedula" placeholder="Cédula"><br>
      <input *ngIf="citaSelected" [(ngModel)]="correoPaciente" type="text" name="fcorreo" placeholder="Correo"><br>
      <input *ngIf="citaSelected" [(ngModel)]="telefonoPaciente" type="text" name="ftelefono" placeholder="Teléfono"><br>
      <select *ngIf="citaSelected" [(ngModel)]="sexoPaciente"  name="fsexo">
        <option  value = "1">Mujer</option>
        <option  value = "2">Hombre</option>
      </select>

      <button *ngIf="citaSelected" (click)="agendarCita()" type="button">Agendar cita</button>

      <p *ngIf="errorCita"> {{errorCita}}</p>

      <p *ngIf="confirmacionCita">Cita agendada. Número de cita: {{confirmacionCita}}</p>




    </section>-->

  <!-- PopUp -->
  <div id="popupOverlay" class="popup-overlay" *ngIf="showPopup && savedPopUp?.encendido">
    <div id="popup" class="popup">
      <span class="close-btn" (click)="closePopup()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill"
          viewBox="0 0 16 16">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
        </svg>
      </span>
      <a [routerLink]="['/promo']" style="cursor: pointer;"> <img
          [src]="url+'popup/resources/thumbnails/'+savedPopUp?.popUpCoverImage" alt="Imagen de promoción"
          class="popup-image"></a>
      <a [routerLink]="['/promo']"><button class="promo-btn mb-3" (click)="verPromocion()">Ver Promoción <svg
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-arrow-right-circle-fill mb-1 ms-2" viewBox="0 0 16 16">
            <path
              d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
          </svg></button></a>
    </div>
  </div>





  <app-foot></app-foot>
</div>