<app-aside></app-aside>


<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-lg-9 pt-lg-4">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row d-flex justify-content-center">


              <div class="col-12">

                <div class="border-bottom mt-lg-2 pb-2 mb-5">
                  <h2 class="mt-lg-4"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                      class="bi bi-tags" style="color: #6610f2;" viewBox="0 0 16 16">
                      <path
                        d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
                      <path
                        d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
                    </svg> Editar promo PopUp</h2>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Rellene correctamente el formulario.</p>
                  </div>
                </div>
              </div>

              <div class="col-9">
                <div class="alert alert-danger my-3" role="alert" *ngIf="msm_error" (click)="close_alert()">
                  {{msm_error}}
                </div>
                <div class="alert alert-success my-3" role="alert" *ngIf="msm_success" (click)="close_alert()">
                  {{msm_success}}
                </div>

                <form #popUpForm="ngForm" (ngSubmit)="onSubmit(popUpForm)">
                  <div class="col-12 d-flex justify-content-center">
                    <div class="form-group">

                      <div class="form-check form-switch">
                        <input class="form-check-input" name="encendido" type="checkbox" id="flexSwitchCheckChecked" [(ngModel)]="savedPopUp.encendido">
                        <label class="form-check-label" for="flexSwitchCheckChecked">{{ savedPopUp.encendido ? 'Encendido' : 'Apagado' }}</label>
                      </div>
                      

                      <div class="col-12">
                        <div class="row mt-3 mb-5">
                          <label class="smalltext mt-4 " for="text-input">Imágen de portada desktop<span
                              style="color: red;">*</span></label>
                          <div class="col-12 border text-center pt-4">
                            <div class="">
                              <a (click)="vamos.click()" style="cursor: pointer;"><img class="mb-4 mt-4"
                                  [src]="imgSelect?.landingCoverImage || url+'popup/resources/thumbnails/'+savedPopUp?.landingCoverImage"
                                  style="width: 80%;"></a>
                            </div>


                            <input class="form-control mt-4 mb-3" type="file" id="landingCoverImage"
                              (change)="imgSelected($event, 'landingCoverImage')" accept="image/*" #vamos>
                            <p class="small text-muted mt-2 mb-3">Peso
                              requerido: Menos de 200kb</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <label class="smalltext mt-4 " for="mobileCoverImage">Imágen de portada Mobile<span
                              style="color: red;">*</span></label>
                          <div class="col-12 border text-center pt-4">
                            <div class="">
                              <a (click)="vamosmobile.click()" style="cursor: pointer;"><img class="mb-4 mt-4"
                                  [src]="imgSelect?.mobileCoverImage || url+'popup/resources/thumbnails/'+savedPopUp?.mobileCoverImage"
                                  style="width: 80%;"></a>
                            </div>
                            <input class="form-control mt-4 mb-3" type="file" id="mobileCoverImage"
                              (change)="imgSelected($event, 'mobileCoverImage')" accept="image/*" #vamosmobile>
                            <p class="small text-muted mt-2 mb-3">Peso
                              requerido: Menos de 60kb</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <label class="smalltext mt-4 " for="mobileCoverImage">Imágen del PopUp<span
                              style="color: red;">* <strong>Alto máximo de la imagen 700px</strong></span></label>
                          <div class="col-12 border text-center pt-4">
                            <div class="">
                              <a (click)="vamospopup.click()" style="cursor: pointer;"><img class="mb-4 mt-4"
                                  [src]="imgSelect?.popUpCoverImage || url+'popup/resources/thumbnails/'+savedPopUp?.popUpCoverImage"
                                  style="width: 80%;"></a>
                            </div>

                            <input class="form-control mt-4 mb-3" type="file" id="popUpCoverImage"
                              (change)="imgSelected($event, 'popUpCoverImage')" accept="image/*" #vamospopup>
                            <p class="small text-muted mt-2 mb-3"><strong>Alto máximo de la imagen 700px</strong> - Peso requerido: Menos de 100kb</p>
                          </div>

                        </div>
                      </div>

                      <label for="textSection1" class="form-label mt-4">Campo de información 1</label>
                      <textarea class="form-control" id="textSection1" name="textSection1" [(ngModel)]="savedPopUp.textSection1"
                        rows="3"></textarea>

                      <label for="textSection2" class="form-label mt-4">Campo de información 2</label>
                      <textarea class="form-control" id="textSection2" name="textSection2" [(ngModel)]="savedPopUp.textSection2"
                        rows="3"></textarea>

                      <label for="textSection3" class="form-label mt-4">Campo de información 3</label>
                      <textarea class="form-control" id="textSection3" name="textSection3" [(ngModel)]="savedPopUp.textSection3"
                        rows="3"></textarea>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary mt-5 mb-5">Actualizar</button>
                </form></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>