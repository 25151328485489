import { Injectable } from '@angular/core';
import { Observable, from, map, switchMap } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  public url;

  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
  }

  listar(filtro):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/'+filtro,{headers:headers})
  }
  find_by_slug(slug: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this._http.get<any>(`${this.url}article/slug/${slug}`, { headers: headers }).pipe(
      switchMap(response => {
        // Assuming the response.content is the Base64 encoded field
        // Adjust the response field as per your API response structure
        return from(this.decodeBase64(response.article.body)).pipe(
          map(decodedContent => {
            response.article.body = decodedContent;
            return response;  // Return the modified response with the decoded content
          })
        );
      })
    );
  }

  findRandom():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/random',{headers:headers})
  }

  listarLatest():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/latest',{headers:headers})
  }

  list_one(id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this._http.get<any>(`${this.url}article/one/${id}`, { headers: headers }).pipe(
      switchMap(response => {
        // Assuming the response.body is the Base64 encoded field
        // Check your API response structure and adjust accordingly
        return from(this.decodeBase64(response.article.body)).pipe(
          map(decodedBody => {
            response.article.body = decodedBody;
            return response;  // Return the modified response with the decoded body
          })
        );
      })
    );
  }

  eliminar(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url + 'article/'+id,{headers:headers})
  }

  update(data): Observable<any> {
    return from(this.encodeArticleBody(data.body)).pipe(
      switchMap(encodedBody => {
        const fd = new FormData();
        fd.append('nombre', data.nombre);
        fd.append('body', encodedBody);  // Use the encoded body
        fd.append('banner', data.banner);
        fd.append('mobile_banner', data.mobile_banner);
  
        return this._http.put(this.url + 'article/' + data._id, fd);
      })
    );
  }

  registro(data: any) {
    return from(this.encodeArticleBody(data. body)).pipe(
      switchMap( body => {
        const fd = new FormData();
        fd.append('nombre', data.nombre);
        fd.append('body',  body); // Use the resolved string here
        fd.append('banner', data.banner);
        fd.append('mobile_banner', data.mobile_banner);

        // Make the POST request
        return this._http.post(this.url + 'article/registro', fd);
      })
    );
  }

  private encodeArticleBody( body: string): Promise<string> {
    const encoder = new TextEncoder();
    const encoded = encoder.encode( body);  // Encode text as UTF-8
    const blob = new Blob([encoded], { type: 'application/octet-stream' });
  
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data.split(',')[1]);  // Extract Base64 data from the result
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private decodeBase64(base64String: string): Promise<string> {
    return new Promise((resolve, reject) => {
        // Convert the Base64 string to a binary string
        const binaryString = window.atob(base64String);

        // Convert binary string to an array of characters and then to an array of bytes
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the byte array
        const blob = new Blob([bytes], { type: 'application/octet-stream' });

        // Read the Blob as text using FileReader
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = () => {
            reject(new Error('Failed to read the blob as text.'));
        };
        reader.readAsText(blob, 'UTF-8');  // Specify the encoding if necessary
    });
  }
  
}
