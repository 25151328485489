<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-planes.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          SALUD A SU ALCANCE</h1>
      </div>
    </div>
  </div>
</section>

<!--<section>
  <div class="container mb-5">
    <div class="row">
      <div class="col-12 mb-4">
        <h2 [ngClass]="{'bigger-t mt-5 text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">PLANES DE SALUD ASEMBIS</h2>
        <p [ngClass]="{'text-center': true, 'text-muted': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">Haz clic en el dato que quieres leer
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
            <path
              d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
          </svg>
        </p>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="accordion mb-4" id="accordion1">
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading1">
              <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-shield-check me-2 yellow" viewBox="0 0 16 16">
                  <path
                    d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                BENEFICIOS DEL PLAN
              </button>
            </h2>
            <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1"
                 data-bs-parent="#accordion1">
              <div class="accordion-body">
                <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element. These classes control the overall
                  appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                  custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                  within the <code>.accordion-body</code>, though the transition does limit overflow.
                </p>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="accordion mb-4" id="accordion2">

          <div class="accordion-item">
            <h2 class="accordion-header" id="heading2">
              <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-shield-check me-2 yellow cursor-prueba" viewBox="0 0 16 16">
                  <path
                    d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                NUESTRA RED MÉDICA
              </button>
            </h2>
            <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2"
                 data-bs-parent="#accordion2">
              <div class="accordion-body">
                <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element. These classes control the overall
                  appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                  custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                  within the <code>.accordion-body</code>, though the transition does limit overflow.
                </p>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="accordion mb-4" id="accordion3">

          <div class="accordion-item">
            <h2 class="accordion-header" id="heading3">
              <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-shield-check me-2 yellow" viewBox="0 0 16 16">
                  <path
                    d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                SOBRE LAS CONSULTAS
              </button>
            </h2>
            <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3"
                 data-bs-parent="#accordion3">
              <div class="accordion-body">
                <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element. These classes control the overall
                  appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                  custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                  within the <code>.accordion-body</code>, though the transition does limit overflow.
                </p>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="accordion mb-4" id="accordion4">

          <div class="accordion-item">
            <h2 class="accordion-header" id="heading4">
              <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-shield-check me-2 yellow" viewBox="0 0 16 16">
                  <path
                    d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                OTRO DATO
              </button>
            </h2>
            <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4"
                 data-bs-parent="#accordion4">
              <div class="accordion-body">
                <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element. These classes control the overall
                  appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                  custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                  within the <code>.accordion-body</code>, though the transition does limit overflow.
                </p>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="accordion mb-4" id="accordion5">

          <div class="accordion-item">
            <h2 class="accordion-header" id="heading5">
              <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-shield-check me-2 yellow" viewBox="0 0 16 16">
                  <path
                    d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                OTRO DATO
              </button>
            </h2>
            <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5"
                 data-bs-parent="#accordion5">
              <div class="accordion-body">
                <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element. These classes control the overall
                  appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                  custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                  within the <code>.accordion-body</code>, though the transition does limit overflow.
                </p>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="accordion mb-4" id="accordion6">

          <div class="accordion-item">
            <h2 class="accordion-header" id="heading6">
              <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-shield-check me-2 yellow" viewBox="0 0 16 16">
                  <path
                    d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                  <path
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                OTRO DATO
              </button>
            </h2>
            <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6"
                 data-bs-parent="#accordion6">
              <div class="accordion-body">
                <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                  plugin adds the appropriate classes that we use to style each element. These classes control the overall
                  appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                  custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                  within the <code>.accordion-body</code>, though the transition does limit overflow.
                </p>

              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</section>

<section class="" style="background-color: #efefef;">
  <div class="container-fluid no-gutters">
    <div class="row no-gutters">
      <div class="col-12 col-md-12 col-lg-4 bg-doctor no-gutters"></div>

      <div class="col-12 col-md-12 col-lg-8 no-gutters">
        <h3 class="" [ngClass]="{'big-t mt-5 text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">ESTOS SON NUESTROS PLANES</h3>
        <p [ngClass]="{'text-center': true, 'text-muted': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">Haz clic en el plan que quieres leer
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
            <path
              d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
          </svg>
        </p>

        <div class="container-fluid mb-5">
          <div class="row">

            <div class="col-12 col-md-6">
              <div class="accordion mb-4" id="plan1">
                <div class="accordion-item border-plan">
                  <h2 class="accordion-header " id="headingplan1">
                    <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseplan1" aria-expanded="false"
                            aria-controls="collapseplan1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                           class="bi bi-check2-circle yellow me-2" viewBox="0 0 16 16">
                        <path
                          d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path
                          d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                      </svg>
                      PLAN NOMBRE
                    </button>
                  </h2>
                  <div id="collapseplan1" class="accordion-collapse collapse" aria-labelledby="headingplan1"
                       data-bs-parent="#plan1">
                    <div class="accordion-body">
                      <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                        plugin adds the appropriate classes that we use to style each element. These classes control the overall
                        appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                        custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                        within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="accordion mb-4" id="plan2">
                <div class="accordion-item border-plan">
                  <h2 class="accordion-header" id="headingplan2">
                    <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseplan2" aria-expanded="false"
                            aria-controls="collapseplan2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                           class="bi bi-check2-circle yellow me-2" viewBox="0 0 16 16">
                        <path
                          d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path
                          d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                      </svg>
                      PLAN NOMBRE
                    </button>
                  </h2>
                  <div id="collapseplan2" class="accordion-collapse collapse" aria-labelledby="headingplan2"
                       data-bs-parent="#plan2">
                    <div class="accordion-body">
                      <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                        plugin adds the appropriate classes that we use to style each element. These classes control the overall
                        appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                        custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                        within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="accordion mb-4" id="plan3">
                <div class="accordion-item border-plan">
                  <h2 class="accordion-header" id="headingplan3">
                    <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseplan3" aria-expanded="false"
                            aria-controls="collapseplan3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                           class="bi bi-check2-circle yellow me-2" viewBox="0 0 16 16">
                        <path
                          d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path
                          d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                      </svg>
                      PLAN NOMBRE
                    </button>
                  </h2>
                  <div id="collapseplan3" class="accordion-collapse collapse" aria-labelledby="headingplan3"
                       data-bs-parent="#plan3">
                    <div class="accordion-body">
                      <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                        plugin adds the appropriate classes that we use to style each element. These classes control the overall
                        appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                        custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                        within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="accordion mb-4" id="plan4">
                <div class="accordion-item border-plan">
                  <h2 class="accordion-header" id="headingplan4">
                    <button [ngClass]="{'accordion-button collapsed bg-gray': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}" type="button"
                            data-bs-toggle="collapse" data-bs-target="#collapseplan4" aria-expanded="false"
                            aria-controls="collapseplan4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                           class="bi bi-check2-circle yellow me-2" viewBox="0 0 16 16">
                        <path
                          d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                        <path
                          d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                      </svg>
                      PLAN NOMBRE
                    </button>
                  </h2>
                  <div id="collapseplan4" class="accordion-collapse collapse" aria-labelledby="headingplan4"
                       data-bs-parent="#plan4">
                    <div class="accordion-body">
                      <p [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse
                        plugin adds the appropriate classes that we use to style each element. These classes control the overall
                        appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                        custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                        within the <code>.accordion-body</code>, though the transition does limit overflow.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-12 col-md-4 mt-3">
              <img class="icon-plan" src="assets/img/icons/icon-plan-1.png">
              <h5 [ngClass]="{'text-normal p-0': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">PERSONAS ASEGURADAS</h5>
              <p [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">Lorem ipsum dolor sit amet, consectetuer adipiscing.</p>
            </div>

            <div class="col-12 col-md-4 mt-3">
              <img class="icon-plan" src="assets/img/icons/icon-plan-3.png">
              <h5 [ngClass]="{'text-normal p-0': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">ESPECIALIDADES MÉDICAS</h5>
              <p [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">Lorem ipsum dolor sit amet, consectetuer adipiscing.</p>
            </div>

            <div class="col-12 col-md-4 mt-3">
              <img class="icon-plan" src="assets/img/icons/icon-plan-2.png">
              <h5 [ngClass]="{'text-normal p-0': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">CITAS ILIMITADAS</h5>
              <p [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">Lorem ipsum dolor sit amet, consectetuer adipiscing.</p>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</section>-->

<section>
  <div class="container">
    <div class="row">


      <div class="col-md-6 none-phone">
        <img class="mt-5" src="assets/img/logo-asembisdr.png" style="width: 30%; margin-left: 35%;">
        <h3
          [ngClass]="{'bigger-t text-center mb-3': true, 'blue': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ASEMBIS LLEGA A <span
          [ngClass]="{'huge-t': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">SU HOGAR</span>
        </h3>
        <p
          [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          <strong>Dr ASEMBIS A DOMICILIO</strong> brinda una consulta que se extiende hasta por 30 minutos. Es
          importante
          recordar que el médico se puede retrasar debido al tráfico o por un paciente previo. Como seguridad para
          nuestros pacientes, nuestros médicos se encuentran incorporados al colegio de Médicos y debidamente
          identificados.</p>

        <p
          [ngClass]="{'green mb-5': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
               class="bi bi-calendar3 mb-2 me-2" viewBox="0 0 16 16">
            <path
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
            <path
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg>
          De lunes a viernes: 8:30am a 6:00pm y sábados de 8:30am a 2 pm
        </p>

      </div>

      <div class="col-12 only-phone">
        <img class="mt-5" src="assets/img/logo-asembisdr.png" style="width: 50%; margin-left: 25%;">
        <h3
          [ngClass]="{'huge-t text-center mb-3': true, 'blue': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ASEMBIS LLEGA A <span
          [ngClass]="{'huge-t': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"><br>SU HOGAR</span>
        </h3>
        <p
          [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          <strong>Dr ASEMBIS A DOMICILIO</strong> brinda una consulta que se extiende hasta por 30 minutos. Es
          importante
          recordar que el médico se puede retrasar debido al tráfico o por un paciente previo. Como seguridad para
          nuestros pacientes, nuestros médicos se encuentran incorporados al colegio de Médicos y debidamente
          identificados.</p>

        <p
          [ngClass]="{'green mb-5': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
               class="bi bi-calendar3 mb-2 me-2" viewBox="0 0 16 16">
            <path
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
            <path
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg>
          De lunes a viernes: 8:30am a 6:00pm y sábados de 8:30am a 2 pm
        </p>

      </div>

      <div class="col-12 col-md-6">
        <img class="mt-1 mb-5 mt-md-5 mb-md-5" src="assets/img/domicilio.jpg" style="width: 100%;">
      </div>

      <!--      <div class="col-12 col-md-4">
              <img class="mt-3 mb-1 mb-md-5" src="assets/img/logo-asembisdr.png" style="width: 80%; margin-left: 10%;">
            </div>

            <div class="col-12 col-md-8">
              <h3
                [ngClass]="{'big-t mt-2 mt-md-5': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                Dr. ASEMBIS A DOMICILIO</h3>
              <p
                [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Brindamos un consulta que se extiende hasta por 30 minutos. Es importante
                recordar que el médico se puede retrasar debido al tráfico o por un paciente previo. Como seguridad para
                nuestros pacientes, nuestros médicos se encuentran incorporados al colegio de Médicos y debidamente
                identificados.</p>
              <p
                [ngClass]="{'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     class="bi bi-calendar3 mb-2 me-2" viewBox="0 0 16 16">
                  <path
                    d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                  <path
                    d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
                De lunes a viernes: 8:30am a 6:00pm y sábados de 8:30am a 2 pm
              </p>
            </div>-->

      <div class="col-1"></div>

      <div class="col-10 corners-rounded bg-gray mb-5">

        <div class="container">
          <div class="row">
            <div class="col-12">
              <h5
                [ngClass]="{'big-t text-center mt-4 mb-3': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                     class="bi bi-check-circle-fill mb-2 me-2" viewBox="0 0 16 16">
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                ATENDEMOS
              </h5>
            </div>

            <div class="col-md-6 none-phone">
              <ul class="align-bigscreens" style="">
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Dolor de oído, cabeza, garganta y estómago
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Resfríos, gripe y tos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Diarreas y vómito
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Fiebre y dolor corporal
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Dolor de espalda
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Laboratorio clínico (gratis servicio de trasporte)
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Peso y talla
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Control de enfermedades crónicas (diabetes,
                  hipertensión arterial,colesterol)
                </li>
              </ul>
            </div>

            <div class="col-md-6 none-phone">
              <ul class="align-bigscreens" style="">
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Sutura de heridas pequeñas (aprox. 5cm)
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Lavado de oídos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Cauterización de lunares
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Certificados médicos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Dictámenes de licencia
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Drenaje de abscesos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Alergias
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
                    <path
                      d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                  </svg>
                  Inyectable bajo receta médica o previa evaluación médica
                </li>
              </ul>
            </div>

            <div class="col-12 only-phone">
              <ul class="" style="">
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Dolor de oído, cabeza, garganta y estómago
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Resfríos, gripe y tos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Diarreas y vómito
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Fiebre y dolor corporal
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Dolor de espalda
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Laboratorio clínico (gratis servicio de trasporte)
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Peso y talla
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Control de enfermedades crónicas (diabetes,
                  hipertensión arterial,colesterol)
                </li>
              </ul>
            </div>

            <div class="col-12 only-phone">
              <ul class="" style="">
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Sutura de heridas pequeñas (aprox. 5cm)
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Lavado de oídos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Cauterización de lunares
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Certificados médicos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Dictámenes de licencia
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Drenaje de abscesos
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Alergias
                </li>
                <li [ngClass]="{'dislex': global.dislex,'sizetext': global.sizetext}">
                  Inyectable bajo receta médica o previa evaluación médica
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<!--<section>
  <div class="container mt-4 mb-5">
    <div class="row">
      <div class="col-12 col-md-4">
        <img class="mt-3 mt-md-4" src="assets/img/logo-ekono.png" style="width: 80%; margin-left: 10%;">
      </div>

      <div class="col-12 col-md-8">
        <h5
          [ngClass]="{'big-t p-2 p-md-0 mt-2 mt-md-5': true, 'blue': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          CRÉDITOS DE SALUD ASEMBIS MEDIANTE PLATAFORMA EKONO CRÉDITO</h5>
        <p
          [ngClass]="{'p-2 p-md-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Para brindarles un servicio de calidad y que más personas tengan acceso a la salud, hemos implementado los
          créditos Ekono. Comunícate con nosotros para obtener toda la información al respecto. </p>

        <div class="d-flex justify-content-start">
          <div class="col-5 d-grid">
            <a href="https://www.tiendasekono.com/credi-e" target="_blank"
               [ngClass]="{'btn ps-2 ps-md-0 mb-5': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Conocer
              más
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>-->

<section class="none-phone">
  <div
    [ngClass]="{'container corners-rounded mb-5 mt-5': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-2">
        <img class="mt-4 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">
      </div>

      <div class="col-12 col-md-8 col-lg-4">
        <h3
          [ngClass]="{'big-t mt-4 mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>
        <div class="d-flex justify-content-start mb-3">
          <a class="text-decoration-none me-4" style="cursor: pointer;"
             href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-small': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-instagram me-1 mb-1" viewBox="0 0 16 16">
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
              </svg>
              clínicas_asembis
            </h6>
          </a>

          <a class="text-decoration-none" style="cursor: pointer;" href="https://www.facebook.com/Asembis/"
             target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-small': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-facebook me-1 mb-1"
                   viewBox="0 0 16 16">
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg>
              Clínicas Asembis
            </h6>
          </a>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                TELÉFONOS
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Llamadas: +506 2285 5881</p>
              <p
                [ngClass]="{'ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Whatsapp: +506 8814 5881</p>
            </div>
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
                  <path
                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                </svg>
                CORREO
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                contacto@asembis.org</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="only-phone">
  <div class="container mb-5 mt-5">
    <div class="row d-flex justify-content-center">

      <div
        [ngClass]="{'col-9 corners-rounded': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
        <img class="mt-5 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">

        <h3
          [ngClass]="{'big-t mt-4 text-center mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>

        <div class="d-flex justify-content-center mb-5 mt-5">
          <a
            [ngClass]="{'text-decoration-none me-4': true, 'white': !global.color_filter, 'black': global.color_filter}"
            style="cursor: pointer;" href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 class="bi bi-instagram me-3" viewBox="0 0 16 16">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
          </a>

          <a [ngClass]="{'text-decoration-none': true, 'white': !global.color_filter, 'black': global.color_filter}"
             style="cursor: pointer;" href="https://www.facebook.com/Asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 class="bi bi-facebook ms-3"
                 viewBox="0 0 16 16">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
          </a>
        </div>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>
          TELÉFONOS
        </h5>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Llamadas: +506 2285 5881</p>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Whatsapp: +506 8814 5881</p>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
            <path
              d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
          </svg>
          CORREOS
        </h5>
        <p
          [ngClass]="{'text-center mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          contacto@asembis.org</p>


      </div>

    </div>
  </div>
</section>

<app-foot></app-foot>
