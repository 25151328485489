<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">
<div class="container pb-5">
  <div class="bg-light box-shadow-lg corners-rounded">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-3">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-9 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">

            <div class="toast-container toast-bottom-right">
              <div class="toast mb-3 fade hide" id="dark-toast" data-delay="5000" role="alert" aria-live="assertive"
                   aria-atomic="true">
                <div class="toast-header bg-accent text-white"><i class="czi-time mr-2"></i>
                  <h6 class="font-size-sm text-white mb-0 mr-auto">Notificación</h6>
                  <button class="close text-white ml-2 mb-1" type="button" data-dismiss="toast" aria-label="Close"
                          (click)="close_toast()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="toast-body text-accent">Se eliminó corractamente el registro.</div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2 mb-4">
                <h1 class="mt-lg-4">Galería del producto</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Tamaño recomendado: 500px X 660px - Peso recomendado: 80kb</p>
                  <div class="btn-group" role="group" aria-label="Solid button group">
                    <a [routerLink]="['/admin/productos']" class="btn btn-dark btn-sm" style="    padding-top: 10px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-arrow-90deg-left me-1" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
                      </svg>
                      Regresar</a>
                    <button class="btn btn-primary btn-sm" (click)="openNuevasImageneesModal()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-image me-1" viewBox="0 0 16 16">
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        <path
                          d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                      </svg>
                      Nuevas imagenes
                    </button>

                  </div>
                </div>




                <div class="modal fade" id="modal_adding" tabindex="-1" role="dialog" style="display: none;" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">

                  <form #galeriaForm="ngForm" (ngSubmit)="onSubmit(galeriaForm)">
                    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px;">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title">Subir imagenes</h4>
                          <button class="btn-close" type="button" (click)="closeModalBtn()"></button>
                        </div>
                        <div class="modal-body">
                          <div *ngIf="msm_error" class="alert alert-danger alert-dismissible fade show" role="alert">
                            <span class="font-weight-medium">Error!:</span> Por favor ingrese al menos dos imagenes.
                            <button type="button" (click)="close_alert()" class="close" data-dismiss="alert"
                                    aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
                            <ngx-dropzone-label>
                              <div>
                                <h2>Arrastra las imagenes aquí</h2>
                              </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                                                        [file]="f" [removable]="true" (removed)="onRemove(f)">

                            </ngx-dropzone-image-preview>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-secondary btn-sm" type="button" (click)="closeModalBtn()">Cancelar</button>
                          <button class="btn btn-primary btn-shadow btn-sm" type="submit">Subir</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>

              <div class="row">
                <div class="col-lg-12" *ngIf="imagenes.length == '0'">
                  <div class="alert alert-dark alert-with-icon" role="alert">
                    <div class="alert-icon-box">
                      <i class="alert-icon czi-security-announcement"></i>
                    </div>
                    No hay ninguna imagenen en este producto, <a [routerLink]="['/admin/productos']" class="alert-link">regrasar
                    al inicio.</a>.
                  </div>
                </div>


                <div class="col-lg-4 mb-4"
                     *ngFor="let item of imagenes | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                  <div class="card">
                    <img [src]="url+'/galeria/resources/thumbnails/'+item.imagen" style="width: 100%;">
                    <button class="btn btn-warning btn-sm" style="border-radius: 0px;" data-toggle="modal"
                            [attr.data-target]="'#modal-'+item._id">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-trash" viewBox="0 0 16 16">
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg>
                      Eliminar
                    </button>
                  </div>

                  <div class="modal fade" [attr.id]="'modal-'+item._id" tabindex="-1" role="dialog"
                       style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title">Confirmación</h4>
                          <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p class="font-size-sm">¿Desea eliminar definitivamente el regsitro?</p>
                          <span class="font-size-sm text-muted"><b>Código:</b>- {{item._id}}</span>
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                          <button class="btn btn-primary btn-shadow btn-sm" type="button" (click)="eliminar(item._id)">
                            Suprimir
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mt-4">

                  <ngb-pagination
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [collectionSize]="count_img"></ngb-pagination>

                </div>
              </div>
            </div>


          </div>

        </div>
      </section>
    </div>
  </div>
</div>
</section>
