import { Component, OnInit } from '@angular/core';
import {EspedashboardService} from '../../services/espedashboard.service';
import {AreacatService} from '../../services/areacat.service';
import {GLOBAL} from "../../services/GLOBAL";
import {UserService} from "../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Espedashboard} from "../../models/Espedashboard";

@Component({
  selector: 'app-especialidades',
  templateUrl: './especialidades.component.html',
  styleUrls: ['./especialidades.component.css'],
  providers: [{ provide: Window, useValue: window }]

})
export class EspecialidadesComponent implements OnInit {


  public global = GLOBAL;
  public identity;
  public especialidades: Array<any> = [];
  public areacats: Array<any> = [];
  public especialidades_show: Array<any> = [];
  public toggle : Array<boolean> = [];
  public areaID = '';
  public areaname = '';
  public especilidad_show = false;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _espedashboardService: EspedashboardService,
    private _areacatService: AreacatService,
    private window: Window

  ){
    this.identity = this._userService.getIdentity();
  }


  ngOnInit(): void {
    this.initData();
  }


  initData() {
    this._espedashboardService.listar().subscribe(
      response => {
        this.especialidades = response.espedashboard;
        this.populate_especialidades_toshow (this.areaID, this.areaname, null);
      },
      error => {

      }
    );
    this._areacatService.listar().subscribe(
      response_area => {
        this.areacats = response_area.areacat;
      },
      error => {

      }
    );

  }

  populate_especialidades_toshow (area_id, name, el: HTMLElement ){
    this.areaID = area_id;
    this.areaname = name;
    this.especialidades_show = []
    if (el != null) {
      el.scrollIntoView({behavior: 'smooth'});
    }
    for (var especialidad of this.especialidades){
      if (especialidad.area._id == area_id){
        this.especialidades_show.push(especialidad)
      }
    }
    if (this.areaID != '') {
      this.especilidad_show = true;
    }


  }

  go_back() {
    this.especilidad_show = false;
  }

}
