<app-aside></app-aside>


<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>


<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-lg-9 pt-lg-4">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">


              <div class="col-lg-12">
                <div class="border-bottom mt-lg-2 pb-2">
                  <h2 class="mt-lg-4"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                      class="bi bi-geo-alt mb-1" style="color: #6610f2;" viewBox="0 0 16 16">
                      <path
                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg> Sucursales</h2>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Cree, edite y elimine las sucursales que se mostrarán en su página.</p>
                  </div>
                </div>
              </div>

              <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show"
                role="alert">
                {{msm_success}}
                <button type="button" class="btn-close btn-close-dark me-2 m-auto" data-dismiss="alert"
                  aria-label="Close"></button>
              </div>

              <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show"
                role="alert">
                {{msm_error}}
                <button type="button" class="btn-close btn-close-dark me-2 m-auto" data-dismiss="alert"
                  aria-label="Close"></button>
              </div>


              <div class="col-lg-12">
                <form #locationForm="ngForm" (ngSubmit)="onSubmit(locationForm)">
                  <div class="card border-0 box-shadow">
                    <div class="card-body">
                      <div class="tab-content" style="margin-top: 20px;">
                        <div class="tab-pane fade active show">
                          <div class="form-group row align-items-center">

                            <div class="col-12">
                              <h5>Seleccione la provincia a la que pertenece la sucursal <span
                                  style="color: red;">*</span></h5>
                              <select class="form-select form-select-lg mb-4" aria-label="Default select example"
                                name="provincia" [(ngModel)]="location.provincia" id="select-input">
                                <option selected disabled value="">Seleccione una provincia:</option>

                                <option *ngFor="let item of provincias" [value]="item._id">{{item.name}}</option>
                              </select>
                            </div>

                            <div class="col-md-5">

                              <h5 class="smalltext">Nombre de sucursal <span style="color: red;">*</span></h5>
                              <input class="form-control" type="text" placeholder="Agregar nombre" name="name"
                                maxlength="50" [(ngModel)]="location.name">
                            </div>

                            <div class="col-md-4">
                              <h5 class="smalltext">Correo <span style="color: red;">*</span></h5>
                              <input class="form-control" type="text" placeholder="Correo" name="mail" maxlength="50"
                                [(ngModel)]="location.mail">
                            </div>

                            <div class="col-md-3">
                              <h5 class="smalltext">Número de teléfono</h5>
                              <input class="form-control" type="text" placeholder="Teléfono" name="phone" maxlength="50"
                                [(ngModel)]="location.phone">
                            </div>


                          </div>
                          <div class="form-group row align-items-center">

                            <div class="col-md-6">
                              <h6 class="smalltext mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                  class="bi bi-calendar2-week" style="color: #6610f2;" viewBox="0 0 16 16">
                                  <path
                                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                                  <path
                                    d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                </svg>
                                Horario de lunes a viernes <span style="color: red;">*</span>
                              </h6>
                              <input class="form-control mb-3 " type="text" placeholder="Agregar horario" name="weekday"
                                maxlength="50" [(ngModel)]="location.weekday">
                            </div>

                            <div class="col-md-6">
                              <h6 class="smalltext  mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                  class="bi bi-calendar2-week-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                                  <path
                                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM8.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM3 10.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                                </svg>
                                Horario sábados <span style="color: red;">*</span>
                              </h6>
                              <input class="form-control mb-3" type="text" placeholder="Agregar horario" name="saturday"
                                maxlength="50" [(ngModel)]="location.saturday">
                            </div>

                            <div class="col-md-6">
                              <h6 class="smalltext">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                  class="bi bi-calendar2-week-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                                  <path
                                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM8.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM3 10.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                                </svg>
                                Horario domingo
                              </h6>
                              <input class="form-control mb-3" type="text" placeholder="Agregar horario" name="sunday"
                                maxlength="50" [(ngModel)]="location.sunday">
                            </div>

                            <div class="col-md-6">
                              <h5 class="smalltext mt-3">Dirección escrita <span style="color: red;">*</span></h5>
                              <textarea class="form-control" id="textarea-input" rows="4"
                                placeholder="Agregar ubicación escrita" name="located"
                                [(ngModel)]="location.located"></textarea>
                            </div>

                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <h6 class="smalltext  mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                      class="bi bi-geo-alt" style="color: #6610f2;" viewBox="0 0 16 16">
                                      <path
                                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>
                                    Link de WAZE
                                  </h6>
                                  <input class="form-control mb-3" type="text" placeholder="Link de waze" name="waze"
                                    maxlength="50" [(ngModel)]="location.waze">
                                </div>
                                <div class="col-md-6">
                                  <h6 class="smalltext mt-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                      class="bi bi-geo-alt-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                                      <path
                                        d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                    </svg>
                                    Link de GOOGLE MAPS
                                  </h6>
                                  <input class="form-control mb-3" type="text" placeholder="link de google maps"
                                    name="maps" maxlength="50" [(ngModel)]="location.maps">
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="">
                          <button class="btn btn-success mt-2 mb-3 btn-lg mr-1" type="submit">Añadir sucursal</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              <div class="col-12">
                <h4 class="mt-5 mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-info-circle-fill mb-2" style="color: #0D6EFD;" viewBox="0 0 16 16">
                    <path
                      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                  </svg>
                  A continuacion encontrara todas las sucursales agregadas:
                </h4>
              </div>

              <div class="col-lg-6 mt-3 mb-3" *ngFor="let item of locations">
                <div class="card" *ngIf="locations?.length > 0">
                  <div class="card-header">
                    <h4 class="product-title hugetext" style="margin-bottom: 0px;">
                      <a>{{item?.name}}</a>
                    </h4>

                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="media-body pt-2">

                          <h6 class="mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-geo-alt-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                              <path
                                d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                            </svg>
                            Dirección escrita: {{item?.located}}, {{item?.provincia.name}}
                          </h6>


                          <h6 class="mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                              class="bi bi-calendar2-week" style="color: #6610f2;" viewBox="0 0 16 16">
                              <path
                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                              <path
                                d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                            </svg>
                            Horario de lunes a viernes: {{item?.weekday}}
                          </h6>


                          <h6 class="mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                              class="bi bi-calendar2-week-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                              <path
                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM8.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM3 10.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                            </svg>
                            Horario sábado: {{item?.saturday}}
                          </h6>

                          <h6 class="mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                              class="bi bi-calendar2-week-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                              <path
                                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM8.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM3 10.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                            </svg>
                            Horario domingo: {{item?.sunday}}
                          </h6>

                          <h6 class="mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-envelope" style="color: #6610f2;" viewBox="0 0 16 16">
                              <path
                                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                            </svg>
                            Correo: {{item?.mail}}
                          </h6>

                          <h6 class="mt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-telephone-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            Tel: {{item?.phone}}
                          </h6>


                          <a href="{{item?.waze}}" target="_blank" *ngIf="item?.waze">
                            <button type="button" class="btn btn-outline-dark me-4 mt-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-geo-alt-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg>
                              Waze
                            </button>
                          </a>
                          <a href="{{item?.maps}}" target="_blank" *ngIf="item?.maps">
                            <button type="button" class="btn btn-outline-dark mt-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-geo-alt-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                                <path
                                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg>
                              Google Maps
                            </button>
                          </a>

                        </div>


                      </div>
                    </div>

                    <div class="col-lg-12 mt-4 bg-gray d-flex justify-content-center corners-rounded">

                      <a data-toggle="modal" [attr.data-target]="'#editlocation-'+item._id"
                        class="btn btn-primary mt-3 mb-3 me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                        Editar información
                      </a>

                      <a data-toggle="modal" [attr.data-target]="'#modal-'+item._id"
                        class="btn btn-dark btn-icon mt-3 mb-3 ms-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash" viewBox="0 0 16 16">
                          <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                        Eliminar sucursal
                      </a>

                    </div>
                  </div>

                  <div class="modal fade" [attr.id]="'modal-'+item._id" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Confirmación</h5>
                          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p class="font-size-sm">¿Desea eliminar definitivamente esta sucursal?</p>
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar
                          </button>
                          <button class="btn btn-primary btn-shadow btn-sm" type="button" (click)="eliminar(item._id)">
                            Suprimir
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="modal fade" [attr.id]="'editlocation-'+item._id" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel2">Editar sucursal</h5>
                          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                          <form #locationForm="ngForm" (ngSubmit)="update(locationForm,item._id)">
                            <div class="card border-0 box-shadow">
                              <div class="card-body">
                                <div class="tab-content" style="margin-top: 20px;">
                                  <div class="tab-pane fade active show">
                                    <div class="form-group row align-items-center">


                                      <div class="col-md-5">
                                        <h5 class="smalltext">Nombre de sucursal</h5>
                                        <input class="form-control" type="text" placeholder="Agregar nombre"
                                          #name="ngModel" name="name" maxlength="50" [(ngModel)]="item.name">
                                      </div>

                                      <div class="col-md-4">
                                        <h5 class="smalltext">Correo</h5>
                                        <input class="form-control" type="text" placeholder="Correo" name="mail"
                                          maxlength="50" #mail="ngModel" [(ngModel)]="item.mail">
                                      </div>

                                      <div class="col-md-3">
                                        <h5 class="smalltext">Número de teléfono</h5>
                                        <input class="form-control" type="text" placeholder="Teléfono" name="phone"
                                          maxlength="50" [(ngModel)]="item.phone">
                                      </div>


                                    </div>
                                    <div class="form-group row align-items-center">

                                      <div class="col-md-6">
                                        <h6 class="smalltext mt-4">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            fill="currentColor" class="bi bi-calendar2-week" style="color: #6610f2;"
                                            viewBox="0 0 16 16">
                                            <path
                                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                                            <path
                                              d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                          </svg>
                                          Horario de lunes a viernes
                                        </h6>
                                        <input class="form-control mb-3 " type="text" placeholder="Agregar horario"
                                          name="weekday" maxlength="50" [(ngModel)]="item.weekday">
                                      </div>

                                      <div class="col-md-6">
                                        <h6 class="smalltext  mt-4">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            fill="currentColor" class="bi bi-calendar2-week-fill"
                                            style="color: #6610f2;" viewBox="0 0 16 16">
                                            <path
                                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM8.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM3 10.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                                          </svg>
                                          Horario sábado
                                        </h6>
                                        <input class="form-control mb-3" type="text" placeholder="Agregar horario"
                                          name="saturday" maxlength="50" [(ngModel)]="item.saturday">
                                      </div>

                                      <div class="col-md-6">
                                        <h6 class="smalltext  mt-4">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            fill="currentColor" class="bi bi-calendar2-week-fill"
                                            style="color: #6610f2;" viewBox="0 0 16 16">
                                            <path
                                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM8.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM3 10.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                                          </svg>
                                          Horario domingo
                                        </h6>
                                        <input class="form-control mb-3" type="text" placeholder="Agregar horario"
                                          name="sunday" maxlength="50" [(ngModel)]="item.sunday">
                                      </div>

                                      <div class="col-md-6">
                                        <h5 class="smalltext mt-3">Dirección escrita</h5>
                                        <textarea class="form-control" id="textarea-input" rows="4"
                                          placeholder="Agregar ubicación escrita" name="located"
                                          [(ngModel)]="item.located"></textarea>
                                      </div>

                                      <div class="col-md-6">
                                        <h6 class="smalltext  mt-4">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-geo-alt" style="color: #6610f2;"
                                            viewBox="0 0 16 16">
                                            <path
                                              d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                            <path
                                              d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                          </svg>
                                          Link de WAZE
                                        </h6>
                                        <input class="form-control mb-3" type="text" placeholder="Link de waze"
                                          name="waze" maxlength="5000" [(ngModel)]="item.waze">

                                        <h6 class="smalltext mt-1">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-geo-alt-fill" style="color: #6610f2;"
                                            viewBox="0 0 16 16">
                                            <path
                                              d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                          </svg>
                                          Link de GOOGLE MAPS
                                        </h6>
                                        <input class="form-control mb-3" type="text" placeholder="link de google maps"
                                          name="maps" maxlength="5000" [(ngModel)]="item.maps">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="d-flex justify-content-start">
                                <button class="btn btn-primary mb-2 ms-3" type="submit"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-check2-circle" viewBox="0 0 16 16">
                                    <path
                                      d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                    <path
                                      d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                  </svg> Actualizar</button>
                                <button class="btn btn-secondary mb-2 ms-2" type="button" data-dismiss="modal">
                                  Cancelar
                                </button>
                              </div>

                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">

                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="modal fade" [attr.id]="'editlocation-'+item._id" tabindex="-1" role="dialog"
                    style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title text-lg-left">Editar hito</h4>
                          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                          <form #hitoform="ngForm" (ngSubmit)="update(hitoform,item._id)">
                            <div class="card border-0 box-shadow">
                              <div class="card-body">
                                <div class="tab-content" style="margin-top: 20px;">
                                  <div class="tab-pane fade active show">
                                    <div class="form-group row align-items-center">
                                      <div class="col-md-12">
                                        <h3 class="smalltext">Título del hito</h3>
                                        <input class="form-control" type="text" name="name" maxlength="50"
                                          [(ngModel)]="item.title">
                                      </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                      <div class="col-md-12">
                                        <h3 class="smalltext mt-3">Descripción del hito</h3>
                                        <textarea class="form-control" id="textarea-input" rows="5"
                                          placeholder="description" name="located"
                                          [(ngModel)]="item.description"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card-footer">
                                <button class="btn btn-success btn-sm mb-2 mr-1" type="submit">Actualizar</button>
                                <button class="btn btn-secondary btn-sm mb-2 ml-1" type="button" data-dismiss="modal">
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </section>
      </div>
    </div>
  </div>

</section>