<app-aside></app-aside>


<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-lg-9 pt-lg-4">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">


              <div class="col-lg-12">
                <div class="mt-4 pb-2 text-center">
                  <h2 class="mt-lg-4">Bienvenido al panel de administración</h2>
                </div>
                <div class="row d-flex justify-content-center">
                  <div class="col-10"><p class="roboto">Para utilizar el panel de administrador, elija una de las opciones disponibles
                    en el <strong>menú ubicado en el lado derecho de la pantalla.</strong> <br><br>En caso de tener dificultades para utilizar
                    alguna funcionalidad en particular, puede acceder al botón <br><strong>"<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                    </svg> Tutorial de uso"</strong> ubicado en la parte superior
                    izquierda de la pantalla para ver un video explicativo sobre cómo utilizar esa funcionalidad de
                    manera correcta. <br><br>Si desea finalizar su sesión, diríjase al menú ubicado en la parte superior de la página y haga clic en la opción <strong>"<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
                      <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
                    </svg> Cerrar sesión."</strong></p></div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  </div>
</section>