import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public url;
  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
  }
  listar():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'location',{headers:headers})
  }

  eliminar(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url + 'location/eliminar/'+id,{headers:headers})
  }

  registro(data):Observable<any>{
    var fd = new FormData();
    fd.append('provincia',data.provincia);
    fd.append('name',data.name);
    fd.append('located',data.located);
    fd.append('weekday',data.weekday);
    fd.append('saturday',data.saturday);
    fd.append('sunday',data.sunday);
    fd.append('mail',data.mail);
    fd.append('phone',data.phone);
    fd.append('waze',data.waze);
    fd.append('maps',data.maps);
    return this._http.post(this.url + 'location/registro',fd);
  }

  update(data):Observable<any>{
    const fd = new FormData();
    fd.append('provincia',data.provincia);
    fd.append('name',data.name);
    fd.append('located',data.located);
    fd.append('weekday',data.weekday);
    fd.append('saturday',data.saturday);
    fd.append('sunday',data.sunday);
    fd.append('mail',data.mail);
    fd.append('phone',data.phone);
    fd.append('waze',data.waze);
    fd.append('maps',data.maps);
    return this._http.put(this.url + '/location/editar/' + data._id,fd);
  }


}
