<app-nav></app-nav>

<section class="">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-11 col-md-6 col-lg-7">
        <div id="carouselExampleIndicators" class="carousel carousel-dark slide" data-bs-ride="carousel">

          <div class="carousel-inner">
            <ng-container *ngFor="let imagen of galeria;let primero = first;let indice=index">
              <div class="carousel-item {{primero? 'active': ''}}">
                <img [src]="url+'/galeria/resources/thumbnails/'+imagen.imagen" class="d-block w-100" alt="...">
              </div>
            </ng-container>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div class="col-11 col-md-6 col-lg-5">
        <div class="p-md-4">
          <p class="text-muted mt-md-3 ">
            Los anteojos ideales
          </p>
          <h1 [ngClass]="{'huge-t black mt-4 mt-lg-0 mb-4': true, 'roboto': !global.dislex, 'dislex': global.dislex }">
            {{producto.titulo}}</h1>
          <div class="d-flex justify-content-between col-12 col-md-12 col-lg-12 col-xl-8 mb-3">
            <p
              [ngClass]="{'black ms-2': true, 'dislex': global.dislex, 'text-normal': !global.sizetext ,'sizetext': global.sizetext}">
              Precio: ₡{{producto.precio_ahora}}
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-tags ms-2"
                viewBox="0 0 16 16">
                <path
                  d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
                <path
                  d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
              </svg>
            </p>
            <p
              [ngClass]="{'text-muted me-2': true, 'dislex': global.dislex, 'text-normal': !global.sizetext ,'sizetext': global.sizetext}" *ngIf="producto?.precio_antes">
              Antes: <span style="text-decoration: line-through;">₡{{producto.precio_antes}}</span></p>
          </div>
          <div class="d-grid col-12 col-md-10 col-lg-8">
            <a
              [ngClass]="{'btn mb-3': true, 'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
              Probarmelos
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-emoji-sunglasses ms-2 mb-1" viewBox="0 0 16 16">
                <path
                  d="M4.968 9.75a.5.5 0 1 0-.866.5A4.498 4.498 0 0 0 8 12.5a4.5 4.5 0 0 0 3.898-2.25.5.5 0 1 0-.866-.5A3.498 3.498 0 0 1 8 11.5a3.498 3.498 0 0 1-3.032-1.75zM7 5.116V5a1 1 0 0 0-1-1H3.28a1 1 0 0 0-.97 1.243l.311 1.242A2 2 0 0 0 4.561 8H5a2 2 0 0 0 1.994-1.839A2.99 2.99 0 0 1 8 6c.393 0 .74.064 1.006.161A2 2 0 0 0 11 8h.438a2 2 0 0 0 1.94-1.515l.311-1.242A1 1 0 0 0 12.72 4H10a1 1 0 0 0-1 1v.116A4.22 4.22 0 0 0 8 5c-.35 0-.69.04-1 .116z" />
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-1 0A7 7 0 1 0 1 8a7 7 0 0 0 14 0z" />
              </svg>
            </a>
          </div>
          <div class="d-grid col-12 col-md-10 col-lg-8">
            <a
              [ngClass]="{'btn mb-5': true, 'cursor': global.cursor_btn, 'btn-outline-success': !global.color_filter, 'btn-dark': global.color_filter}">
              Contactarlos
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp ms-2 mb-1" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
              </svg>
            </a>
          </div>

          <div class="col-12 col-md-11 border-bottom border-top mb-5">
            <h5
              [ngClass]="{'bold-f ms-2 mt-3': true, 'gray': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'text-normal': !global.sizetext ,'sizetext': global.sizetext, 'dislex': global.dislex }">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                class="bi bi-eyeglasses me-2" viewBox="0 0 16 16">
                <path
                  d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
              </svg>
              Descripción del producto
            </h5>
            <p [ngClass]="{'black mb-4 p-2': true,'sizetext': global.sizetext, 'dislex': global.dislex }">
              {{producto.info_short}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="bg-gray">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 text-center">
        <h2 [ngClass]="{'bigger-t title-line-height mt-5': true, 'bold-f': !global.dislex, 'dislex': global.dislex, 'turquesa':!global.color_filter,
          'black': global.color_filter}">PASOS PARA USAR EL</h2>
        <h3
          [ngClass]="{'huge-t title-line-height mb-3 text-center': true, 'blue': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
          style="line-height: 0.8;">PROBADOR VIRTUAL</h3>
      </div>

      <div
        [ngClass]="{'col-12 corners-rounded mb-5 none-phone none-ipad':true, 'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
        <div class="row">

          <div class="col-12 col-md-3 text-center">
            <img src="assets/img/icons/icon-box-btn.png" style="width: 60%;">
            <h3
              [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              CLIC EN PROBARMELO
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Luego de seleccionar tus lentes presione
              el boton de probador virtual
            </p>
          </div>

          <div class="col-12 col-md-3 text-center">
            <img src="assets/img/icons/icon-box-cam.png" style="width: 25%;">
            <h3
              [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              ACTIVE SU CÁMARA
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              De acceso a la cámara de su celular
              v o computadora
            </p>
          </div>

          <div class="col-12 col-md-3 text-center">
            <img src="assets/img/icons/icon-box-card.png" style="width: 25%;">
            <h3
              [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              USE REFERENCIA
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Coloque una tarjeta sobre su frente
              para saber mejor su medida
            </p>
          </div>

          <div class="col-12 col-md-3 text-center">
            <h3
              [ngClass]="{'huge-t mb-1 mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              ¡LISTO!
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Puede probarse todos los lentes
              que desee
            </p>
          </div>

        </div>
      </div>

      <div
        [ngClass]="{'col-11 corners-rounded mb-5 p-4 none-desktop':true, 'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item text-center active">
              <img src="assets/img/icons/icon-box-btn2.png" style="width: 50%;">
              <h3
                [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                CLIC EN PROBARMELOS
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                Presione el botón “Probarmelos”
                para acceder al probador virtual
              </p>
            </div>
            <div class="carousel-item text-center">
              <img src="assets/img/icons/icon-box-cam.png" style="width: 50%;">
              <h3
                [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                ACTIVE SU CÁMARA
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                De acceso a la cámara de su celular
                v o computadora
              </p>
            </div>
            <div class="carousel-item text-center">
              <img src="assets/img/icons/icon-box-card.png" style="width: 50%;">
              <h3
                [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                USE REFERENCIA
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                Coloque una tarjeta sobre su frente
                para saber mejor su medida
              </p>
            </div>
            <div class="carousel-item text-center">
              <img src="assets/img/icons/icon-box-oppener.png" style="width: 46%;">
              <h3
                [ngClass]="{'huge-t mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                ¡LISTO!
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                Puede probarse todos los lentes
                que desee
              </p>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

    </div>
  </div>
</section>


<app-foot></app-foot>


<!-- Modal -->