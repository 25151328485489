<app-aside></app-aside>


<div class="page-title-overlap bg-accent pt-4">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">

   
      
          
      
              <div class="col-lg-12">
                <div class="border-bottom mt-lg-2 pb-2">
                    <h2 class="mt-lg-4">Solicitudes</h2>
                    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                      <p class="text-muted">Todos las solicitudes de cancelacion.</p>
                      
                    </div>
                    
                </div>
  
              </div>

              <div class="col-lg-6 pt-4">
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-semibold"><span class="czi-filter"></span> &nbsp; Filtro</span>
                  </div>
                  <select class="form-control custom-select" [(ngModel)]="filtro" (change)="filtro_listar(filtro)">
                    <option value="" selected>Todos</option>
                    <option value="Reembolsado">Reembolsados</option>
                    <option value="En espera">En espera</option>
                    <option value="Denegado">Denegados</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="table-responsive">
                  <table class="table table-striped table-sm">
                    <thead class="thead-dark">
                      <tr>
                        <th>ID VENTA</th>
                        <th>Estado</th>
                        <th>Monto</th>
                        <th>Detalles</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let item of cancelaciones| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                        <tr>
                            <td>{{item.venta._id.toUpperCase()}}</td>
                            <td>
                                <span class="badge badge-accent" *ngIf="item.estado == 'En espera'">{{item.estado}}</span>
                                <span class="badge badge-primary" *ngIf="item.estado == 'Reembolsado'">{{item.estado}}</span>
                                <span class="badge badge-dark" *ngIf="item.estado == 'Denegado'">{{item.estado}}</span>
                            </td>
                            <td>${{item.venta.total_pagado}}</td>
                            <td><a [routerLink]="['/admin/cancelacion/modulo/detalle/',item._id]" href="/admin/cancelacion/modulo/detalle/{{item._id}}" routerLinkActive="router-link-active" ><span class="czi-folder"></span></a></td>
                        </tr>
                    </tbody>
                  </table>
                 
                </div>
                <ngb-pagination
                      [(page)]="page"
                      [pageSize]="pageSize"
                      [collectionSize]="count_cat"></ngb-pagination>
              </div>
          </div>
         
        </div>
      </section>
    </div>
  </div>
</div>

<app-foot></app-foot>