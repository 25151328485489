import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PortadaService } from 'src/app/services/portada.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { ContactoService } from 'src/app/services/contacto.service';
import { PopUpService } from '../../services/popUp.service';

declare var jQuery:any;
declare var $:any;
declare var grecaptcha: any;

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public url;
  public general;
  public contacto = {
    nombres:'',
    correo: '',
    telefono: '',
    medio:'',
    horario:'',
    mensaje:''
  };
  public msm_success=false;
  public msm_error=false;
  public btn_send=false;
  savedPopUp: any;
  public global = GLOBAL;
  private browserRecaptchaKey = this.global.browserRecaptchaKey;

  constructor(
    private _congeneralService : PortadaService,
    private _contactoService : ContactoService,
    private _popUpService: PopUpService,
    private cdr: ChangeDetectorRef
  ) {

    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this._popUpService.getLocalPopUp().subscribe(
      response => {
        this.savedPopUp = response;
        this.cdr.detectChanges();
      }
    );
  }


  onSubmit(contactoForm){

    this.btn_send = true;


    if(!contactoForm.valid){
      this.msm_error = true;
      this.msm_success = false;
    }else{
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(this.browserRecaptchaKey, {action: 'EnviarMensaje'});
        let data = {
          nombres:contactoForm.value.nombres,
          correo: contactoForm.value.correo,
          telefono: contactoForm.value.telefono,
          medio:contactoForm.value.medio,
          horario:contactoForm.value.horario,
          mensaje:contactoForm.value.mensaje,
          recaptchaToken: token
        }

        this._contactoService.registro(data).subscribe(
          response =>{

            this.contacto = {
              nombres:'',
              correo: '',
              telefono: '',
              medio:'',
              horario:'',
              mensaje:''
            }
            this.msm_success = true;
            this.msm_error = false;
            this.btn_send = false;
          },
          error=>{

            this.msm_success = false;
            this.msm_error = true;
            this.btn_send = false;
          }
        );
      });
    }
  }

  close_alert() {
    this.msm_error = false;
    this.msm_success = false;
  }

}
