import {Component, OnInit} from '@angular/core';

import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EspedashboardService} from '../../../services/espedashboard.service';
import {AreacatService} from '../../../services/areacat.service';
import {Espedashboard} from '../../../models/Espedashboard';
import {GLOBAL} from "../../../services/GLOBAL";

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-espedashboard',
  templateUrl: './espedashboard.component.html',
  styleUrls: ['./espedashboard.component.css']
})

export class EspedashboardComponent implements OnInit {


  public especialidades: Array<any> = [];
  public areacats: Array<any> = [];
  public especialidades_show: Array<any> = [];
  public especialidad = new Espedashboard('', '', '','');
  public identity;
  public id;
  public msm_error = '';
  public msm_success = '';
  public toggle : Array<boolean> = [];
  public areaID = '';

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _espedashboardService: EspedashboardService,
  private _areacatService: AreacatService

  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    this.initData();
  }


  initData() {
    this._espedashboardService.listar().subscribe(
      response => {
        this.especialidades = response.espedashboard;
        this.populate_especialidades_toshow (this.areaID);
      },
      error => {

      }
    );
    this._areacatService.listar().subscribe(
      response_area => {
        this.areacats = response_area.areacat;
      },
      error => {

      }
    );

  }

  eliminar(_id) {
    this._espedashboardService.eliminar(_id).subscribe(
      response => {

        const modal = document.getElementById('delete-'+_id);
        // change state like in hidden modal
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        // get modal backdrop
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        $('.modal-backdrop').remove();

        this.initData();

      },
      error => {

      }
    );
  }

  close_toast() {
    $('#dark-toast').removeClass('show');
    $('#dark-toast').addClass('hide');
  }

  onSubmit(espedarshboardForm) {

    if (espedarshboardForm.valid && this.especialidad.title && this.especialidad.description ) {
        let data = {
          title: espedarshboardForm.value.title,
          description: espedarshboardForm.value.description,
          services: espedarshboardForm.value.services
        };
        this._espedashboardService.registro(data).subscribe(
          response => {
            console.log(response);
            this.especialidad = new Espedashboard('', '', '','');
            this.msm_success = 'Especialidad de ASEMBIS añadida';
            this.initData();
          },
          error => {
            this.msm_error = 'Complete correctamente el formulario por favor.';
          }
        );

    }else {
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }
  }

  update(espedarshboardForm, _id) {

    if (espedarshboardForm.valid) {

      let data = {
        _id: _id,
        title: espedarshboardForm.value.title,
        description: espedarshboardForm.value.description,
        services: espedarshboardForm.value.services
      };


      this._espedashboardService.update(data).subscribe(
        response => {
          console.log(response);
          this.msm_success = 'Especialidad de ASEMBIS editada';
          this.initData();

          const modal = document.getElementById('editespecialidad-'+_id);
          // change state like in hidden modal
          modal.classList.remove('show');
          modal.setAttribute('aria-hidden', 'true');
          modal.setAttribute('style', 'display: none');
          // get modal backdrop
          const modalBackdrops = document.getElementsByClassName('modal-backdrop');
          $('.modal-backdrop').remove();

        },

        error => {
          this.msm_error = error;
        }
      );

    } else {
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }


  }

  close_alert() {
    this.msm_success = '';
  }

  populate_especialidades_toshow (area_id){
    this.areaID = area_id;
    this.especialidades_show = []
    for (var especialidad of this.especialidades){
      if (especialidad.area._id == area_id){
        this.especialidades_show.push(especialidad)
      }
    }
    this.toggle = []
    for (var area of this.areacats){
      if (this.areaID == area._id) {
        this.toggle.push (false)
      }
      else {
        this.toggle.push (true)
      }
    }
  }


}
