import {Component, OnInit} from '@angular/core';

import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationService} from '../../../services/location.service';
import {ProvinciaService} from '../../../services/provincia.service';
import {location} from '../../../models/Location';
import {GLOBAL} from "../../../services/GLOBAL";

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class locationComponent implements OnInit {


  public locations: Array<any> = [];
  public provincias: Array<any> = [];
  public location = new location('', '', '', '', '', '', '', '','','','');
  public identity;
  public id;
  public msm_error = '';
  public msm_success = '';

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _locationService: LocationService,
    private _provinciaService: ProvinciaService
  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    this.initData();
  }


  initData() {
    this._locationService.listar().subscribe(
      response => {
        this.locations = response.location;
      },
      error => {

      }
    );
    this._provinciaService.listar().subscribe(
      response_prov => {
        this.provincias = response_prov.provincia;
      },
      error => {

      }
    );
  }

  eliminar(id) {
    this._locationService.eliminar(id).subscribe(
      response => {

        $('#modal-'+id).modal('hide');
        $('.modal-backdrop').remove();
        $(document.body).removeClass("modal-open");
        this.initData();

      },
      error => {

      }
    );
  }

  close_toast() {
    $('#dark-toast').removeClass('show');
    $('#dark-toast').addClass('hide');
  }

  onSubmit(locationForm) {

    if (locationForm.valid && this.location.name && this.location.located && this.location.weekday && this.location.saturday && this.location.sunday && this.location.sunday && this.location.mail  ) {
      if (this.location.provincia) {

        let data = {
          provincia: locationForm.value.provincia,
          name: locationForm.value.name,
          located: locationForm.value.located,
          weekday: locationForm.value.weekday,
          saturday: locationForm.value.saturday,
          sunday: locationForm.value.sunday,
          mail: locationForm.value.mail,
          phone: locationForm.value.phone,
          waze: locationForm.value.waze,
          maps: locationForm.value.maps
        };
        this._locationService.registro(data).subscribe(
          response => {
            console.log(response);
            this.location = new location('', '', '', '', '', '', '', '', '','','');
            this.msm_success = 'Sucursal de ASEMBIS añadida';
            this.initData();
          },
          error => {
            this.msm_error = 'Complete correctamente el formulario por favor.';
          }
        );

      }
      else {
        this.msm_error = 'Selecccione una provincia';
      }
    }else {
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }
  }

  update(locationForm, _id) {

    if (locationForm.valid) {

      let data = {
        _id: _id,
        name: locationForm.value.name,
        located: locationForm.value.located,
        weekday: locationForm.value.weekday,
        saturday: locationForm.value.saturday,
        sunday: locationForm.value.sunday,
        mail: locationForm.value.mail,
        phone: locationForm.value.phone,
        waze: locationForm.value.waze,
        maps: locationForm.value.maps
      };


      this._locationService.update(data).subscribe(
        response => {
          console.log(response);
          this.msm_success = 'Sucursal de ASEMBIS actualizada';

          $('#Modal').modal('hide');
          $('.modal-backdrop').remove();
          $(document.body).removeClass("modal-open");
          this.initData();

        },

        error => {
          this.msm_error = error;
        }
      );

    } else {
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }


  }

  close_alert() {
    this.msm_success = '';
  }





}
