export let GLOBAL = {
    url : '/api/',
    color_filter: false,
    cursor_btn: false,
    encendidoPromo: false,
    dislex: false,
    sizetext: false,
    browserRecaptchaKey: '6Lf3KtMfAAAAAIsNaz-S0dA0hyYBbEd6HB5dxlat',
    userMail: ""
};
