<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-trabajosocial.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          NUESTRO TRABAJO SOCIAL</h1>
      </div>
    </div>
  </div>
</section>

<section class="bg-gray">

  <div class="container">
    <div class="row">

      <div class="col-12 col-md-4 d-flex justify-content-center">
        <div class="card border-0 box-info mt-5 mb-4 mb-md-5">
          <img src="assets/img/icons/icon-sa-1.png" class="icon-box mt-3" alt="...">
          <div class="card-body">
            <h4
              [ngClass]="{'text-big text-center': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              Nuestro trabajo social</h4>
            <p
              [ngClass]="{' ps-3 pe-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Nos aseguramos de darle la mejor atención posible, es por eso que nuestro equipo de trabajo social está
              conformado por personas profesionales, amables y dispuestas a ayudar. Usted es nuestra prioridad.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 d-flex justify-content-center">
        <div class="card border-0 box-info mt-md-5 mb-4 mb-md-5">
          <img src="assets/img/icons/icon-sa-2.png" class="icon-box mt-3" alt="...">
          <div class="card-body">
            <h4
              [ngClass]="{'text-big text-center': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              Equipo médico de primera</h4>
            <p
              [ngClass]="{'ps-3 pe-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex,'sizetext': global.sizetext}">
              Tanto el personal médico, así como el equipo que se utiliza en todos los procedimientos y consultas son de
              primera calidad. De esta forma aseguramos que la calidad esté presente en cada uno de los servicios que
              brindamos. </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4  d-flex justify-content-center">
        <div class="card border-0 box-info mt-md-5 mb-5 mb-md-5">
          <img src="assets/img/icons/icon-sa-3.png" class="icon-box mt-3" alt="...">
          <div class="card-body">
            <h4
              [ngClass]="{'text-big text-center': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              Enfocado al servicio al cliente</h4>
            <p
              [ngClass]="{'ps-3 pe-3':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Para nosotros el cliente es prioritario, por eso convertimos su visita en una agradable experiencia a
              través de un servicio al cliente de calidad.</p>
          </div>
        </div>
      </div>

    </div>
  </div>

</section>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 bg-photo-kids">

      </div>

      <div class="col-12 col-md-6">
        <div class="card border-0 box-socialwork">
          <div class="card-body">
            <h2
              [ngClass]="{'bigger-t title-line-height mt-5 mb-3 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              SOLICITE EL APOYO DE <br>TRABAJO
              SOCIAL</h2>
            <p
              [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              El Departamento de Trabajo Social realiza un estudio a toda persona que
              no cuente con los medios económicos para el pago de un servicio.
              Nuestro objetivo es que toda persona pueda acceder a nuestros servicios de salud de forma igualitaria.
              Servicios tales como anteojos, consultas médicas, prótesis dentales, cirugías y diferentes especialidades.
              Consulte por Trabajo Social en la sucursal más cercana será un gusto poder apoyarle.</p>
            <p
              [ngClass]="{'mt-4 text-center': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              Salud al alcance de todos. <br>Ponte en contacto al 2285 5881</p>
            <div class="text-center mb-5">
              <a class="only-phone" href="tel:+50622855881"
                 [ngClass]="{'btn btn-md-lg m-1': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     class="bi bi-telephone-fill me-1" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                PONTE EN CONTACTO
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section [ngClass]="{'gradient': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="">
    <img class="none-phone" src="assets/img/line-decor.png" alt="linea de decoración" style="width: 100%;">
    <img class="only-phone" src="assets/img/decor-m.jpg" alt="linea de decoración" style="width: 100%;">
  </div>

  <div class="container mt-2">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 mb-5 mt-5">
        <h4
          [ngClass]="{'big-t text-center mb-4 mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"><span
          [ngClass]="{'': true}">NUESTROS PROYECTOS DE BIEN SOCIAL</span>
        </h4>

        <div id="carouselExampleControls" class="carousel slide mb-5 mt-5" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5
                        [ngClass]="{'text-big': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                        1. Ver para Aprender</h5>
                      <p
                        [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">
                        Dirigido a escuelas de atención prioritaria su objetivo es apoyar a personas
                        menores de edad en la mejora de su calidad visual.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5
                        [ngClass]="{'text-big': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                        2. Atención a organizaciones de bien social</h5>
                      <p
                        [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">
                        Atención de casos referidos por instituciones que trabajan con población en
                        condición de vulnerabilidad.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5
                        [ngClass]="{'text-big': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                        3. Proyectos comunitarios</h5>
                      <p
                        [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">
                        Dirigidos a la niñez, adolescencia, población adulta mayor en temáticas de
                        salud y prevención.<br><span style="color: transparent;">text</span></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-2 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5
                        [ngClass]="{'text-big': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                        4. Donativos a organizaciones</h5>
                      <p
                        [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">
                        Apoyo a diferentes organizaciones con donativos solicitados para la atención
                        de personas en condición de pobreza y vulnerabilidad.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="carousel-item">

              <div class="card mb-3 corners-rounded" style="width: 80%; margin-left: 10%;">
                <div class="row g-0">
                  <div class="col-12 col-md-2">
                    <img src="assets/img/icons/icon-sa-1.png" class="img-fluid rounded-start icon-project mt-3 mt-md-5"
                         alt="...">
                  </div>
                  <div class="col-12 col-md-10">
                    <div class="card-body">
                      <h5
                        [ngClass]="{'text-big': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                        5. Gestión Ambiental</h5>
                      <p
                        [ngClass]="{'dislex': global.dislex, 'card-text': !global.sizetext, 'sizetext': global.sizetext}">
                        Concientización al personal colaborador de ASEMBIS en el adecuado manejo de
                        los residuos y el impacto ambiental.</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <button [ngClass]="{'carousel-control-prev': true,'cursor': global.cursor_btn}" type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev">
            <span [ngClass]="{'carousel-control-prev-icon': true,'cursor': global.cursor_btn}"
                  aria-hidden="true"></span>
            <span [ngClass]="{'visually-hidden': true,'cursor': global.cursor_btn}">Previous</span>
          </button>

          <button [ngClass]="{'carousel-control-next': true,'cursor': global.cursor_btn}" type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next">
            <span [ngClass]="{'carousel-control-next-icon': true,'cursor': global.cursor_btn}"
                  aria-hidden="true"></span>
            <span [ngClass]="{'visually-hidden': true,'cursor': global.cursor_btn}">Next</span>
          </button>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6 mb-5 mt-0 mt-md-5 border-start">
        <h4
          [ngClass]="{'big-t text-center mb-4 mt-2 mt-md-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <span [ngClass]="{'p-2': true}">RESULTADO DE PROYECTOS</span></h4>
        <div class="d-flex justify-content-center">
          <ul>
            <li
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              Atención a la salud visual de la niñez
            </li>
            <li
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              Aporte a la educación de la niñez y adolescencia
            </li>
            <li
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              Mejora en la calidad de vida de personas
            </li>
            <li
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              Acceso a servicios médicos y atención de calidad
            </li>
            <li
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              Prevención y tratamiento en temas de salud
            </li>
          </ul>
        </div>
        <a href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/" target="_blank"><img
          [ngClass]="{'mb-5 ods-logo': true,'cursor': global.cursor_btn}" src="assets/img/icons/img-objetivos.png"></a>
      </div>
    </div>
  </div>

</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="card border-0" style="width: 90%;margin-left: 5%">
          <div class="card-body">
            <h2
              [ngClass]="{'bigger-t title-line-height mt-5 mb-3 text-center': true, 'blue': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex}">
              NUESTROS OBJETIVOS</h2>
            <p
              [ngClass]="{'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Desde su creación el objetivo principal de ASEMBIS ha sido beneficiar a
              la clase de escasos recursos de Costa Rica, así nace el departamento de trabajo social, a través del cual
              nuestros pacientes se benefician con exoneraciones parciales o totales en los diferentes servicios que
              brindamos. Este servicio se ofrece en todas las sucursales.</p>
            <p
              [ngClass]="{'mt-2 mb-5': true, 'green': !global.color_filter, 'black': global.color_filter,'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Solo en los últimos cinco años ASEMBIS ha brindado en ayuda social
              a
              los pacientes, un promedio de ¢257.946.520, contribuyendo al mejoramiento de la salud de nuestra
              población.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6 mt-md-3">

        <video class="mt-5 mb-3 none-phone" style="width: 90%; margin-left: 10%;" id="background-video" controls loop>
          <source src="assets/img/videots.mp4" type="video/mp4">
        </video>

        <video class="mt-0 mb-5 only-phone" style="width: 100%;" id="background-video2" controls loop>
          <source src="assets/img/videots.mp4" type="video/mp4">
        </video>


      </div>

    </div>
  </div>
</section>


<app-foot></app-foot>

