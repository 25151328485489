<app-aside></app-aside>

<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">
            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2 mb-5">
                <h1 class="mt-lg-4">Registro de colecciones</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Complete correctamente el formulario.</p>
                  <p class="font-size-sm pl-md-4"><a class="text-nowrap" [routerLink]="['/admin/categorias']" href="/admin/categorias">Regresar<i
                    class="czi-arrow-right align-middle ml-1"></i></a></p>
                </div>
              </div>

              <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show"
                   role="alert">
                {{msm_error}}
                <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
              </div>

              <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show"
                   role="alert">
                {{msm_success}}
                <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
              </div>
              <form #categoriaForm="ngForm" (ngSubmit)="onSubmit(categoriaForm)">
                <div class="card border-0 box-shadow">

                  <div class="card-body">
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Titulo</label>
                          <div class="col-md-10">
                            <input class="form-control" type="text" placeholder="Titulo de la categoría"
                                   #nombre="ngModel" name="nombre" maxlength="50" [(ngModel)]="categoria.nombre">
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Banner</label>
                          <div class="col-md-10">
                            <!-- Drag and drop file upload -->
                            <div class="cz-file-drop-area">
                              <div *ngIf="!categoria['banner']" class="cz-file-drop-icon czi-cloud-upload"></div>
                              <div *ngIf="categoria['banner']" class="cz-file-drop-preview img-thumbnail rounded">
                                <img src="{{imgSelect.banner}}">
                              </div>
                              <span class="cz-file-drop-message">Suelta la imagen aquí</span>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelected($event, 'banner')">

                            </div>
                            <p class="text-muted smalltext">Tamaño recomendado: 1600 x 480 px</p>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Mobile
                            Banner</label>
                          <div class="col-md-10">
                            <!-- Drag and drop file upload -->
                            <div class="cz-file-drop-area">
                              <div *ngIf="!categoria['mobile_banner']" class="cz-file-drop-icon czi-cloud-upload"></div>
                              <div *ngIf="categoria['mobile_banner']"
                                   class="cz-file-drop-preview img-thumbnail rounded">
                                <img src="{{imgSelect.mobile_banner}}">
                              </div>
                              <span class="cz-file-drop-message">Suelta la imagen aquí</span>
                              <input type="file" class="cz-file-drop-input"
                                     (change)="imgSelected($event, 'mobile_banner')">

                            </div>
                            <p class="text-muted smalltext">Tamaño recomendado: 500 x 300 px</p>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium"
                                 for="textarea-input">Descripción</label>
                          <div class="col-md-10">
                            <textarea class="form-control" id="textarea-input" rows="5"
                                      placeholder="Detalle corto del producto." #description="ngModel"
                                      name="description" [(ngModel)]="categoria.description"></textarea>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium"
                                 for="textarea-input">Porcentajes</label>

                          <div class="col-md-10 row">
                            <div class="col-md-5">
                              <input class="form-control" type="number" placeholder="Porcentaje orgánico" name="organicPercentage" [(ngModel)]="categoria.organicPercentage">
                            </div>

                            <div class="col-md-5">
                              <input class="form-control" type="number" placeholder="Porcentaje reciclado" name="recyclingPercentage" [(ngModel)]="categoria.recyclingPercentage">
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary mb-2 mr-1" type="submit">Registrar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

