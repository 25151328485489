import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GaleriaService } from 'src/app/services/galeria.service';
import { GLOBAL } from "../../../../services/GLOBAL";
import { UserService } from 'src/app/services/user.service';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-galeria-producto',
  templateUrl: './galeria-producto.component.html',
  styleUrls: ['./galeria-producto.component.css']
})
export class GaleriaProductoComponent implements OnInit {

  public id;
  public imagenes : any = [];
  public files: File[] = [];
  public msm_error=false;
  public url;
  public page;
  public pageSize = 12;
  public count_img;
  public identity;

  constructor(
    private _galeriaService : GaleriaService,
    private _route : ActivatedRoute,
    private _router : Router,
    private _userService: UserService,

  ) {
    this.url = GLOBAL.url;
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    {
        this._route.params.subscribe(
          params=>{
            this.id = params['id'];
            this._galeriaService.listar(this.id).subscribe(
              response=>{
                this.imagenes = response.imagenes;
                this.count_img = this.imagenes.length;
                this.page = 1;


              },
              error=>{

              }
            )
          }
        );
      }

  }

  close_toast(){
    /* $('#dark-toast').removeClass('show');
        $('#dark-toast').addClass('hide'); */
  }

  close_modal(){
    $('#modal_adding').removeClass('show');
    $('.modal-backdrop').remove();
    this.files = []
  }

  close_alert(){
    this.msm_error = false;
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    console.log(this.files);

  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }


  onSubmit(galeriaForm){

      let data = {
        imagenes : this.files,
        producto : this.id
      }

      this._galeriaService.registro(data).subscribe(
        response =>{
          this.listar();
          this.files = []

          const modal = document.getElementById('modal_adding');
          // change state like in hidden modal
          modal.classList.remove('show');
          modal.setAttribute('aria-hidden', 'true');
          modal.setAttribute('style', 'display: none');
          // get modal backdrop
          const modalBackdrops = document.getElementsByClassName('modal-backdrop');
          // remove opened modal backdrop
          document.body.removeChild(modalBackdrops[0]);
        },
        error=>{
          this.msm_error = true;
        }
      );
  }


  listar(){
    this._galeriaService.listar(this.id).subscribe(
      response=>{
        this.imagenes = response.imagenes;
        this.count_img = this.imagenes.length;
        this.page = 1;
      },
      error=>{
      }
    );
  }


  eliminar(id){
    this._galeriaService.eliminar(id).subscribe(
      response=>{

        $('#modal-'+id).modal('hide');
        $('.modal-backdrop').remove();
        $(document.body).removeClass("modal-open");
        this.listar();

      },
      error=>{

      }
    );
  }

  openNuevasImageneesModal() {
    const modal = document.getElementById('modal_adding');
    modal.classList.add('show');
    modal.setAttribute('aria-hidden', 'false');
    modal.setAttribute('style', 'display: block');
    $('<div class="modal-backdrop fade show"></div>').appendTo(document.body);
  }

  closeModalBtn () {
    const modal = document.getElementById('modal_adding');
    // change state like in hidden modal
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    // get modal backdrop
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    // remove opened modal backdrop
    document.body.removeChild(modalBackdrops[0]);
  };


}




