<app-nav></app-nav>


<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-articles.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          BLOG ASEMBIS</h1>
      </div>
    </div>
  </div>
</section>


<section>
  <div class="container mb-5">
    <div class="row">

      <div class="col-12 col-md-6 col-lg-4" *ngFor="let article of articles">
        <div class="card mt-5">
          <img [src]="url+'article/resources/thumbnails/'+article.banner" class="card-img-top" alt="...">
          <div class="card-body">
            <img src="assets/img/icons/icon-one-article.png" class="mt-2 mb-3" style="width: 13%;">
            <h5
              [ngClass]="{'big-t mt-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              {{article.nombre}}</h5>
            <p class="blockquote-footer mt-2 mb-3">Blog ASEMBIS</p>
            <div class="d-flex justify-content-center">
              <div class="col-10 d-grid">
                <a [routerLink]="['/blog/'+article.slug]" href="/blog/{{article.slug}}"
                  [ngClass]="{'btn btn-block': true, 'cursor': global.cursor_btn,  'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">Leer
                  entrada
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>


</section>


<app-foot></app-foot>