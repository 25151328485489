<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-lg-9 pt-lg-4 pb-4 mb-3">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">
              <div class="col-lg-12">
                <div class="border-bottom mt-lg-2 pb-2 mb-5">
                  <a class="btn btn-light me-1" [routerLink]="['/admin/productos']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-arrow-90deg-left me-1" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                    </svg>
                    Regresar</a>
                  <h1 class="mt-lg-4">Edición de producto</h1>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Complete correctamente el formulario.</p>

                  </div>
                </div>


                <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show"
                  role="alert">
                  {{msm_error}}
                  <button type="button" class="btn-close btn-close-dark me-2 m-auto" data-dismiss="alert"
                    aria-label="Close"></button>
                </div>

                <div *ngIf="msm_success" (click)="close_alert()"
                  class="alert alert-primary text-white alert-dismissible fade show" role="alert">
                  {{msm_success}}
                  <button type="button" class="btn-close btn-close-dark me-2 m-auto" data-dismiss="alert"
                    aria-label="Close"></button>
                </div>


                <form #productoForm="ngForm" (ngSubmit)="onSubmit(productoForm)">
                  <div class="card border-0 box-shadow">
                    <div class="card-header d-flex justify-content-end">

                      <button class="btn btn-primary btn-lg mb-2" type="submit">Actualizar datos
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                          class="bi bi-check-circle-fill ms-2" viewBox="0 0 16 16">
                          <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                      </button>

                    </div>
                    <div class="card-body">
                      <div class="tab-content" style="margin-top: 20px;">
                        <div class="tab-pane fade active show">
                          <div class="form-group row align-items-center">
                            <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Titulo</label>
                            <div class="col-md-9">
                              <input class="form-control" type="text" placeholder="Titulo del producto"
                                #titulo="ngModel" name="titulo" [(ngModel)]="producto.titulo" required>
                            </div>
                          </div>

                          <div class="form-group row align-items-center">
                            <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Banner</label>
                            <div class="col-md-9">

                              <div class="row mt-5 mb-5">
                                <div class="col-2">

                                </div>

                                <div class="col-8 border text-center">
                                  <div *ngIf="!producto['poster']" class="">
                                    <a (click)="vamos.click()" style="cursor: pointer;">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor"
                                        class="bi bi-image text-muted mb-5 mt-5" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        <path
                                          d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                                      </svg>
                                    </a>
                                  </div>
                                  <div *ngIf="producto['poster']" class="">
                                    <a (click)="vamos.click()" style="cursor: pointer;"><img class="mb-4 mt-4"
                                        [src]="imgSelect?.poster || url+'productos_img_data/resources/thumbnails/'+producto.poster"
                                        style="width: 80%;"></a>
                                  </div>
                                  <input class="form-control mb-3" type="file" id="formFile" #vamos
                                    (change)="imgSelected($event, 'poster')">

                                </div>

                              </div>


                            </div>
                          </div>


                          <div class="form-group row">
                            <label class="col-md-3 col-form-label font-weight-medium" for="textarea-input">Descripción
                              del
                              producto</label>
                            <div class="col-md-9">
                              <textarea class="form-control mb-5" id="textarea-input" rows="5"
                                placeholder="Detalle corto del producto." #info_short="ngModel" name="info_short"
                                [(ngModel)]="producto.info_short" required></textarea>
                            </div>
                          </div>
                          <div class="form-group row align-items-center">
                            <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Detalles</label>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-lg-4">
                                  <label for="select-input">Precio ahora</label>
                                  <input class="form-control" type="number" placeholder="₡" #precio_ahora="ngModel"
                                    name="precio_ahora" min="0" [(ngModel)]="producto.precio_ahora" required>
                                </div>
                                <div class="col-lg-4">
                                  <label for="select-input">Precio antes</label>
                                  <input class="form-control" type="text" placeholder="₡" #precio_antes="ngModel"
                                    name="precio_antes" [(ngModel)]="producto.precio_antes">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group row align-items-center mt-4">
                            <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Categoría</label>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-lg-8">
                                  <div class="form-group">
                                    <label for="select-input">Seleccione la colección</label>
                                    <select class="form-select" id="select-input" [(ngModel)]="producto.categoria"
                                      name="categoria" required>
                                      <option selected disabled value="">SELECCIONAR</option>
                                      <option *ngFor="let item of data_categorias" [value]="item._id">{{item.categoria}}
                                      </option>

                                    </select>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>

                  </div>
                </form>


              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  </div>
</section>