import { Component, OnInit } from '@angular/core';
import {CategoriaService} from "../../services/categoria.service";
import {GLOBAL} from "../../services/GLOBAL";


@Component({
  selector: 'app-aboutdesigner',
  templateUrl: './aboutdesigner.component.html',
  styleUrls: ['./aboutdesigner.component.css']
})
export class AboutdesignerComponent implements OnInit {

  public categorias;
  public url;
  public hitos: Array<any> = [];


  constructor(
    private _categoriaService: CategoriaService,
  ) {
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this.data_categorias();
    this.initData()
  }

  initData() {

  }


  data_categorias() {

    this._categoriaService.listar('').subscribe(
      response => {
        this.categorias = response.categorias;
      },
      error => {

      }
    );
  }

}
