<!--<p>loginv2 works!</p>
<button (click)="onLogout()" > logout </button>
<button *ngIf="!manager" [routerLink]="['/citas']" > Módulo de citas </button>
<button *ngIf="!manager" (click)="loginManager()" > Administración de ASEMBIS </button>
<button *ngIf="manager" (click)="usersManagment()" > Administrar Usuarios existentes </button>
<button *ngIf="manager" (click)="showCreateUserForm()" > Crear Usuario </button>

<p *ngIf="errorConvenios">{{errorConvenios}}</p>

<section *ngIf="users?.length != 0 && manager && !showCreateUser">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4" *ngFor="let user of users">
        email:    {{user.mail}} <br/>
        Convenio: {{user.jobTitle}}
        <button (click)="deleteUser(user.id)" > Eliminar </button>
      </div>
    </div>
</section>

<h1 *ngIf="manager && showCreateUser">Crear nuevo usuario</h1>
<form *ngIf="manager && showCreateUser" #userForm="ngForm" (ngSubmit)="createUser(userForm)">
  <input class="form-control" type="text" placeholder="Email del nuevo usuario"
         [(ngModel)]="newUser.mail" name="mail" required ngControl="name">

  <select class="form-select" aria-label="Default select example" name="convenio"
          [(ngModel)]="convenio"
          id="select-input" required>
    <option selected disabled value="">Seleccione un convenio</option>
    <option *ngFor="let item of convenios"
            [value]="item.key">{{item.value}}</option>
  </select>

  <button class="btn btn-primary btn-lg mb-2" type="submit">Invitar usuario </button>
</form>-->





<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <div class="col-6">
      <a style="cursor:pointer;">
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
             class="bi bi-shield-fill-check white mt-4 mb-4 ms-md-4 me-1" *ngIf="!manager" (click)="loginManager()"  viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z"/>
        </svg>
      </a>
      <img class="logo-nav-convenios" src="assets/img/logoASEMBIS-white.svg" alt="Logo de ASEMBIS">
    </div>


    <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
      <button type="button" class="btn btn-dark btn-lg" (click)="onLogout()">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
             class="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"/>
          <path fill-rule="evenodd"
                d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
        </svg>
        Logout
      </button>
    </div>
  </div>
</nav>

<section>
  <div class="container">
    <div class="row d-flex justify-content-center">


      <div class="col-12" *ngIf="!manager">
        <h1 class="fs-2 text-center mt-5">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               class="bi bi-check-circle-fill mb-2" style="color: #6610f2;" viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg>
          Hola, le damos la bienvenida
        </h1>
      </div>

      <div class="col-12 col-md-5">

        <div class="card mt-5 mb-5 border-0 corners-rounded shadow-box" *ngIf="!manager">
          <div class="card-header d-flex justify-content-start border-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                 class="bi bi-person-circle" style="color: #6610f2;" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              <path fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
            </svg>
            <h5 class="fs-5 mt-1 ms-2">{{nombreUsuario}}</h5>
          </div>
          <div class="card-body text-center">
            <h5 class="title-card fs-2 mt-2 ">Modulo de citas</h5>
            <button type="button" class="btn btn-dark btn-lg mt-2 mb-4" *ngIf="!manager" [routerLink]="['/citas']">Solicitar una cita nueva
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                <path
                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
              </svg>
            </button>
          </div>
        </div>


      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row d-flex justify-content-center">

      <div class="col-12 col-md-5" *ngIf="manager">

        <div class="card mt-5 mb-5">
          <div class="card-header d-flex justify-content-start">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor"
                 class="bi bi-archive-fill mt-1 me-2" style="color: #6610f2;" viewBox="0 0 16 16">
              <path
                d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
            </svg>
            <h4 class="fs-5 mt-2">Administrar usuarios</h4>
          </div>
          <div class="card-body text-center">
            <h4 class="mt-3">Para administrar o ver usuarios de clic aquí</h4>
            <button type="button" class="btn btn-dark btn-lg" (click)="usersManagment()">Administrar usuarios</button>
          </div>
        </div>

      </div>

      <div class="col-12 col-md-5" *ngIf="manager">

        <div class="card mt-md-5 mb-5">
          <div class="card-header d-flex justify-content-start">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                 class="bi bi-person-plus-fill mt-1 me-2" style="color: #6610f2;" viewBox="0 0 16 16">
              <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              <path fill-rule="evenodd"
                    d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
            </svg>
            <h4 class="fs-5 mt-2">Nuevo Usuario</h4>
          </div>
          <div class="card-body text-center">
            <h4 class="mt-3">Para agregar un usuario de clic aquí</h4>
            <button type="button" class="btn btn-primary btn-lg" (click)="showCreateUserForm()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg"
                   viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
              </svg>
              Nuevo Usuario
            </button>
          </div>
        </div>

      </div>


    </div>
  </div>
</section>

<section class="border-top" >
  <div class="container">
    <div class="row d-flex justify-content-center">

      <div class="col-12 col-md-6" *ngIf="manager && showCreateUser">

        <h3 class="fs-3 mt-5 mb-2" *ngIf="manager && showCreateUser">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                                        class="bi bi-person-plus-fill mb-1" style="color: #6610f2;" viewBox="0 0 16 16">
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          <path fill-rule="evenodd"
                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
        </svg> Crear nuevo usuario</h3>


        <form class="mb-5" *ngIf="manager && showCreateUser" #userForm="ngForm" (ngSubmit)="createUser(userForm)">
          <div class="mb-3">
            <label for="mail" class="form-label">Email</label>
            <input type="email" class="form-control" id="mail" placeholder="Email del nuevo usuario"
                   [(ngModel)]="newUser.mail" name="mail" required ngControl="name">
            <div id="emailHelp" class="form-text">Incluya el correo del nuevo usuario.</div>
          </div>
          <div class="mb-3">
            <label for="convenio" class="form-label">Convenio</label>
            <select class="form-select" aria-label="Default select example" name="convenio"
                    [(ngModel)]="convenio" id="convenio" required>
              <option selected>Seleccione opción</option>
              <option *ngFor="let item of convenios"
                      [value]="item.key">{{item.value}}</option>
            </select>
            <div class="form-text">Seleccione un convenio.</div>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>

          <h5 class="mt-3 text-success" *ngIf="usuarioCreado"> <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg> Usuario Agregado</h5>

          <h5 class="mt-3 text-danger" *ngIf="errorConvenios"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
          </svg>
            {{errorConvenios}}</h5>


        </form>

      </div>

      <div class="col-12" *ngIf="users?.length != 0 && manager && !showCreateUser">

        <h3 class="fs-3 mt-5 mb-3"><svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                                                                          class="bi bi-archive-fill mt-1 me-2" style="color: #6610f2;" viewBox="0 0 16 16">
          <path
            d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
        </svg> Usuarios guardados</h3>

        <div class="row">

          <div class="col-12 col-md-4" *ngFor="let user of users">

            <div class="card text-dark bg-light corners-rounded mt-4 mb-4">
              <div class="card-header d-flex justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-person-circle mt-1" style="color: #6610f2;" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                  <path fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg> <h5 class="fs-5 ms-1 mt-1">Usuario</h5></div>
              <div class="card-body">
                <h5 class="card-title"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope mb-1" style="color: #6610f2;" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                </svg> Email: {{user.mail}}</h5>
                <h5 class="card-title"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-right-fill" style="color: #6610f2;" viewBox="0 0 16 16">
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                </svg> Convenio: {{user.jobTitle}}</h5>

                <button type="submit" class="btn btn-dark mt-2 mb-2" (click)="deleteUser(user.id)">Eliminar usuario <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                </svg></button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>


