export class Producto{
    constructor(
        public _id: string,
        public titulo: string,
        public poster: string,
        public precio_ahora: string,
        public precio_antes: string,
        public video_review: string,
        public info_short: string,
        public detalle: string,
        public stock: string,
        public categoria: string,
        public subcategoria: string,
        public nombre_selector: string,
        public marca: string
    ){
    }
}
