<app-aside></app-aside>

<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

  <div class="container pb-3">
    <div class="bg-light rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-9 pt-lg-4 pb-4 mb-3">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">
              <div class="col-lg-12">
                <div class="border-bottom mt-lg-2 pb-2 mb-5">
                  <h1 class="mt-lg-4">Redacción de entrada</h1>
                  <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                    <p class="text-muted">Todos los campos son obligatorios</p>

                    <a class="btn btn-dark me-1" [routerLink]="['/admin/articles']" href="/admin/articles">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-90deg-left me-1" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                      </svg>
                      Regresar</a>
                  </div>
                </div>

                <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show"
                  role="alert">
                  {{msm_error}}
                  <button class="btn-close" type="button" data-dismiss="alert" aria-label="Close"></button>
                </div>

                <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show"
                  role="alert">
                  {{msm_success}}
                  <button class="btn-close" type="button" data-dismiss="alert" aria-label="Close"></button>
                </div>



                <form #categoriaForm="ngForm" (ngSubmit)="onSubmit(categoriaForm)">
                  <div class="card border-0 box-shadow">

                    <div class="card-body">
                      <div class="tab-content" style="margin-top: 20px;">
                        <div class="tab-pane fade active show">

                          <div class="form-group row align-items-center">
                            <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Titulo<span
                              style="color: red;">*</span></label>
                            <div class="col-md-10">
                              <input class="form-control" type="text" maxlength="100" placeholder="Titulo del artículo"
                                #nombre="ngModel" name="nombre" [(ngModel)]="article.nombre">
                            </div>
                          </div>

                          <div class="form-group row align-items-center">
                            <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Portada <span
                              style="color: red;">*</span></label>
                            <div class="col-md-9">

                              <div class="row mt-5 mb-5">
                                <div class="col-1">
                                </div>
                                <div class="col-8 border text-center">
                                  <div *ngIf="!article['banner']" class="">
                                    <a (click)="vamos.click()" style="cursor: pointer;">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor"
                                        class="bi bi-image text-muted mb-5 mt-5" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        <path
                                          d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                                      </svg>
                                    </a>
                                  </div>
                                  <div *ngIf="article['banner']" class="">
                                    <a (click)="vamos.click()" style="cursor: pointer;"><img class="mb-4 mt-4"
                                        src="{{imgSelect.banner}}" style="width: 80%;"></a>
                                  </div>

                                  <input class="form-control mb-3" type="file" id="formFile" #vamos
                                    (change)="imgSelected($event, 'banner')">
                                  <p class="small text-muted mt-2 mb-3">Tamaño requerido: 1000 x 600 px <br> Peso
                                    recomendado: menos de 70KB</p>
                                </div>

                              </div>

                            </div>
                          </div>

                          <div class="form-group row align-items-center">
                            <label class="col-md-2 col-form-label font-weight-medium" for="textarea-input">Cuerpo <span
                                style="color: red;">*</span></label>
                            <div class="col-md-10">
                              <tinymce #body="ngModel" [(ngModel)]="article.body" required name="body"
                                [config]="config"></tinymce>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="msm_error" (click)="close_alert()"
                      class="alert alert-danger alert-dismissible fade show" role="alert">
                      {{msm_error}}
                      <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                          aria-hidden="true">×</span></button>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-primary mb-2 mr-1" type="submit">Publicar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>