import { Component, OnInit } from '@angular/core';
import { GLOBAL } from "../../services/GLOBAL";
import { PromoService } from '../../services/promo.service';


@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.css']
})
export class PromosComponent implements OnInit {

  public promos: Array<any> = [];
  public url;


  public global = GLOBAL;

  constructor(
    private _promoService: PromoService
  ) {
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this._promoService.listar().subscribe(
      response => {
        this.promos = response.promos;
      },
      error => {

      }
    );
  }

  ngAfterViewInit() {
    const images = document.querySelectorAll<HTMLImageElement>('img[data-enlargable]');

    Array.from(images).forEach((img) => {
      img.classList.add('img-enlargable');
      img.addEventListener('click', function () {
        const src = this.getAttribute('data-src-lg');
        const overlay = document.createElement('div');
        overlay.style.background = `RGBA(0,0,0,.5) url(${src}) no-repeat center`;
        overlay.style.backgroundSize = 'contain';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.position = 'fixed';
        overlay.style.zIndex = '10000';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.cursor = 'zoom-out';

        overlay.addEventListener('click', function () {
          document.body.removeChild(this);
        });

        document.body.appendChild(overlay);
      });
    });


  }


}
