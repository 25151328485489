import { Component, OnInit } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-index-article',
  templateUrl: './index-article.component.html',
  styleUrls: ['./index-article.component.css']
})
export class IndexArticleComponent implements OnInit {

  public articles : Array<any> = [];
  public page;
  public pageSize = 15;
  public count_art;
  public filtro;
  public identity;

  constructor(
    private _userService: UserService,
    private _router : Router,
    private _route :ActivatedRoute,
    private _articleService : ArticleService
  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    this.resetSearch();
  }


  resetSearch(){
    this.filtro = ''
    this._articleService.listar('').subscribe(
      response=>{
        this.articles = response.articles;
        this.count_art = this.articles.length;
        this.page = 1;

      },
      error =>{

      }
    );
  }

  search(searchForm){
    console.log(this.filtro);
    this._articleService.listar(this.filtro).subscribe(
      response=>{
        this.articles = response.articles;

      },
      error =>{

      }
    );
  }

  eliminar(_id){
    this._articleService.eliminar(_id).subscribe(
      response=>{

        this.resetSearch();

        const modal = document.getElementById('modal-'+_id);
        // change state like in hidden modal
        modal.classList.remove('show');
        modal.setAttribute('aria-hidden', 'true');
        modal.setAttribute('style', 'display: none');
        // get modal backdrop
        const modalBackdrops = document.getElementsByClassName('modal-backdrop');
        $('.modal-backdrop').remove();

      },
      error=>{

      }
    );
  }

  close_toast(){
    $('#dark-toast').removeClass('show');
    $('#dark-toast').addClass('hide');
  }


}
