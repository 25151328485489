<app-nav></app-nav>

<!-- <section class="">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 text-center">
        <h3 [ngClass]="{'bigger-t title-line-height mt-5 mb-3 text-center': true, 'blue': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}" style="line-height: 0.8;">PROBADOR VIRTUAL</h3>
      </div>

      <div [ngClass]="{'col-12 corners-rounded mb-5 none-phone none-ipad':true, 'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
        <div class="row">

          <div class="col-12 col-md-3 text-center">
            <img src="assets/img/icons/icon-box-select.png" style="width: 25%;">
            <h3
              [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              SELECCIONE ANTEOJOS
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Luego de seleccionar tus lentes presione
              el boton de probador virtual
            </p>
          </div>

          <div class="col-12 col-md-3 text-center">
            <img src="assets/img/icons/icon-box-cam.png" style="width: 25%;">
            <h3
              [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              ACTIVE SU CÁMARA
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              De acceso a la cámara de su celular
              v o computadora
            </p>
          </div>

          <div class="col-12 col-md-3 text-center">
            <img src="assets/img/icons/icon-box-card.png" style="width: 25%;">
            <h3
              [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              USE REFERENCIA
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Coloque una tarjeta sobre su frente
              para saber mejor su medida
            </p>
          </div>

          <div class="col-12 col-md-3 text-center">
            <h3
              [ngClass]="{'huge-t mb-1 mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
              ¡LISTO!
            </h3>
            <p
              [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Puede probarse todos los lentes
              que desee
            </p>
          </div>

        </div>
      </div>

      <div [ngClass]="{'col-11 corners-rounded mb-5 p-4 none-desktop':true, 'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item text-center active">
              <img src="assets/img/icons/icon-box-select.png" style="width: 25%;">
              <h3
                [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                SELECCIONE ANTEOJOS
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                Luego de seleccionar tus lentes presione
                el boton de probador virtual
              </p>
            </div>
            <div class="carousel-item text-center">
              <img src="assets/img/icons/icon-box-cam.png" style="width: 25%;">
              <h3
                [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                ACTIVE SU CÁMARA
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                De acceso a la cámara de su celular
                v o computadora
              </p>
            </div>
            <div class="carousel-item text-center">
              <img src="assets/img/icons/icon-box-card.png" style="width: 25%;">
              <h3
                [ngClass]="{'text-normal mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                USE REFERENCIA
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                Coloque una tarjeta sobre su frente
                para saber mejor su medida
              </p>
            </div>
            <div class="carousel-item text-center">
              <img src="assets/img/icons/icon-box-oppener.png" style="width: 25%;">
              <h3
                [ngClass]="{'huge-t mb-1 mt-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                ¡LISTO!
              </h3>
              <p
                [ngClass]="{'ps-3 pe-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                Puede probarse todos los lentes
                que desee
              </p>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

    </div>
  </div>
</section> -->

<section style="background-color:#f5f7f8">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-opticas" src="assets/img/icons/icon-optica.png">
        <h1
          [ngClass]="{'bigger-t': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ANTEOJOS ASEMBIS</h1>
      </div>
    </div>
  </div>

  <div class="container mt-2 mt-md-5 mb-4">
    <div class="row">

      <div class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card mb-3 mt-5 mt-md-2 border-0 bg-transparent">
          <div class="row g-0">
            <div class="col-3 col-md-3 g-0">
              <img class="mt-3 mt-md-2" src="assets/img/icons/icon-process.png" style="width: 90%; margin-left: 10%;"
                alt="...">
            </div>
            <div class="col-9 col-md-9 g-0">
              <div class="card-body">
                <p [ngClass]="{'big-t mt-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
                  style="line-height: 0;">FABRICACIÓN</p>
                <p
                  [ngClass]="{'':true, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Producimos más de <strong>15.625</strong> lentes al año</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card mb-3 mt-2 mt-md-2 border-0 bg-transparent">
          <div class="row g-0">
            <div class="col-3 col-md-3 g-0">
              <img class="mt-3 mt-md-2" src="assets/img/icons/icon-quality-2.png" style="width: 100%; margin-left: 0%;"
                alt="...">
            </div>
            <div class="col-9 col-md-9 g-0">
              <div class="card-body">
                <p [ngClass]="{'big-t mt-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
                  style="line-height: 0;">MATERIALES</p>
                <p
                  [ngClass]="{'':true, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Utilizamos materiales de primera calidad</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card mb-3 mt-2 mt-md-2 border-0 bg-transparent">
          <div class="row g-0">
            <div class="col-3 col-md-3 g-0">
              <img class="mt-3 mt-md-2" src="assets/img/icons/icon-shake.png" style="width: 100%; margin-left: 0%;"
                alt="...">
            </div>
            <div class="col-9 col-md-9 g-0">
              <div class="card-body">
                <p [ngClass]="{'big-t mt-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
                  style="line-height: 0;">CON AMOR</p>
                <p
                  [ngClass]="{'':true, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                  Hacemos todo con amor para nuestros clientes</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<section class="mt-4 mb-3 mb-md-3">
  <div class="container none-mobile none-ipad">
    <div class="row">

      <div class="col-12 col-md-3 col-lg-4 col-xl-6 mt-2 none-phone">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
            <li class="poppinsbold smalltext textblack"><a class="text-nowrap" style="color: black;"
                [routerLink]="['']" href=""><i class="czi-home mr-1"></i>Inicio</a>
            </li>
            <li class="poppinsbold smalltext textblack"><a style="color: black;">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                  class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
                Nuestros anteojos</a>
            </li>

          </ol>
        </nav>
      </div>


      <div class="col-12 col-md-9 col-lg-8 col-xl-6 text-end none-phone">
        <div class="btn-group">

          <div class="dropdown">

            <button class="poppins regulartext btn dropdown-toggle btn-sm me-4" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Escoger categoría
            </button>

            <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton"
              style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">

              <div *ngFor="let categoria of categorias;let indice=index" class="border-top">

                <a class="dropdown-item poppinsbold smalltext" style="color: black"
                  [routerLink]="['/anteojos/'+categoria.nombre.toLowerCase().trim()]" href="/anteojos/{{categoria.nombre.toLowerCase().trim()}}"
                  [attr.aria-controls]="'cat-'+indice">{{categoria.nombre}}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
              </div>

            </div>

          </div>

          <div class="dropdown mr-0 mr-md-5 mr-lg-0">
            <button class="poppins regulartext btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButton2"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Ordenar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2"
              style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                (click)="sort_filter('lower')">Menor a Mayor precio</a>
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                (click)="sort_filter('major')">Mayor a Menor precio</a>
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                (click)="sort_filter('desc')">Z - A</a>
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                (click)="sort_filter('asc')">A - Z</a>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>

  <div class="container none-desktop">
    <div class="row">

      <div class="col-5">
        <div class="dropdown">

          <button class="poppins regulartext btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButtonmobile"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Anteojos para
          </button>

          <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButtonmobile"
            style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">

            <div class="border-top">

              <a class="dropdown-item poppinsbold smalltext" style="color: black"
                [routerLink]="['/anteojos']" href="/anteojos">TODOS
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
              </a>
            </div>
            <div *ngFor="let categoria of categorias;let indice=index" class="border-top">

              <a class="dropdown-item poppinsbold smalltext" style="color: black"
                [routerLink]="['/anteojos/'+categoria.nombre.toLowerCase().trim()]" href="/anteojos/{{categoria.nombre.toLowerCase().trim()}}"
                [attr.aria-controls]="'cat-'+indice">{{categoria.nombre}}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
              </a>
            </div>

          </div>

        </div>
      </div>

      <div class="col-7">
        <div class="btn-group">


          <div class="dropdown mr-0 mr-md-5 mr-lg-0">
            <button class="poppins regulartext btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButton2mobile"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Ordenar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2mobile"
              style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                (click)="sort_filter('lower')">Menor a Mayor precio</a>
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                (click)="sort_filter('major')">Mayor a menor precio</a>
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                (click)="sort_filter('desc')">Z - A</a>
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                (click)="sort_filter('asc')">A - Z</a>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-12  text-center">
        <h1
          [ngClass]="{'big-t title-line-height mt-3 mb-3 text-center': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-eyeglasses only-phone"
            viewBox="0 0 16 16" style="color: #06788E;">
            <path
              d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
          </svg> {{real_category_name}}</h1>
      </div>
    </div>
  </div>
</section>

<section class="mt-2 mb-5">
  <div class="container">
    <div class="row">

      <div class="col-md-4 col-lg-3 none-phone">
        <div class="d-flex flex-column flex-shrink-0 mt-3 p-3 text-dark bg-transparent border-end">
          <a class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
              class="bi bi-eyeglasses me-2" viewBox="0 0 16 16" style="color: #06788E;">
              <path
                d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            </svg>
            <p [ngClass]="{'big-t mt-3': true, 'turquesa': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}"
              style="line-height: 0;">ANTEOJOS</p>
          </a>
          <hr>
          <ul class="nav nav-pills flex-column mb-auto">

            <h5 class="text-muted fs-6 mb-2">Seleccione:</h5>

            <li class="border-bottom">
              <a href="#" class="nav-link text-dark mb-2 mt-2" [routerLink]="['/anteojos']" href="/anteojos">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-caret-right-fill me-1 mb-1" style="color:#06788E" viewBox="0 0 16 16">
                  <path
                    d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
                TODOS
              </a>
            </li>

            <li class="border-bottom" *ngFor="let categoria of categorias;let indice=index">
              <a href="#" class="nav-link text-dark mb-2 mt-2"
                [routerLink]="['/anteojos/'+categoria.nombre.toLowerCase().trim()]" href="/anteojos/{{categoria.nombre.toLowerCase().trim()}}" [attr.aria-controls]="'cat-'+indice"
                [routerLinkActive]="['active']">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-caret-right-fill me-1 mb-1" style="color:#06788E" viewBox="0 0 16 16">
                  <path
                    d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
                {{categoria.nombre}}
              </a>
            </li>

          </ul>
          <hr>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-9">
        <div class="row">
          <div class="col-6 col-md-6 col-lg-4"
            *ngFor="let item of productos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
            <div class="card text-center mt-4">
              <a [routerLink]="['/producto/',item.slug]" href="/producto/{{item.slug}}">
              <img [src]="url+'productos_img_data/resources/thumbnails/'+item.poster" class="card-img-top"
                style="cursor:pointer;"> </a>
              <div class="card-body">
                <a  [routerLink]="['/producto/',item.slug]" href="/producto/{{item.slug}}">
                  <h5
                    [ngClass]="{'black me-2': true, 'dislex': global.dislex, 'card-title': !global.sizetext ,'sizetext': global.sizetext}"
                    style="cursor:pointer;">{{item.titulo.substr(0, 69)}}</h5>
                </a>
                <div class="d-flex justify-content-center mb-3">
                  <p
                    [ngClass]="{'black me-2 mb-0': true, 'dislex': global.dislex, 'card-text': !global.sizetext ,'sizetext': global.sizetext}">
                    Precio: ₡{{item.precio_ahora}}</p>
                  <p
                    [ngClass]="{'text-muted  me-2': true, 'dislex': global.dislex, 'card-text': !global.sizetext ,'sizetext': global.sizetext}" *ngIf="item?.precio_antes">
                    Antes: <span style="text-decoration: line-through;">₡{{item.precio_antes}}</span></p>
                </div>

                <a [routerLink]="['/producto/',item.slug]" href="/producto/{{item.slug}}"
                  [ngClass]="{'btn none-phone': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                  Ver Anteojo
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                  </svg>
                </a>

                <a [routerLink]="['/producto/',item.slug]" href="/producto/{{item.slug}}"
                  [ngClass]="{'btn only-phone': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                  Verlo
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                  </svg>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>



<app-foot></app-foot>