export class location{
  constructor(
    public _id: string,
    public provincia: string,
    public name: string,
    public located: string,
    public weekday: string,
    public saturday: string,
    public sunday: string,
    public mail: string,
    public phone: string,
    public waze: string,
    public maps: string
  ){
  }
}
