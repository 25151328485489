<app-nav></app-nav>
<div class="container py-5 mb-lg-3">
    <div class="row justify-content-center pt-lg-4 text-center">
      <div class="col-lg-5 col-md-7 col-sm-9"><img class="d-block mx-auto mb-5" src="assets/img/newlogo-green.svg" width="340" alt="404 Error">
        <h1 class="h3">404 error</h1>
        <h3 class="h5 font-weight-normal mb-4">Parece que no podemos encontrar la página que está buscando...</h3>
        <p class="font-size-md mb-4">
        </p>
      </div>
    </div>
    <!--<div class="row justify-content-center">
      <div class="col-xl-8 col-lg-10">
        <div class="row">
          <div class="col-sm-4 mb-3"><a class="card h-100 border-0 box-shadow-sm" [routerLink]="['/home/inicio']">
              <div class="card-body">
                <div class="media align-items-center"><i class="czi-home text-dark h4 mb-0"></i>
                  <div class="media-body pl-3">
                    <h5 class="font-size-sm mb-0">Inicio</h5><span class="text-muted font-size-ms">Regresar a inicio</span>
                  </div>
                </div>
              </div></a></div>
          <div class="col-sm-4 mb-3"><a class="card h-100 border-0 box-shadow-sm" [routerLink]="['/colecciones']">
              <div class="card-body">
                <div class="media align-items-center"><i class="czi-heart text-dark h4 mb-0"></i>
                  <div class="media-body pl-3">
                    <h5 class="font-size-sm mb-0">Colecciones</h5><span class="text-muted font-size-ms">Colecciones AMAG</span>
                  </div>
                </div>
              </div></a></div>
          <div class="col-sm-4 mb-3"><a class="card h-100 border-0 box-shadow-sm" [routerLink]="['/contacto']">
              <div class="card-body">
                <div class="media align-items-center"><i class="czi-help text-dark h4 mb-0"></i>
                  <div class="media-body pl-3">
                    <h5 class="font-size-sm mb-0">Contacto</h5><span class="text-muted font-size-ms">Contactate con nosotros</span>
                  </div>
                </div>
              </div></a></div>
        </div>
      </div>
    </div>-->
  </div>
  <app-foot></app-foot>
