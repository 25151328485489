import {Component, OnInit, DoCheck, Input, ChangeDetectorRef } from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ProductoService} from 'src/app/services/producto.service';
import {CarritoService} from 'src/app/services/carrito.service';
import {GLOBAL} from 'src/app/services/GLOBAL';
import {CategoriaService} from 'src/app/services/categoria.service';
import * as io from 'socket.io-client';
import {PortadaService} from 'src/app/services/portada.service';
import { PopUpService } from 'src/app/services/popUp.service';

declare var jQuery: any;
declare var $: any;


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  public identity;
  public productos: Array<any> = [];
  public filter;
  public carrito: Array<any> = [];
  public url;
  public subtotal: any = 0;
  public categorias: Array<any> = [];
  public categoriaLatest;

  // public socket = io('http://localhost:4201');
  public savedPopUp: any;
  public opmenu = 1;
  public general: any = {};
  public select_categoria = '';
  public global = GLOBAL;
  public body = document.body;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _productoService: ProductoService,
    private _carritoService: CarritoService,
    private _categoriaService: CategoriaService,
    private _congeneralService: PortadaService,
    private _popUpService: PopUpService,
    private cdr: ChangeDetectorRef
  ) {
    // this.identity = this._userService.getIdentity();
    this.url = GLOBAL.url;
  }

  /*  ngDoCheck(): void {
      this.identity = this._userService.getIdentity();
    }*/

  search_by_filter() {

    this._router.navigate(['/productos/search/', this.filter]);


  }

  show_menu() {
    if (this.opmenu == 1) {
      $('#menubar').removeClass('hide-menu');
      $('#menubar').addClass('show-menu');
      this.opmenu = 2;
    } else if (this.opmenu == 2) {
      $('#menubar').removeClass('show-menu');
      $('#menubar').addClass('hide-menu');
      this.opmenu = 1;
    }
  }

  show_Carrito() {
    this._carritoService.preview_carrito(/*this.identity._id*/).subscribe(
      response => {
        this.carrito = response.carrito;

        this.carrito.forEach(element => {
          this.subtotal = this.subtotal + (element.precio * element.cantidad);
        });

      },
      error => {
        console.log(error);

      }
    );
  }

  ngOnInit(): void {

    this._popUpService.getLocalPopUp().subscribe(
      response => {
        this.savedPopUp = response;
        this.cdr.detectChanges();
      }
    );

    // if(this.identity){
    this.data_categorias();

    /*      this.socket.on('new-carrito', function (data) {
            this.subtotal = 0;
            this.show_Carrito();

          }.bind(this)); */

    this.url = GLOBAL.url;
    this._route.params.subscribe(
      params => {
        this.filter = params['filter'];
      }
    );

    this.show_Carrito();
    this.listar_latest();

    /*      this._productoService.listar_autocomplete().subscribe(
            response =>{

              if(response.data.length >= 1){
                response.data.forEach(element => {
                  this.productos.push(element.titulo);


                });
              }

            },
            error=>{

            }
          );*/
    /*    }else{
          this.data_categorias();
          this.url = GLOBAL.url;
          this._route.params.subscribe(
            params=>{
              this.filter = params['filter'];
            }
          )
        }*/


  }

  /*  logout(){

      localStorage.removeItem('token');
      localStorage.removeItem('identity');

      this.identity = null;

      this._router.navigate(['/']);
    }*/

  remove_producto(index) {
    this._carritoService.remove_carrito(index).subscribe(
      response => {
        this.subtotal = this.subtotal - (response.precio * response.cantidad);
        this._carritoService.preview_carrito(/*this.identity._id*/).subscribe(
          response => {
            this.carrito = response.carrito;
            // this.socket.emit('save-carrito_dos', {new:true});


          },
          error => {
            console.log(error);

          }
        );
      },
      error => {

      }
    );
  }

  data_categorias() {
    this._categoriaService.listar('').subscribe(
      response => {
        this.categorias = response.categorias;
      },
      error => {

      }
    );
  }

  select_cat() {
    this._router.navigate(['/productos/' + this.select_categoria]);

  }

  listar_latest() {
    this._categoriaService.listarLatest().subscribe(
      response => {
        this.categoriaLatest = response.categoria;
      },
      error => {

      }
    );
  }

  change_color(value) {

    GLOBAL.color_filter = value;

  }

  cursor() {
    this.body.classList.add('cursor');
    GLOBAL.cursor_btn = true
  }

  no_cursor() {
    this.body.classList.remove('cursor');
      GLOBAL.cursor_btn = false
  }

  dislex (value) {
    GLOBAL.dislex = value;
  }

  sizetext (value) {
    GLOBAL.sizetext = value;
  }
}
