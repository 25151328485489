export class Promo{
  constructor(
    public _id: string,
    public titulo: string,
    public poster: string,
    public detalle: string,
    public validez: string,
  ){
  }
}
