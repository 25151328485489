import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GLOBAL} from "./GLOBAL";
import {firstValueFrom, lastValueFrom, map, Observable, of, take} from "rxjs";
import {MsalService} from "@azure/msal-angular";

@Injectable({
  providedIn: 'root'
})
export class CitaService {

  public url;

  constructor( private _http : HttpClient, private msalService: MsalService) {
    this.url = GLOBAL.url;
  }

  async listarServicios(data): Promise<Observable<any>> {
    const fd = new FormData();
    fd.append('recaptchaToken', data.recaptchaToken);
    fd.append('recaptchaAction', data.recaptchaAction);

    return this._http.post(this.url + 'cita/listarServicios', fd);
  }

  listarAgendas(data):Observable<any>{
    const fd = new FormData();
    fd.append('fecha_inicio',data.fecha_inicio);
    fd.append('fecha_fin',data.fecha_fin);
    fd.append('servicio',data.servicio);
    fd.append('recaptchaToken',data.recaptchaToken);
    fd.append('recaptchaAction',data.recaptchaAction);
    return this._http.post(this.url + 'cita/listarAgenda',fd);
  }

  async registro(data): Promise<Observable<any>> {
    const fd = new FormData();
    fd.append('fecha', data.fecha);
    fd.append('servicio', data.servicio);
    fd.append('agenda', data.agenda);
    fd.append('nombre', data.nombre);
    fd.append('cedula', data.cedula);
    fd.append('correo', data.correo);
    fd.append('sexo', data.sexo);
    fd.append('telefono', data.telefono);
    fd.append('expediente', data.expediente);
    fd.append('fechaNacimiento', data.fechaNacimiento);
    fd.append('primerApellido', data.primerApellido);
    fd.append('segundoApellido', data.segundoApellido);
    fd.append('recaptchaToken', data.recaptchaToken);
    fd.append('recaptchaAction', data.recaptchaAction);

    return this._http.post(this.url + 'cita/registrarCita', fd);

  }

  async registrov2(data): Promise<Observable<any>> {
    return this._http.post(this.url + 'cita/registrarCita', {});
  }

  listarConvenios(data):Observable<any>{
    const fd = new FormData();
    fd.append('recaptchaToken',data.recaptchaToken);
    fd.append('recaptchaAction',data.recaptchaAction);
    return this._http.post(this.url + 'convenio/listarConvenios',fd);
  }

  listarCitas(data: { fecha_inicio: string; servicio: any; recaptchaAction: string; fecha_fin: string; recaptchaToken: any; agenda: any }): Observable<any> {
    const fd = new FormData();
    fd.append('fecha_inicio',data.fecha_inicio);
    fd.append('fecha_fin',data.fecha_fin);
    fd.append('servicio',data.servicio);
    fd.append('agenda',data.agenda);
    fd.append('recaptchaToken',data.recaptchaToken);
    fd.append('recaptchaAction',data.recaptchaAction);
    return this._http.post(this.url + 'cita/listarCitas',fd);
  }

  getDatosPacientes(data):Observable<any>{
    const fd = new FormData();
    fd.append('cedula',data.cedula);
    fd.append('recaptchaToken',data.recaptchaToken);
    fd.append('recaptchaAction',data.recaptchaAction);
    return this._http.post(this.url + 'cita/getDatosPaciente',fd);
  }
}
