import { Component, OnInit, DoCheck } from '@angular/core';
import { PortadaService } from 'src/app/services/portada.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Portada } from '../../../../models/Portada';

interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface FileWithPreview {
  file: File;
  dataURL: string;
}

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-general',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.css']
})
export class PortadaComponent implements OnInit, DoCheck {

  public filePortada: File;
  public filePortadaType: string;
  public filePortadaMobileType: string;
  public filePortadaMujer: File;
  public imgSelectPortada: String | ArrayBuffer;
  public imgSelectPortadaMujer: String | ArrayBuffer;
  public file_portada_mobile: File;
  public imgSelectPortadaMobile: String | ArrayBuffer;
  public portada: any = {};
  public url;
  public msm_error = '';
  public msm_success = false;
  public identity;
  public filesSlider: FileWithPreview[] = [];
  public filesSliderMobile: FileWithPreview[] = [];
  public extensionLists = {
    video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
    image: ['jpg', 'gif', 'bmp', 'png', 'webp']
  };
  public desktopFileType: string;
  public mobileFileType: string;
  public isUpdating: boolean = false;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _portadaService: PortadaService
  ) {
    this.identity = this._userService.getIdentity();
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this.init_data();
  }

  init_data() {
    this._portadaService.get_data().subscribe(
      async response => {
        this.portada = response.imagenes;
        if (this.extensionLists.video.includes(this.portada.portada.split('.').pop())) {
          this.desktopFileType = 'video';
        } else if (this.extensionLists.image.includes(this.portada.portada.split('.').pop())) {
          this.desktopFileType = 'image';
        }
        if (this.extensionLists.video.includes(this.portada.portadaMobile.split('.').pop())) {
          this.mobileFileType = 'video';
        } else if (this.extensionLists.image.includes(this.portada.portadaMobile.split('.').pop())) {
          this.mobileFileType = 'image';
        }
        await this.fetchFiles(response);
      },
      error => {
      }
    );
  }

  private async fetchFiles(response) {
    // Manejo para slider 1
    var slideMobile1 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider1Mobile).then(r => r.blob()).then(blobFile =>
        new File([blobFile], response.imagenes.slider1Mobile ? response.imagenes.slider1Mobile : 'default', { type: blobFile.type }));

    var slide1 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider1).then(r => r.blob()).then(blobFile =>
        new File([blobFile], response.imagenes.slider1 ? response.imagenes.slider1 : 'default', { type: blobFile.type }));

    // Manejo para slider 2
    var slideMobile2 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider2Mobile).then(r => r.blob()).then(blobFile =>
        new File([blobFile], response.imagenes.slider2Mobile ? response.imagenes.slider2Mobile : 'default', { type: blobFile.type }));

    var slide2 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider2).then(r => r.blob()).then(blobFile =>
        new File([blobFile], response.imagenes.slider2 ? response.imagenes.slider2 : 'default', { type: blobFile.type }));

    // Manejo para slider 3
    var slideMobile3 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider3Mobile).then(r => r.blob()).then(blobFile =>
        new File([blobFile], response.imagenes.slider3Mobile ? response.imagenes.slider3Mobile : 'default', { type: blobFile.type }));

    var slide3 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider3).then(r => r.blob()).then(blobFile =>
        new File([blobFile], response.imagenes.slider3 ? response.imagenes.slider3 : 'default', { type: blobFile.type }));

   
    var slideMobile4 = response.imagenes.slider4Mobile ? await fetch(this.url + 'portada/resources/thumbnails/' + response.imagenes.slider4Mobile).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider4Mobile ? response.imagenes.slider4Mobile : 'default', { type: blobFile.type })) : null;

    var slide4 = response.imagenes.slider4 ? await fetch(this.url + 'portada/resources/thumbnails/' + response.imagenes.slider4).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider4 ? response.imagenes.slider4 : 'default', { type: blobFile.type })) : null;

    
    var slideMobile5 = response.imagenes.slider5Mobile ? await fetch(this.url + 'portada/resources/thumbnails/' + response.imagenes.slider5Mobile).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider5Mobile ? response.imagenes.slider5Mobile : 'default', { type: blobFile.type })) : null;

    var slide5 = response.imagenes.slider5 ? await fetch(this.url + 'portada/resources/thumbnails/' + response.imagenes.slider5).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider5 ? response.imagenes.slider5 : 'default', { type: blobFile.type })) : null;

    
    this.filesSliderMobile = [
      { file: slideMobile1, dataURL: await this.convertToDataURL(slideMobile1) },
      { file: slideMobile2, dataURL: await this.convertToDataURL(slideMobile2) },
      { file: slideMobile3, dataURL: await this.convertToDataURL(slideMobile3) },
      slideMobile4 ? { file: slideMobile4, dataURL: await this.convertToDataURL(slideMobile4) } : null,
      slideMobile5 ? { file: slideMobile5, dataURL: await this.convertToDataURL(slideMobile5) } : null
    ].filter(file => file !== null);  // Filtrar nulos

    this.filesSlider = [
      { file: slide1, dataURL: await this.convertToDataURL(slide1) },
      { file: slide2, dataURL: await this.convertToDataURL(slide2) },
      { file: slide3, dataURL: await this.convertToDataURL(slide3) },
      slide4 ? { file: slide4, dataURL: await this.convertToDataURL(slide4) } : null,
      slide5 ? { file: slide5, dataURL: await this.convertToDataURL(slide5) } : null
    ].filter(file => file !== null);  // Filtrar nulos
  }

  private convertToDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  close_alert() {
    this.msm_error = '';
  }

  onSelectSlider(event: HtmlInputEvent) {
    const files = Array.from(event.target.files);
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.filesSlider.length < 5) {  // Permite hasta 5
          this.filesSlider.push({ file, dataURL: e.target.result });
        }
      };
      reader.readAsDataURL(file);
    });
  }

  onSelectSliderMobile(event: HtmlInputEvent) {
    const files = Array.from(event.target.files);
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.filesSliderMobile.length < 5) {  // Permite hasta 5
          this.filesSliderMobile.push({ file, dataURL: e.target.result });
        }
      };
      reader.readAsDataURL(file);
    });
  }

  onRemoveSlider(fileWithPreview: FileWithPreview) {
    this.filesSlider = this.filesSlider.filter(f => f !== fileWithPreview);
  }

  onRemoveSliderMobile(fileWithPreview: FileWithPreview) {
    this.filesSliderMobile = this.filesSliderMobile.filter(f => f !== fileWithPreview);
  }


  imgSelected_portada(event: HtmlInputEvent) {
    if (event.target.files && event.target.files[0]) {
      this.imgSelectPortada = undefined;
      this.filePortada = (event.target.files[0] as File);

      const reader = new FileReader();
      reader.onload = e =>
        this.imgSelectPortada = reader.result;
      if (this.extensionLists.video.includes(this.filePortada.name.split('.').pop())) {
        this.desktopFileType = 'video';
      } else if (this.extensionLists.image.includes(this.filePortada.name.split('.').pop())) {
        this.desktopFileType = 'image';
      }
      reader.readAsDataURL(this.filePortada);
      this.portada.portada = this.filePortada;
      $('#logo-icon').addClass('cz-file-drop-preview img-thumbnail rounded logo-preview');
      $('#logo-icon').removeClass('cz-file-drop-icon czi-cloud-upload');
    }
  }

  imgSelectedPortadaMobile(event: HtmlInputEvent) {
    if (event.target.files && event.target.files[0]) {
      this.imgSelectPortadaMobile = undefined;
      this.file_portada_mobile = (event.target.files[0] as File);
      if (this.extensionLists.video.includes(this.file_portada_mobile.name.split('.').pop())) {
        this.mobileFileType = 'video';
      } else if (this.extensionLists.image.includes(this.file_portada_mobile.name.split('.').pop())) {
        this.mobileFileType = 'image';
      }
      const reader = new FileReader();
      reader.onload = e =>
        this.imgSelectPortadaMobile = reader.result;
      reader.readAsDataURL(this.file_portada_mobile);
      this.portada.portadaMobile = this.file_portada_mobile;
      $('#favicon-icon').addClass('cz-file-drop-preview img-thumbnail rounded favicon-preview');
      $('#favicon-icon').removeClass('cz-file-drop-icon czi-cloud-upload');
    }
  }

  imgSelectedPortadaMujer(event: HtmlInputEvent) {
    if (event.target.files && event.target.files[0]) {
      this.imgSelectPortadaMujer = undefined;
      this.filePortadaMujer = (event.target.files[0] as File);

      const reader = new FileReader();
      reader.onload = e =>
        this.imgSelectPortadaMujer = reader.result;
      if (this.extensionLists.image.includes(this.filePortadaMujer.name.split('.').pop())) {
        this.desktopFileType = 'image';
      }
      reader.readAsDataURL(this.filePortadaMujer);
      this.portada.portadaMujer = this.filePortadaMujer;
      $('#logo-icon').addClass('cz-file-drop-preview img-thumbnail rounded logo-preview');
      $('#logo-icon').removeClass('cz-file-drop-icon czi-cloud-upload');
    }
  }

  ngDoCheck(): void {
    $('.logo-preview').html('<img src=' + this.imgSelectPortada + '>');
    $('.favicon-preview').html('<img src=' + this.imgSelectPortadaMobile + '>');
  }

  onkey_iframe(data) {
    $('#content-mapa').html(data);
  }

  onSubmit(generalForm) {
    this.isUpdating = true;
    // Verificar si hay al menos una imagen para cada slider (desktop y mobile)
    if (this.filesSlider.length > 0 && this.filesSliderMobile.length > 0) {
      const data = {
        tipo: this.portada.tipo,
        portada: this.filePortada,
        portadaMobile: this.file_portada_mobile,
        slider1: this.filesSlider[0]?.file || null,
        slider1Mobile: this.filesSliderMobile[0]?.file || null,
        slider2: this.filesSlider[1]?.file || null,
        slider2Mobile: this.filesSliderMobile[1]?.file || null,
        slider3: this.filesSlider[2]?.file || null,
        slider3Mobile: this.filesSliderMobile[2]?.file || null,
        slider4: this.filesSlider[3]?.file || null,   
        slider4Mobile: this.filesSliderMobile[3]?.file || null,   
        slider5: this.filesSlider[4]?.file || null,  
        slider5Mobile: this.filesSliderMobile[4]?.file || null, 
        portadaMujer: this.filePortadaMujer,
      };
  
      
      this._portadaService.update(this.portada._id, data).subscribe(
        response => {
          window.scroll(0, 0);
          this.msm_error = '';
          this.msm_success = true;
          this.isUpdating = false;

          // Mostrar mensaje durante 5 segundos y luego ocultarlo
          setTimeout(() => {
            this.msm_success = false;
          }, 5000);
        },
        error => {
          window.scroll(0, 0);
          this.msm_error = error.message;
          this.msm_success = false;
          this.isUpdating = false;

          // Mostrar mensaje durante 5 segundos y luego ocultarlo
          setTimeout(() => {
            this.msm_error = '';
          }, 5000);
        }
      );
    } else {
      this.isUpdating = false;
      this.msm_error = 'Debe agregar al menos 3 imágenes en cada slider (desktop y mobile).';

      // Mostrar mensaje durante 5 segundos y luego ocultarlo
      setTimeout(() => {
        this.msm_error = '';
      }, 5000);
    }
  }
  

  infoPortada = {
    tipo: '',
  };
}
