<div class="container d-flex justify-content-start p-3">
  <div class="col-12 col-md-2">
    <a [routerLink]="['/home/inicio']" target="_blank"><img class="mb-3" style="width: 80%"
                                                            src="assets/img/logodashboard.png"></a>
  </div>

  <div class="col-12 col-md-10 d-flex justify-content-between">
    <h3 class="fs-4 mt-2" style="color: white;">¡Bienvenido!</h3>
    <a [routerLink]="['/admin/infogeneral']">
      <button type="button" class="btn btn-light">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
             class="bi bi-play-circle-fill me-1" viewBox="0 0 16 16">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
        </svg>
        Tutorial de uso
      </button>
    </a>
  </div>
</div>
