import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from 'src/app/services/article.service';
import {GLOBAL} from '../../services/GLOBAL';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-show-article',
  templateUrl: './show-article.component.html',
  styleUrls: ['./show-article.component.css']
})
export class ShowArticleComponent implements OnInit {
  public slug;
  public article;
  public url;
  public randomArticles;
  public global = GLOBAL;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _articleService: ArticleService) {
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this._route.params.subscribe(
      params => {
        this.slug = params.slug;

        this.init_data();

      }
    );
  }


  init_data() {
    this._articleService.find_by_slug(this.slug).subscribe(
      response => {
        this.article = response.article;
        this._articleService.findRandom().subscribe(
          randomResponse => {
            this.randomArticles = randomResponse.articles;
            this.randomArticles = this.randomArticles.filter(item => (item._id !== this.article._id));
          }
        );
        $('#detalle').html(this.article.body);
      });
  }
}
